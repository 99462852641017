import * as Actions from "./action";
import * as ActionTypes from "./actionTypes";
import { parseDataKey } from "src/common/util";
import { ConsultationTypeError, ConsultationTypeErrorOrigin } from "./types";
import { MedicalConsultationType } from "src/common/models/consultationType";
import { MedicalConsultationOrigin } from "src/common/models/consultationOrigin";

export interface ConsultationTypeState {
    error?: ConsultationTypeError
    consultationTypes: MedicalConsultationType[]
    consultationOrigins: MedicalConsultationOrigin[]
    createConsultationTypeSuccess: boolean
    currentConsultationType?: MedicalConsultationType
}

const initialState: ConsultationTypeState = {
    consultationTypes: [],
    consultationOrigins: [],
    createConsultationTypeSuccess: false,
}

const consultationTypeReducer = (state: ConsultationTypeState = initialState, action: any): ConsultationTypeState => {
    switch (action.type) {
        case ActionTypes.GET_CONSULTATION_TYPES_API: {
            return {
                ...state,
                consultationTypes: [],
            }
        }
        case ActionTypes.GET_CONSULTATION_TYPES_SUCCESS: {
            const successAction = action as Actions.GetConsultationTypeSuccessAction

            return {
                ...state,
                consultationTypes: parseDataKey(successAction.consultationTypes),
                error: undefined
            }
        }
        case ActionTypes.GET_CONSULTATION_TYPES_ERROR: {
            const errorAction = action as Actions.ErrorAction;

            return {
                ...state,
                error: {
                    type: ConsultationTypeErrorOrigin.GET_RECORDS,
                    detail: errorAction.error
                }
            }
        }
        case ActionTypes.CREATE_CONSULTATION_TYPE_API: {
            return {
                ...state,
                createConsultationTypeSuccess: false,
                error: undefined,
            }
        }
        case ActionTypes.CREATE_CONSULTATION_TYPE_SUCCESS: {
            return {
                ...state,
                createConsultationTypeSuccess: true,
            }
        }
        case ActionTypes.CREATE_CONSULTATION_TYPE_ERROR: {
            const errorAction = action as Actions.ErrorAction;

            return {
                ...state,
                error: {
                    type: ConsultationTypeErrorOrigin.CREATE,
                    detail: errorAction.error
                }
            }
        }
        case ActionTypes.GET_CONSULTATION_TYPE_BY_ID_API: {
            return {
                ...state,
                currentConsultationType: undefined,
                error: undefined,
            }
        }
        case ActionTypes.GET_CONSULTATION_TYPE_BY_ID_SUCCESS: {
            const successAction = action as Actions.GetConsultationTypeByIdSuccessAction

            return {
                ...state,
                currentConsultationType: successAction.consultationType,
            }
        }
        case ActionTypes.GET_CONSULTATION_TYPE_BY_ID_ERROR: {
            const errorAction = action as Actions.ErrorAction;

            return {
                ...state,
                error: {
                    type: ConsultationTypeErrorOrigin.GET_BY_ID,
                    detail: errorAction.error
                }
            }
        }
        case ActionTypes.REMOVE_CURRENT_CONSULTATION_TYPE: {
            return {
                ...state,
                currentConsultationType: undefined,
            }
        }
        case ActionTypes.UPDATE_CONSULTATION_TYPE_API: {
            return {
                ...state,
                createConsultationTypeSuccess: false,
                error: undefined,
            }
        }
        case ActionTypes.UPDATE_CONSULTATION_TYPE_SUCCESS: {
            return {
                ...state,
                createConsultationTypeSuccess: true,
            }
        }
        case ActionTypes.UPDATE_CONSULTATION_TYPE_ERROR: {
            const errorAction = action as Actions.ErrorAction;

            return {
                ...state,
                error: {
                    type: ConsultationTypeErrorOrigin.UPDATE,
                    detail: errorAction.error
                }
            }
        }
        case ActionTypes.GET_CONSULTATION_ORIGINS_API: {
            return {
                ...state,
                consultationOrigins: [],
            }
        }
        case ActionTypes.GET_CONSULTATION_ORIGINS_SUCCESS: {
            const successAction = action as Actions.GetConsultationOriginsSuccessAction

            return {
                ...state,
                consultationOrigins: parseDataKey(successAction.consultationOrigins),
                error: undefined
            }
        }
        case ActionTypes.GET_CONSULTATION_ORIGINS_ERROR: {
            const errorAction = action as Actions.ErrorAction;

            return {
                ...state,
                error: {
                    type: ConsultationTypeErrorOrigin.GET_ORIGINS,
                    detail: errorAction.error
                }
            }
        }
    }
    return state;
};

export default consultationTypeReducer