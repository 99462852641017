import { Languages } from "src/common/descriptions/types";
import { PatientsDescriptions } from "./types";

const DescriptionsEnUs: PatientsDescriptions = {
    TITLE: "Patients Records",
    TABLE: {
        ACTION_BUTTON: "",
        SEARCH_PLACEHOLDER: "Search",
        LOADING_DATA: "Loading data...",
    },
    TABLE_COLUMNS: {
        ID: "ID",
        NAME: "Name",
        SURNAME: "Surname",
        BIRTHDATE: "Birthdate",
        AGE: "Age",
        GENDER: "Gender",
        PHONE: "Phone",
        EMAIL: "Email",
        ACTION: "Actions",
    },
}

const DescriptionsEsUs: PatientsDescriptions = {
    TITLE: "Registro de Pacientes",
    TABLE: {
        ACTION_BUTTON: "",
        SEARCH_PLACEHOLDER: "Búsqueda",
        LOADING_DATA: "Obteniendo información...",
    },
    TABLE_COLUMNS: {
        ID: "Código",
        NAME: "Nombres",
        SURNAME: "Apellidos",
        BIRTHDATE: "Fecha Nac.",
        AGE: "Edad",
        GENDER: "Género",
        PHONE: "Teléfono",
        EMAIL: "Correo",
        ACTION: "Acciones",
    },
}

export const patientsDictionary = {}
patientsDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
patientsDictionary[Languages.SPANISH_US] = DescriptionsEsUs