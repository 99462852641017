import { useState } from 'react';
import * as Styles from './styles';
import { InputProps } from 'antd';

interface Props extends InputProps {
  symbol?: string
  onChange?: (e: any) => void
}

export const CurrencyInput = (props: Props) => {

  const inputProps = { ...props } as InputProps

  const [amount, setAmount] = useState<string>('');

  const handleInputChange = (event) => {
    if (props.onChange) {
      props.onChange(event)
    } else {
      const inputValue = event.target.value;
      const sanitizedInput = inputValue.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
      setAmount(sanitizedInput);
    }
  };

  return (
    <Styles.CurrencyInput
      prefix={props.symbol ?? 'Q'}
      onChange={handleInputChange}
      value={!props.onChange ? amount : undefined}
      {...inputProps}
    />
  )
}