import { Patient } from "src/common/models/patient"
import { PaymentPlanList } from "src/features/MedicalArea/PatientPayment/PaymentPlanList"

interface Props {
    patient: Patient
}

export const PaymentPlanRecords = (props: Props) => {
    return (
        <PaymentPlanList
            data={props.patient.paymentPlanDetail}
        />
    )
}
