import { Action } from 'redux'
import { Utilities } from '../types'
import * as Actions from './actionTypes'

export interface UtilitiesAction extends Action {
    utilities: Utilities
}

export interface ErrorAction extends Action {
    error?
}

export interface UtilitiesFiltersAction extends Action {
    ids: string[]
}

export const getUtilities = (ids: string[]): UtilitiesFiltersAction => ({
    type: Actions.GET_UTILITIES_API,
    ids,
})

export const getUtilitiesSuccess = (utilities: Utilities): UtilitiesAction => ({
    type: Actions.GET_UTILITIES_SUCCESS,
    utilities,
})

export const getUtilitiesError = (error?): ErrorAction => ({
    type: Actions.GET_UTILITIES_ERROR,
    error,
})