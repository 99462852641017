import { SystemDescriptions } from "src/common/descriptions/descriptions";
import { MedicalPaymentsViews } from "src/common/models/Reports/MedicalArea/payments";
import { MedicalAreaReports } from "src/common/models/Reports/report";
import { formatToCurrency } from "src/common/parser";
import { formatToDisplayDatetime } from "src/common/util";
import { SummaryType } from "src/components/SearchableTable/types";
import { searchableTableUtils } from "src/components/SearchableTable/utils";

const medicalPaymentViews = SystemDescriptions.PAGES.REPORTS.MEDICAL_AREA.PAYMENTS.VIEWS
const tableDescriptions = SystemDescriptions.PAGES.REPORTS.MEDICAL_AREA.PAYMENTS.TABLE_COLUMNS

export const medicalPaymentViewsDictionary: Record<MedicalPaymentsViews, string> = {
    [MedicalPaymentsViews.RECORD]: medicalPaymentViews.RECORD,
    [MedicalPaymentsViews.MEDICAL_CONSULTATION_TYPE]: medicalPaymentViews.MEDICAL_CONSULTATION_TYPE,
    [MedicalPaymentsViews.ORIGIN]: medicalPaymentViews.ORIGIN,
    [MedicalPaymentsViews.PATIENT]: medicalPaymentViews.PATIENT,
}

export const medicalAreaViewDictionary: Record<MedicalAreaReports, Object> = {
    [MedicalAreaReports.MEDICAL_PAYMENTS]: medicalPaymentViewsDictionary
}

export const paymentRecordColumns: any = [
    {
        title: tableDescriptions.RECORDS_VIEW.ID,
        dataIndex: "id",
        key: "id",
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: tableDescriptions.RECORDS_VIEW.DATETIME,
        dataIndex: "datetime",
        key: "datetime",
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToDisplayDatetime(value)
    },
    {
        title: tableDescriptions.RECORDS_VIEW.PATIENT,
        dataIndex: "patient",
        key: "patient",
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: tableDescriptions.RECORDS_VIEW.DESCRIPTION,
        dataIndex: "description",
        key: "description",
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: tableDescriptions.RECORDS_VIEW.AMOUNT,
        dataIndex: "credit",
        key: "credit",
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value)
    },
    {
        title: tableDescriptions.RECORDS_VIEW.CASH,
        dataIndex: "cash",
        key: "cash",
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value)
    },
    {
        title: tableDescriptions.RECORDS_VIEW.CARD,
        dataIndex: "card",
        key: "card",
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value)
    },
    {
        title: tableDescriptions.RECORDS_VIEW.ORIGIN,
        dataIndex: "consultationOrigin",
        key: "consultationOrigin",
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: tableDescriptions.RECORDS_VIEW.CONSULTATION_TYPE,
        dataIndex: "consultationTypeId",
        key: "consultationTypeId",
        align: searchableTableUtils.alignment.centerAlign,
    },
];

export const paymentTypeColumns: any = [
    {
        title: tableDescriptions.CONSULTATION_TYPE.TYPE,
        dataIndex: "consultationTypeId",
        key: "consultationTypeId",
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: tableDescriptions.CONSULTATION_TYPE.DESCRIPTION,
        dataIndex: "consultationType",
        key: "consultationType",
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: tableDescriptions.CONSULTATION_TYPE.AMOUNT,
        dataIndex: "credit",
        key: "credit",
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value)
    },
    {
        title: tableDescriptions.CONSULTATION_TYPE.CASH,
        dataIndex: "cash",
        key: "cash",
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value)
    },
    {
        title: tableDescriptions.CONSULTATION_TYPE.CARD,
        dataIndex: "card",
        key: "card",
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value)
    },
    {
        title: tableDescriptions.CONSULTATION_TYPE.ORIGIN,
        dataIndex: "consultationOrigin",
        key: "consultationOrigin",
        align: searchableTableUtils.alignment.centerAlign,
    },
];

export const paymentPatientColumns: any = [
    {
        title: tableDescriptions.PATIENT.ID,
        dataIndex: "patientId",
        key: "patientId",
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: tableDescriptions.PATIENT.PATIENT,
        dataIndex: "patient",
        key: "patient",
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: tableDescriptions.PATIENT.AMOUNT,
        dataIndex: "credit",
        key: "credit",
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value)
    },
    {
        title: tableDescriptions.PATIENT.CASH,
        dataIndex: "cash",
        key: "cash",
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value)
    },
    {
        title: tableDescriptions.PATIENT.CARD,
        dataIndex: "card",
        key: "card",
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value)
    },
];

export const paymentOriginColumns: any = [
    {
        title: "Id",
        dataIndex: "consultationOriginId",
        key: "consultationOriginId",
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: "Orígen",
        dataIndex: "consultationOrigin",
        key: "consultationOrigin",
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: "Amount",
        dataIndex: "credit",
        key: "credit",
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value)
    },
    {
        title: "Cash",
        dataIndex: "cash",
        key: "cash",
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value)
    },
    {
        title: "Card",
        dataIndex: "card",
        key: "card",
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value)
    },
];

export enum ColumnsKeys {
    AMOUNT = 'credit',
    CASH = "cash",
    CARD = "card",
    ID = 'id',
}

export const summaryColumns: Partial<Record<ColumnsKeys, SummaryType>> = {
    [ColumnsKeys.AMOUNT]: SummaryType.SUM,
    [ColumnsKeys.CASH]: SummaryType.SUM,
    [ColumnsKeys.CARD]: SummaryType.SUM,
    [ColumnsKeys.ID]: SummaryType.COUNT,
}