import * as qs from 'qs';

export const parseQueryString = (url: string) => (
    qs.parse(url, { ignoreQueryPrefix: true })
);

export const encodeUriForBackend = (item: string) => (
    encodeURIComponent(item.replace(/,/g, '__'))
);

export const capitalizeFirstLetter = (text: string) => {
    return text.replace(
        /\w+/g,
        function (w) { return w[0].toUpperCase() + w.slice(1).toLowerCase(); }
    );
}

export const removeLetters = (text: string) => text.replace(/\D/g, "")

export const parseGoogleAccountId = (number: string): string => (
    `${number.slice(0, 3)}-${number.slice(3, 6)}-${number.slice(6, 10)}`
)

export const formatToCurrency = (number: string) => `${parseFloat(number).toLocaleString('en-US', { minimumFractionDigits: 2 })}`;

export const formatThousands = (number: string) => (
    number.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
)