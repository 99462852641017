import { Languages } from "src/common/descriptions/types";
import { ConsultationTypeDescriptions } from "./types";

const DescriptionsEnUs: ConsultationTypeDescriptions = {
    TITLE: "Consultation Types Records",
    TITLE2: "Create Consultation Type",
    TITLE3: "Edit Consultation Type",
    TABLE: {
        ACTION_BUTTON: "Create New Type",
        SEARCH_PLACEHOLDER: "Search",
        LOADING_DATA: "Loading data...",
    },
    TABLE_COLUMNS: {
        NUMBER: "No",
        CODE: "Code",
        DESCRIPTION: "Description",
        PRICE: "Price",
        STATUS: "Status",
        ACTIONS: "Actions",
        ACTION_EDIT: "Edit",
        ORIGIN: "Origin",
    },
    STATUS: {
        ACTIVE: "Active",
        INACTIVE: "Inactive",
    },
    TABS: {
        CONSULTATION_TYPE: "Consultation Types",
    },
}

const DescriptionsEsUs: ConsultationTypeDescriptions = {
    TITLE: "Tipos de consulta médica",
    TITLE2: "Crear tipo de consulta",
    TITLE3: "Editar Tipo de Consulta",
    TABLE: {
        ACTION_BUTTON: "Crear tipo",
        SEARCH_PLACEHOLDER: "Buscar",
        LOADING_DATA: "Obteniendo información...",
    },
    TABLE_COLUMNS: {
        NUMBER: "No",
        CODE: "Código",
        DESCRIPTION: "Descripción",
        PRICE: "Precio",
        STATUS: "Estado",
        ACTIONS: "Acciones",
        ACTION_EDIT: "Editar",
        ORIGIN: "Orígen",
    },
    STATUS: {
        ACTIVE: "Activo",
        INACTIVE: "Inactivo",
    },
    TABS: {
        CONSULTATION_TYPE: "Tipos de Consulta",
    },
}

export const consultationTypeDictionary = {}
consultationTypeDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
consultationTypeDictionary[Languages.SPANISH_US] = DescriptionsEsUs