import { SystemConstants } from "src/common/constants";

const GET_CONSULTATION_TYPES = 'GET_CONSULTATION_TYPES';
export const GET_CONSULTATION_TYPES_API = GET_CONSULTATION_TYPES + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_CONSULTATION_TYPES_SUCCESS = GET_CONSULTATION_TYPES + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_CONSULTATION_TYPES_ERROR = GET_CONSULTATION_TYPES + SystemConstants.API_LOADER.ERROR_SUFFIX;

const CREATE_CONSULTATION_TYPE = 'CREATE_CONSULTATION_TYPE';
export const CREATE_CONSULTATION_TYPE_API = CREATE_CONSULTATION_TYPE + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const CREATE_CONSULTATION_TYPE_SUCCESS = CREATE_CONSULTATION_TYPE + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const CREATE_CONSULTATION_TYPE_ERROR = CREATE_CONSULTATION_TYPE + SystemConstants.API_LOADER.ERROR_SUFFIX;

const GET_CONSULTATION_TYPE_BY_ID = 'GET_CONSULTATION_TYPE_BY_ID';
export const GET_CONSULTATION_TYPE_BY_ID_API = GET_CONSULTATION_TYPE_BY_ID + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_CONSULTATION_TYPE_BY_ID_SUCCESS = GET_CONSULTATION_TYPE_BY_ID + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_CONSULTATION_TYPE_BY_ID_ERROR = GET_CONSULTATION_TYPE_BY_ID + SystemConstants.API_LOADER.ERROR_SUFFIX;

export const REMOVE_CURRENT_CONSULTATION_TYPE = 'REMOVE_CURRENT_CONSULTATION_TYPE';

const UPDATE_CONSULTATION_TYPE = 'UPDATE_CONSULTATION_TYPE';
export const UPDATE_CONSULTATION_TYPE_API = UPDATE_CONSULTATION_TYPE + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const UPDATE_CONSULTATION_TYPE_SUCCESS = UPDATE_CONSULTATION_TYPE + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const UPDATE_CONSULTATION_TYPE_ERROR = UPDATE_CONSULTATION_TYPE + SystemConstants.API_LOADER.ERROR_SUFFIX;

const GET_CONSULTATION_ORIGINS = 'GET_CONSULTATION_ORIGINS';
export const GET_CONSULTATION_ORIGINS_API = GET_CONSULTATION_ORIGINS + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_CONSULTATION_ORIGINS_SUCCESS = GET_CONSULTATION_ORIGINS + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_CONSULTATION_ORIGINS_ERROR = GET_CONSULTATION_ORIGINS + SystemConstants.API_LOADER.ERROR_SUFFIX;