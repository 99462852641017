import { createStore, applyMiddleware, Store, Middleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import { RootState } from 'src/state/reducer';
import rootReducer from 'src/state/reducer';
import commonSagas from 'src/common/state/sagas';
import securitySagas from 'src/features/Security/state/sagas';
import medicalAreaSagas from 'src/features/MedicalArea/state/sagas';
import employeeSagas from 'src/features/Employee/state/sagas';
import consultationTypeSagas from 'src/features/MedicalArea/ConsultationType/state/sagas';
import serviceDebitSagas from 'src/features/ServiceDebit/state/sagas';
import reportSagas from 'src/features/Reports/state/sagas';

const sagas = createSagaMiddleware();
const middleware: [Middleware] = [sagas];

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    middleware.push(logger);
}

const store: Store<RootState> = createStore(
    rootReducer,
    applyMiddleware(...middleware)
);

sagas.run(commonSagas)
sagas.run(consultationTypeSagas)
sagas.run(employeeSagas)
sagas.run(medicalAreaSagas)
sagas.run(reportSagas)
sagas.run(securitySagas)
sagas.run(serviceDebitSagas)

export default store;