import { Languages } from "../../types";
import { CompanyCardDescriptions } from "./types";

const DescriptionsEnUs: CompanyCardDescriptions = {
    ADDRESS: "Address",
    PHONE: "Phone",
    EMAIL: "Email",
}

const DescriptionsEsUs: CompanyCardDescriptions = {
    ADDRESS: "Dirección",
    PHONE: "Teléfono",
    EMAIL: "Correo",
}

export const companyCardDictionary = {}
companyCardDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
companyCardDictionary[Languages.SPANISH_US] = DescriptionsEsUs