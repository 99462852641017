import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Company } from 'src/common/models/settings';
import { User } from 'src/common/models/user';
import { selectMenuOption } from 'src/components/Sidebar/state/action';
import { MenuState } from 'src/components/Sidebar/state/reducer';
import { MenuSections } from 'src/components/Sidebar/types';
import { RootState } from 'src/state/reducer';

interface ReduxProps {
    menu: MenuState
    user?: User
    sidebarCollapsed: boolean
    company?: Company
    drawerOpened: boolean
}

const useMenuSelection = () => {

    const dispatch = useDispatch()

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        menu: state.menu,
        user: state.security.user,
        sidebarCollapsed: state.menu.sidebarCollapsed,
        drawerOpened: state.menu.drawerOpened
    }))

    const [menuSelectedKeys, setMenuSelectedKeys] = useState<string[]>([])
    const [menuOpenKeys, setmenuOpenKeys] = useState<string[]>([])

    useEffect(() => {
        if (!reduxProps.menu.selectedMenuOption) {
            getSelectedItems()
        }
    }, [reduxProps.menu.selectedMenuOption, window.location.pathname])

    const getSelectedItems = () => {
        const currentPath = window.location.pathname

        const selectedKeys: string[] = []
        const openKeys: string[] = []

        MenuSections.forEach(section => {
            let foundSection

            if (section?.path === currentPath) {
                selectedKeys.push(section.id)
                foundSection = section
            }

            section.items.forEach(item => {
                if (currentPath === item.path) {
                    selectedKeys.push(item.id)
                    openKeys.push(section.id)
                    foundSection = item
                }

                item.children.forEach(child => {

                    const checkIfIsEditPattern = (currentPath: string, childPath: string): boolean => {
                        const currenPathSplitted = currentPath.split('/').filter((str) => str !== '')
                        const childPathSplitted = childPath.split('/').filter((str) => str !== '')

                        if (currenPathSplitted.length < 2 || childPathSplitted.length < 2) {
                            return false
                        } if (currenPathSplitted.length !== childPathSplitted.length) {
                            return false
                        } else if (currenPathSplitted[0] !== childPathSplitted[0]) {
                            return false
                        } else if (Number(currenPathSplitted[1]) <= 0) {
                            return false
                        }

                        return true
                    }

                    if (currentPath === child.path || checkIfIsEditPattern(currentPath, child.path)) {
                        selectedKeys.push(item.id)
                        openKeys.push(section.id)
                        foundSection = child
                    }
                })
            })

            if (foundSection) {
                dispatch(selectMenuOption(foundSection))
            }
        })

        setMenuSelectedKeys(selectedKeys)

        if (!reduxProps.drawerOpened) {
            setmenuOpenKeys(openKeys)
        } else {
            setmenuOpenKeys([])
        }
    }

    useEffect(() => {
        if (!reduxProps.menu.selectedMenuOption) {
            getSelectedItems()
        }
    }, []);

    return { menuSelectedKeys, menuOpenKeys };
};

export default useMenuSelection;
