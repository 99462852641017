import { useDispatch, useSelector } from "react-redux"
import { NavbarDescriptions } from "src/common/descriptions/components/navbar/types"
import { SystemDescriptions } from "src/common/descriptions/descriptions"
import { User } from "src/common/models/user"
import { RootState } from "src/state/reducer"
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    LogoutOutlined,
    UserOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
} from '@ant-design/icons';
import * as Styles from './styles'
import { Link } from "react-router-dom"
import { Urls } from "src/common/urls"
import { toggleDrawerOpenStatus, toggleFullScreenMode, toggleSidebarCollapse } from "../Sidebar/state/action"

interface ReduxProps {
    user?: User
    isFullScreenMode: boolean
    isSidebarCollapsed: boolean
    isSidebarBroken: boolean
    isDrawerOpened: boolean
}

export const Navbar = () => {

    const dispatch = useDispatch()

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        user: state.security.user,
        isFullScreenMode: state.menu.isFullScreenMode,
        isSidebarCollapsed: state.menu.sidebarCollapsed,
        isSidebarBroken: state.menu.sidebarBroken,
        isDrawerOpened: state.menu.drawerOpened,
    }))

    const descriptions: NavbarDescriptions = SystemDescriptions.COMPONENTS.NAVBAR

    const renderLeftSection = () => {

        const toggleSidebarCollapseStatusClick = () => {
            if (reduxProps.isSidebarBroken) {
                dispatch(toggleDrawerOpenStatus(!reduxProps.isDrawerOpened))
            } else {
                dispatch(toggleSidebarCollapse(!reduxProps.isSidebarCollapsed))
            }
        }

        return <Styles.SectionContainer>
            <Styles.NavbarItem
                type="text"
                icon={reduxProps.isSidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={toggleSidebarCollapseStatusClick}
            />
        </Styles.SectionContainer>
    }

    const renderRightSection = () => {

        const toggleNavBarFullScreenStatus = () => {
            dispatch(toggleFullScreenMode(!reduxProps.isFullScreenMode))
        }

        return <Styles.SectionContainer>
            <Styles.NavbarItem
                type="text"
                icon={!reduxProps.isFullScreenMode ? <FullscreenOutlined /> : <FullscreenExitOutlined />}
                onClick={toggleNavBarFullScreenStatus}
            />
            <Link to={Urls.FRONTEND.USERS.EDIT_URL(reduxProps.user?.id ?? 0)}>
                <Styles.NavbarItem
                    type="text"
                    icon={<UserOutlined />}
                />
            </Link>

            <Link to={Urls.FRONTEND.LOGOUT}>
                <Styles.NavbarItem
                    type="text"
                    danger
                    icon={<LogoutOutlined />}
                    title={descriptions.LOGOUT.DISPLAY_NAME}
                >
                    {descriptions.LOGOUT.DISPLAY_NAME}
                </Styles.NavbarItem>
            </Link>
        </Styles.SectionContainer>
    }

    return (
        <Styles.HeaderApp >
            {renderLeftSection()}
            {renderRightSection()}
        </Styles.HeaderApp>

    )
}
