import { SummaryType } from "src/components/SearchableTable/types";

export enum ColumnsKeys {
    ID = 'id',
    DATETIME = "datetime",
    DESCRIPTION = "description",
    AMOUNT = 'credit',
    CASH = "cash",
    CARD = "card",
    ORIGIN = "origin",
    TYPE = 'consultationTypeId',
}

export const summaryColumns: Partial<Record<ColumnsKeys, SummaryType>> = {
    [ColumnsKeys.AMOUNT]: SummaryType.SUM,
    [ColumnsKeys.CASH]: SummaryType.SUM,
    [ColumnsKeys.CARD]: SummaryType.SUM,
}