import { Modal, Tooltip } from "antd";
import { MedicalPaymentPatientReport, PatientPayments } from "src/common/models/Reports/MedicalArea/payments";
import { formatToCurrency } from "src/common/parser";
import { formatToDisplayDatetime } from "src/common/util";
import { SearchableTable } from "src/components/SearchableTable";
import { searchableTableUtils } from "src/components/SearchableTable/utils";
import { ColumnsKeys, summaryColumns } from "./config";
import { SystemDescriptions } from "src/common/descriptions/descriptions";

interface Props {
    open: boolean
    patientReport: MedicalPaymentPatientReport
    onClose: () => void
}

export const PatientPaymentDetail = (props: Props) => {

    const descriptions = SystemDescriptions.PAGES.REPORTS.MEDICAL_AREA

    const tableColumns: any = [
        {
            title: descriptions.PAYMENTS.TABLE_COLUMNS.RECORDS_VIEW.ID,
            dataIndex: ColumnsKeys.ID,
            key: ColumnsKeys.ID,
            align: searchableTableUtils.alignment.centerAlign,
        },
        {
            title: descriptions.PAYMENTS.TABLE_COLUMNS.RECORDS_VIEW.DATETIME,
            dataIndex: ColumnsKeys.DATETIME,
            key: ColumnsKeys.DATETIME,
            align: searchableTableUtils.alignment.centerAlign,
            render: (value) => formatToDisplayDatetime(value)
        },
        {
            title: descriptions.PAYMENTS.TABLE_COLUMNS.RECORDS_VIEW.DESCRIPTION,
            dataIndex: ColumnsKeys.DESCRIPTION,
            key: ColumnsKeys.DESCRIPTION,
            align: searchableTableUtils.alignment.centerAlign,
        },
        {
            title: descriptions.PAYMENTS.TABLE_COLUMNS.RECORDS_VIEW.AMOUNT,
            dataIndex: ColumnsKeys.AMOUNT,
            key: ColumnsKeys.AMOUNT,
            align: searchableTableUtils.alignment.centerAlign,
            render: (value) => formatToCurrency(value)
        },
        {
            title: descriptions.PAYMENTS.TABLE_COLUMNS.RECORDS_VIEW.CASH,
            dataIndex: ColumnsKeys.CASH,
            key: ColumnsKeys.CASH,
            align: searchableTableUtils.alignment.centerAlign,
            render: (value) => formatToCurrency(value)
        },
        {
            title: descriptions.PAYMENTS.TABLE_COLUMNS.RECORDS_VIEW.CARD,
            dataIndex: ColumnsKeys.CARD,
            key: ColumnsKeys.CARD,
            align: searchableTableUtils.alignment.centerAlign,
            render: (value) => formatToCurrency(value)
        },
        {
            title: descriptions.PAYMENTS.TABLE_COLUMNS.RECORDS_VIEW.ORIGIN,
            dataIndex: ColumnsKeys.ORIGIN,
            key: ColumnsKeys.ORIGIN,
            align: searchableTableUtils.alignment.centerAlign,
        },
        {
            title: descriptions.PAYMENTS.TABLE_COLUMNS.RECORDS_VIEW.CONSULTATION_TYPE,
            dataIndex: ColumnsKeys.TYPE,
            key: ColumnsKeys.TYPE,
            align: searchableTableUtils.alignment.centerAlign,
            render: (value, record: PatientPayments) => <Tooltip title={record.consultationType}>
                {value}
            </Tooltip>
        },
    ];

    const renderContent = () => (
        <SearchableTable
            items={props.patientReport.payments}
            tableColumns={tableColumns}
            showLoader={false}
            summaryColumns={summaryColumns}
        />
    )

    return (
        <Modal
            title={`${descriptions.PAYMENTS.PAYMENT_DETAIL} ${props.patientReport.patient}`}
            centered
            open={props.open}
            footer={null}
            width={1000}
            destroyOnClose
            closable
            onCancel={props.onClose}
        >
            {renderContent()}
        </Modal>
    )
}
