import { SystemConstants } from "src/common/constants";

const GET_MEDICAL_AREA_REPORT = 'GET_MEDICAL_AREA_REPORT';
export const GET_MEDICAL_AREA_REPORT_API = GET_MEDICAL_AREA_REPORT + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_MEDICAL_AREA_REPORT_SUCCESS = GET_MEDICAL_AREA_REPORT + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_MEDICAL_AREA_REPORT_ERROR = GET_MEDICAL_AREA_REPORT + SystemConstants.API_LOADER.ERROR_SUFFIX;

const GET_PAYMENT_CONSULTATION_TYPE_DETAIL = 'GET_PAYMENT_CONSULTATION_TYPE_DETAIL';
export const GET_PAYMENT_CONSULTATION_TYPE_DETAIL_API = GET_PAYMENT_CONSULTATION_TYPE_DETAIL + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_PAYMENT_CONSULTATION_TYPE_DETAIL_SUCCESS = GET_PAYMENT_CONSULTATION_TYPE_DETAIL + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_PAYMENT_CONSULTATION_TYPE_DETAIL_ERROR = GET_PAYMENT_CONSULTATION_TYPE_DETAIL + SystemConstants.API_LOADER.ERROR_SUFFIX;