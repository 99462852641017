import { Alert, Button, Select } from "antd"
import { PageCard } from "src/common/styles/styles"
import { SearchableTable } from "src/components/SearchableTable"
import { medicalAreaViewDictionary, paymentOriginColumns, paymentPatientColumns, paymentRecordColumns, paymentTypeColumns, summaryColumns } from "./dictionary"
import { useEffect, useState } from "react"
import { DateRanges } from "src/components/SearchableTable/types"
import moment from "moment"
import { SystemConstants } from "src/common/constants"
import { useDispatch, useSelector } from "react-redux"
import dayjs from 'dayjs'
import { MedicalPaymentOriginReport, MedicalPaymentPatientReport, MedicalPaymentRecordsReport, MedicalPaymentTypeReport, MedicalPaymentsViews } from "src/common/models/Reports/MedicalArea/payments"
import { getMedicalReportApi, getPaymentConsultationTypeDetailApi } from "../../state/action"
import { MedicalAreaReports } from "src/common/models/Reports/report"
import { RootState } from "src/state/reducer"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { GET_MEDICAL_AREA_REPORT_API, GET_PAYMENT_CONSULTATION_TYPE_DETAIL_API } from "../../state/actionTypes"
import { ReportError } from "../../state/types"
import { getNewMedicalReportKey } from "../../state/reducerUtils"
import { PatientPaymentDetail } from "./PatientPaymentsDetail"
import { searchableTableUtils } from "src/components/SearchableTable/utils"
import { SystemDescriptions } from "src/common/descriptions/descriptions"
import { ConsultationTypePaymentsDetail } from "./ConsultationTypePaymentsDetail"
import { Loader } from "src/components/Loader"

interface ReduxProps {
    isLoadingData: boolean
    paymentsRecords?: MedicalPaymentRecordsReport[]
    paymentsConsultationType?: MedicalPaymentTypeReport[]
    paymentsOrigin?: MedicalPaymentOriginReport[]
    paymentsPatient?: MedicalPaymentPatientReport[]
    error?: ReportError
    consultationTypeDetail?: MedicalPaymentTypeReport[]
    isGettingDetail: boolean
}

export const MedicalPaymentsReport = () => {
    const dispatch = useDispatch()

    const [dateRanges, setDateRanges] = useState<DateRanges>({
        startDate: moment().startOf('month').format(SystemConstants.DATETIME_QUERY(true)),
        endDate: moment().format(SystemConstants.DATETIME_QUERY(false)),
    })

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        isLoadingData: serviceLoading(state, [GET_MEDICAL_AREA_REPORT_API]),
        paymentsRecords: state.report.medicalArea?.paymentsRecords,
        paymentsConsultationType: state.report.medicalArea?.paymentsConsultationType,
        paymentsOrigin: state.report.medicalArea?.paymentsOrigin,
        paymentsPatient: state.report.medicalArea?.paymentsPatient,
        error: state.report.error,
        consultationTypeDetail: state.report.medicalArea?.paymentConsultationTypeDetail,
        isGettingDetail: serviceLoading(state, [GET_PAYMENT_CONSULTATION_TYPE_DETAIL_API]),
    }))

    useEffect(() => {
        if (reduxProps.consultationTypeDetail) {
            setDetailOpen(true)
        }
    }, [reduxProps.consultationTypeDetail])


    const [selectedView, setSelectedView] = useState<MedicalPaymentsViews | undefined>()
    const [selectedPatientReport, setSelectedPatientReport] = useState<MedicalPaymentPatientReport | undefined>()
    const [detailOpen, setDetailOpen] = useState<boolean>(false)
    const [selectedConsultationType, setSelectedConsultationType] = useState<string>("")

    const tableDescriptions = SystemDescriptions.PAGES.REPORTS.MEDICAL_AREA.PAYMENTS.TABLE_COLUMNS
    const descriptions = SystemDescriptions.PAGES.REPORTS.MEDICAL_AREA.PAYMENTS

    const medicalPaymentTableColumnsDictionary = {
        [MedicalPaymentsViews.RECORD]: paymentRecordColumns,
        [MedicalPaymentsViews.MEDICAL_CONSULTATION_TYPE]: [
            ...paymentTypeColumns,
            {
                title: tableDescriptions.PATIENT.PAYMENTS_QTY,
                dataIndex: "paymentsQty",
                key: "paymentsQty",
                align: searchableTableUtils.alignment.centerAlign,
                render: (value, record) => {
                    const handlePaymentsQtyClick = () => {
                        setSelectedConsultationType(record.consultationType)
                        dispatch(getPaymentConsultationTypeDetailApi({
                            dateFrom: dateRanges.startDate,
                            dateTo: dateRanges.endDate,
                            view: MedicalPaymentsViews.RECORD,
                            consultationType: record.consultationTypeId,
                        }))
                    }

                    return <Button
                        type="link"
                        onClick={handlePaymentsQtyClick}
                    >
                        {value}
                    </Button>
                }
            },
        ],
        [MedicalPaymentsViews.ORIGIN]: paymentOriginColumns,
        [MedicalPaymentsViews.PATIENT]: [
            ...paymentPatientColumns,
            {
                title: tableDescriptions.PATIENT.PAYMENTS_QTY,
                dataIndex: "paymentsQty",
                key: "paymentsQty",
                align: searchableTableUtils.alignment.centerAlign,
                render: (value, record) => {
                    const handlePaymentsQtyClick = () => {
                        setSelectedPatientReport(record)
                    }

                    return <Button
                        type="link"
                        onClick={handlePaymentsQtyClick}
                    >
                        {value}
                    </Button>
                }
            },
        ],
    }

    const handleDateRangesChange = async (dateRanges: DateRanges) => {
        console.log("aca aca")
        setDateRanges({
            startDate: moment(dateRanges.startDate).format(SystemConstants.DATETIME_QUERY(true)),
            endDate: moment(dateRanges.endDate).format(SystemConstants.DATETIME_QUERY(false)),
        })
    }

    const handleViewChange = (value) => {
        setSelectedView(value)
    }

    const renderViews = () => (
        <Select
            style={{ width: '100%' }}
            placeholder={descriptions.SELECT_VIEW}
            options={Object.keys(medicalAreaViewDictionary.MEDICAL_PAYMENTS).map(view => ({
                label: medicalAreaViewDictionary.MEDICAL_PAYMENTS[view],
                value: view,
            }))}
            onChange={handleViewChange}
        />
    )

    const handleGenerate = () => {
        dispatch(getMedicalReportApi(
            MedicalAreaReports.MEDICAL_PAYMENTS,
            {
                dateFrom: dateRanges.startDate,
                dateTo: dateRanges.endDate,
                view: selectedView,
            },
        ))
    }

    const renderPaymentDetail = () => (
        selectedPatientReport &&
        <PatientPaymentDetail
            open={!!selectedPatientReport}
            patientReport={selectedPatientReport}
            onClose={() => {
                setSelectedPatientReport(undefined)
            }}
        />
    )

    const renderConsultationTypeDetail = () => (
        reduxProps.consultationTypeDetail &&
        <ConsultationTypePaymentsDetail
            open={detailOpen}
            report={reduxProps.consultationTypeDetail}
            onClose={() => {
                setDetailOpen(false)
            }}
            title={selectedConsultationType}
        />
    )

    return (
        <PageCard title={descriptions.TITLE} >
            {
                reduxProps.error && <Alert
                    message="Error" type="error" showIcon
                    style={{ marginBottom: 12 }}
                />
            }

            < SearchableTable
                showSearch
                searchLabel={descriptions.TABLE.SEARCH_PLACEHOLDER}
                items={selectedView && reduxProps[getNewMedicalReportKey(selectedView)] ? reduxProps[getNewMedicalReportKey(selectedView)] : []}
                tableColumns={selectedView ? medicalPaymentTableColumnsDictionary[selectedView] : []}
                showLoader={reduxProps.isLoadingData}
                showRangePicker
                rangePickerOnChange={handleDateRangesChange}
                showActionButton
                actionButtonLabel={descriptions.TABLE.ACTION_BUTTON}
                actionButtonOnClick={handleGenerate}
                rangePickerDefaultValues={[dayjs().startOf('month'), dayjs()]}
                actionButtonDisabled={!dateRanges || !selectedView || reduxProps.isLoadingData}
                summaryColumns={summaryColumns}
                actionButtonLoading={reduxProps.isLoadingData}
                showTotalSummary
            >
                {renderViews()}
            </SearchableTable >
            {renderPaymentDetail()}
            {
                reduxProps.isGettingDetail
                    ? <Loader
                        isVisible={true}
                        title={"Obteniendo información"}
                        description={"Por favor espera"}
                    />
                    : renderConsultationTypeDetail()
            }
        </PageCard >
    )
}
