import { useDispatch, useSelector } from "react-redux"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { GetPatientsFilters, Patient } from "src/common/models/patient"
import { GET_PATIENTS_API } from "src/features/MedicalArea/state/actionTypes"
import { MedicalAreaError, MedicalAreaErrorOrigin } from "src/features/MedicalArea/state/types"
import { RootState } from "src/state/reducer"
import InnerLoader from "../innerLoader"
import { Alert, Select } from "antd"
import { SystemConstants } from "src/common/constants"
import dayjs from 'dayjs'
import { useEffect } from "react"
import { getPatientsApi } from "src/features/MedicalArea/state/action"

interface ReduxProps {
    patients: Patient[]
    error?: MedicalAreaError
    isGettingPatients: boolean
}

interface Props {
    onChange?: (patient: Patient) => void
    filters?: GetPatientsFilters
}

export const PatientSelect = (props: Props) => {
    const dispatch = useDispatch()

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        patients: state.medicalArea.patients,
        error: state.medicalArea.error,
        isGettingPatients: serviceLoading(state, [GET_PATIENTS_API]),
    }))

    useEffect(() => {
        dispatch(getPatientsApi(props?.filters ?? {}))
    }, [])

    const handleItemChange = (value: string) => {
        const patient = reduxProps.patients.find(candidate => candidate.id_patient && candidate.id_patient === parseInt(value))

        if (patient && props.onChange) {
            props.onChange(patient)
        }

    }

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        reduxProps.isGettingPatients
            ? <InnerLoader />
            : reduxProps.error && reduxProps.error.type === MedicalAreaErrorOrigin.GET_PATIENTS
                ? <Alert type="error" message={"Error getting patients"} banner />
                :
                <Select
                    style={{ width: '100%' }}
                    showSearch
                    placeholder={"Select patient"}
                    optionFilterProp="children"
                    onChange={handleItemChange}
                    filterOption={filterOption}
                    options={reduxProps.patients.map(patient => ({
                        value: `${patient.id_patient ?? 0}`,
                        label: `${patient.name} ${patient.surname} - ${dayjs(patient.birthdate).format(SystemConstants.DATE_FORMAT)} - ${patient.doc_id}`,
                    }))}
                />
    )
}
