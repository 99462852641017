import { ApiError } from "src/features/Security/networking/types";

export enum ConsultationTypeErrorOrigin {
    GET_RECORDS = 'GET_RECORDS',
    CREATE = 'CREATE',
    GET_BY_ID = 'GET_BY_ID',
    UPDATE = 'UPDATE',
    GET_ORIGINS = 'GET_ORIGINS',
}

export interface ConsultationTypeError {
    type: ConsultationTypeErrorOrigin,
    detail: ApiError
}