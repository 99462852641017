import * as React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { AdminRouter } from "./adminRouter"
import { PrivateRoute } from "./PrivateRoute"
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useSelector } from "react-redux"
import { RootState } from "src/state/reducer"

interface ReduxProps {
	isFullScreenMode: boolean
}

const AppRouter: React.FunctionComponent = () => {
	const handle = useFullScreenHandle();

	const reduxProps: ReduxProps = useSelector((state: RootState) => ({
		isFullScreenMode: state.menu.isFullScreenMode,
	}))

	React.useEffect(() => {
		if (reduxProps.isFullScreenMode) {
			handle.enter()
		} else {
			handle.exit()
		}
	}, [reduxProps.isFullScreenMode])

	return <FullScreen handle={handle} >
		<BrowserRouter>
			<Routes>
				<Route
					path={'/*'}
					element={
						<PrivateRoute>
							<AdminRouter />
						</PrivateRoute>
					} />
			</Routes>
		</BrowserRouter>
	</FullScreen>
}

export default AppRouter
