import { SidebarDescriptions } from "src/common/descriptions/components/sidebar/types";
import { SystemDescriptions } from "src/common/descriptions/descriptions";
import { UserRoles } from "src/common/models/user";
import { Urls } from "src/common/urls";

interface MenuOptionChildren {
    path: string
    id: string
    displayName: string
}

export interface MenuOption {
    id: string;
    displayName: string;
    path: string;
    icon: string;
    iconSelected: string;
    children: MenuOptionChildren[]
}

export interface MenuSection {
    id: string;
    displayName: string;
    icon: string;
    iconSelected: string;
    items: MenuOption[]
    path?: string
    roles: UserRoles[]
}

const MenuDescriptions: SidebarDescriptions = SystemDescriptions.COMPONENTS.SIDEBAR

export const MenuSections: MenuSection[] = [
    {
        id: '0',
        displayName: MenuDescriptions.HOME.TITLE,
        icon: 'fas fa-home',
        iconSelected: '',
        roles: [UserRoles.ADMIN, UserRoles.CASHBOX, UserRoles.WAITER, UserRoles.KITCHEN],
        items: [],
        path: Urls.FRONTEND.HOME,
    },
    {
        id: 'A',
        displayName: MenuDescriptions.MEDICAL_AREA.TITLE,
        icon: 'fas fa-window-maximize',
        iconSelected: '',
        roles: [UserRoles.ADMIN, UserRoles.CASHBOX, UserRoles.WAITER],
        items: [
            {
                path: Urls.FRONTEND.MEDICAL_AREA.PATIENTS,
                id: Urls.FRONTEND.MEDICAL_AREA.PATIENTS,
                displayName: MenuDescriptions.MEDICAL_AREA.ITEMS.PATIENTS.DISPLAY_NAME,
                icon: "fas fa-window-restore",
                iconSelected: "",
                children: [],
            },
            {
                path: Urls.FRONTEND.MEDICAL_AREA.PAYMENT_ADD,
                id: Urls.FRONTEND.MEDICAL_AREA.PAYMENT_ADD,
                displayName: MenuDescriptions.MEDICAL_AREA.ITEMS.MEDICAL_PAYMENT.DISPLAY_NAME,
                icon: "fas fa-window-restore",
                iconSelected: "",
                children: [],
            },
            {
                path: Urls.FRONTEND.MEDICAL_AREA.CREATE_PAYMENT_PLAN,
                id: Urls.FRONTEND.MEDICAL_AREA.CREATE_PAYMENT_PLAN,
                displayName: MenuDescriptions.MEDICAL_AREA.ITEMS.PAYMENT_PLAN.DISPLAY_NAME,
                icon: "fas fa-window-restore",
                iconSelected: "",
                children: [],
            },
            {
                path: Urls.FRONTEND.MEDICAL_AREA.PAYMENT_PLAN_RECEIVABLES,
                id: Urls.FRONTEND.MEDICAL_AREA.PAYMENT_PLAN_RECEIVABLES,
                displayName: MenuDescriptions.MEDICAL_AREA.ITEMS.PAYMENT_PLAN_RECEIVABLES.DISPLAY_NAME,
                icon: "fas fa-window-restore",
                iconSelected: "",
                children: [],
            },
        ],
    },
    {
        id: 'SETTINGS',
        displayName: MenuDescriptions.SETTINGS.TITLE,
        icon: 'fas fa-cog',
        iconSelected: '',
        roles: [UserRoles.ADMIN, UserRoles.CASHBOX, UserRoles.WAITER],
        items: [
            {
                path: Urls.FRONTEND.SETTINGS.MEDICAL_AREA,
                id: Urls.FRONTEND.SETTINGS.MEDICAL_AREA,
                displayName: MenuDescriptions.SETTINGS.ITEMS.MEDICAL_AREA.DISPLAY_NAME,
                icon: "fas fa-briefcase-medical",
                iconSelected: "",
                children: [],
            },
        ],
    },
    {
        id: 'GERENCY',
        displayName: MenuDescriptions.GERENCY.TITLE,
        icon: 'fas fa-window-maximize',
        iconSelected: '',
        roles: [UserRoles.ADMIN, UserRoles.CASHBOX, UserRoles.WAITER],
        items: [
            {
                path: Urls.FRONTEND.REPORTS.MAIN,
                id: Urls.FRONTEND.REPORTS.MAIN,
                displayName: MenuDescriptions.GERENCY.ITEMS.REPORTS.DISPLAY_NAME,
                icon: "fas fa-window-restore",
                iconSelected: "",
                children: [],
            },
            {
                path: Urls.FRONTEND.SERVICE_DEBIT.RECORDS,
                id: Urls.FRONTEND.SERVICE_DEBIT.RECORDS,
                displayName: MenuDescriptions.GERENCY.ITEMS.SERVICE_DEBIT_RECORDS.DISPLAY_NAME,
                icon: "fas fa-window-restore",
                iconSelected: "",
                children: [],
            },
        ],
    },
]