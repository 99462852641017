import { Action } from 'redux'
import * as Actions from './actionTypes'
import { MedicalAreaReports, ReportParams } from 'src/common/models/Reports/report'
import { MedicalPaymentRecordsReport, MedicalPaymentsViews } from 'src/common/models/Reports/MedicalArea/payments'

export interface GetMedicalReportAction extends Action {
    reportType: MedicalAreaReports
    params: ReportParams
}
export interface GetMedicalReportSuccessAction extends Action {
    reportType: MedicalAreaReports
    view: MedicalPaymentsViews
    report: any
}
export interface ErrorAction extends Action {
    error?
}
export const getMedicalReportApi = (reportType: MedicalAreaReports, params: ReportParams): GetMedicalReportAction => ({
    type: Actions.GET_MEDICAL_AREA_REPORT_API,
    params,
    reportType,
})
export const getMedicalReportSuccess = (reportType: MedicalAreaReports, view: any, report: any): GetMedicalReportSuccessAction => ({
    type: Actions.GET_MEDICAL_AREA_REPORT_SUCCESS,
    reportType,
    report,
    view,
})
export const getMedicalReportError = (error?): ErrorAction => ({
    type: Actions.GET_MEDICAL_AREA_REPORT_ERROR,
    error,
})

export interface GetPaymentConsultationTypeDetailAction extends Action {
    params: ReportParams
}
export interface GetPaymentConsultationTypeDetailSuccessAction extends Action {
    report: MedicalPaymentRecordsReport[]
}
export const getPaymentConsultationTypeDetailApi = (params: ReportParams): GetPaymentConsultationTypeDetailAction => ({
    type: Actions.GET_PAYMENT_CONSULTATION_TYPE_DETAIL_API,
    params,
})
export const getPaymentConsultationTypeDetailSuccess = (report: MedicalPaymentRecordsReport[]): GetPaymentConsultationTypeDetailSuccessAction => ({
    type: Actions.GET_PAYMENT_CONSULTATION_TYPE_DETAIL_SUCCESS,
    report,
})
export const getPaymentConsultationTypeDetailError = (error?): ErrorAction => ({
    type: Actions.GET_PAYMENT_CONSULTATION_TYPE_DETAIL_ERROR,
    error,
})