import { Alert, Form, Input, Row, Select } from "antd";
import { Col20, Col4 } from "src/components/Columns";
import { ButtonsContainer } from "src/common/styles/styles";
import { ActionButton } from "src/components/ActionButton";
import { ActionButtonType } from "src/components/ActionButton/types";
import { SystemDescriptions } from "src/common/descriptions/descriptions";
import { CurrencyInput } from "src/components/CurrencyInput";
import { MedicalConsultationType } from "src/common/models/consultationType";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/reducer";
import { ConsultationTypeError, ConsultationTypeErrorOrigin } from "src/features/MedicalArea/ConsultationType/state/types";
import { ApiError } from "src/features/Security/networking/types";
import { useEffect } from "react";
import { MedicalConsultationOrigin } from "src/common/models/consultationOrigin";
import { getConsultationOriginsApi } from "src/features/MedicalArea/ConsultationType/state/action";

interface Props {
    onFinish: (consultationType: Partial<MedicalConsultationType>) => void
    onCancel: () => void
    initialValues?: MedicalConsultationType
}

interface ReduxProps {
    consultationOrigins?: MedicalConsultationOrigin[]
    error?: ConsultationTypeError
}

export const CreateConsultationTypeForm = (props: Props) => {
    const dispatch = useDispatch()

    const descriptions = SystemDescriptions.PAGES.SETTINGS.MEDICAL_AREA.CREATE_CONSULTATION_TYPE

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        consultationOrigins: state.consultationType.consultationOrigins,
        error: state.consultationType.error,
    }))

    useEffect(() => {
        dispatch(getConsultationOriginsApi())
    }, [])


    useEffect(() => {
        if (props.initialValues) {
            form.setFieldsValue({
                externalId: props.initialValues.externalId,
                description: props.initialValues.description,
                price: props.initialValues.price,
                isActive: props.initialValues.isActive,
                origin: props.initialValues.originId,
            })
        }
    }, [props.initialValues])

    const [form] = Form.useForm();

    const renderButtons = () => (
        <Form.Item>
            <ButtonsContainer>
                <ActionButton
                    label={descriptions.CANCEL_BUTTON}
                    onClick={props.onCancel}
                    actionButtonType={ActionButtonType.DESTRUCTIVE}
                />
                <ActionButton
                    label={descriptions.SAVE_BUTTON}
                    htmlType='submit'
                />
            </ButtonsContainer>
        </Form.Item>
    )

    const buildRequiredMessageErr = (fieldName: string): string => descriptions.ERRORS.REQUIRED_FIELD(fieldName)

    const onFinish = () => {
        const formValues = form.getFieldsValue()

        const data: Partial<MedicalConsultationType> = {
            externalId: formValues.externalId,
            description: formValues.description,
            price: parseFloat(formValues.price),
            originId: parseInt(formValues.origin),
        }

        if (props.initialValues) {
            data.id = props.initialValues.id
            data.isActive = formValues.isActive
        }

        props.onFinish(data)
    }

    const getErrorMessage = (error: ApiError): string => {
        let message: string = descriptions.ERRORS.UNKNOWN
        if (error?.code === 409) {
            message = descriptions.FORM.ERRORS.NOT_UNIQUE_FIELD(descriptions.FORM.EXTERNAL_ID.LABEL)
        }

        return message
    }

    return (
        <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
        >

            <Row gutter={[16, 8]}>
                <Col4>
                    <Form.Item
                        label={descriptions.FORM.EXTERNAL_ID.LABEL}
                        name={"externalId"}
                        rules={[{ required: true, message: buildRequiredMessageErr(descriptions.FORM.EXTERNAL_ID.LABEL) }]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={descriptions.FORM.EXTERNAL_ID.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col4>
                <Col20>
                    <Form.Item
                        label={descriptions.FORM.DESCRIPTION.LABEL}
                        name={"description"}
                        rules={[{ required: true, message: buildRequiredMessageErr(descriptions.FORM.DESCRIPTION.LABEL) }]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={descriptions.FORM.DESCRIPTION.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col20>
                <Col4>
                    <Form.Item
                        label={descriptions.FORM.PRICE.LABEL}
                        rules={[
                            { required: true, message: buildRequiredMessageErr(descriptions.FORM.PRICE.LABEL) },
                            {
                                message: descriptions.FORM.ERRORS.INVALID_PRICE,
                                validator: (_, value) => {
                                    const currencyRegex = /^\d+(\.\d{1,2})?$/;

                                    if (currencyRegex.test(value)) {
                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject();
                                    }
                                }
                            }
                        ]}
                        name={"price"}
                        validateTrigger="onBlur"
                    >
                        <CurrencyInput
                            placeholder={descriptions.FORM.PRICE.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col4>
                <Col4>
                    <Form.Item
                        label={descriptions.FORM.ORIGIN.LABEL}
                        rules={[
                            { required: true, message: buildRequiredMessageErr(descriptions.FORM.ORIGIN.LABEL) },
                        ]}
                        name={"origin"}
                        validateTrigger="onBlur"
                    >
                        <Select
                            placeholder={descriptions.FORM.ORIGIN.PLACEHOLDER}
                            options={reduxProps.consultationOrigins?.map(origin => ({
                                label: origin.name,
                                value: origin.id,
                            }))}
                        />
                    </Form.Item>
                </Col4>
                {
                    props.initialValues &&
                    <Col4>
                        <Form.Item
                            label={descriptions.FORM.STATUS.LABEL}
                            rules={[
                                { required: true, message: buildRequiredMessageErr(descriptions.FORM.STATUS.LABEL) },
                            ]}
                            name={"isActive"}
                            validateTrigger="onBlur"
                        >
                            <Select
                                placeholder={descriptions.FORM.STATUS.PLACEHOLDER}
                                options={[
                                    {
                                        label: descriptions.FORM.ACTIVE,
                                        value: 1,
                                    },
                                    {
                                        label: descriptions.FORM.INACTIVE,
                                        value: 0,
                                    }
                                ]}
                            />
                        </Form.Item>
                    </Col4>
                }
            </Row>
            {
                reduxProps.error &&
                (reduxProps.error.type === ConsultationTypeErrorOrigin.CREATE || reduxProps.error.type === ConsultationTypeErrorOrigin.UPDATE)
                &&
                <Alert type="error" message={getErrorMessage(reduxProps.error?.detail!)} banner />
            }
            {renderButtons()}
        </Form>
    )
}
