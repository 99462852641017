import { Navigate } from "react-router-dom"
import { Urls } from "src/common/urls"
import { getSessionData } from "src/session/sessionStore"
export const PrivateRoute = ({ children }) => {

    const sessionData = getSessionData();

    return true
        ? children
        : sessionData.accessToken
            ? children
            : <Navigate to={Urls.FRONTEND.LOGIN} replace />
}
