export enum PaymentType {
    CASH = 'CASH',
    CARD = 'CARD',
}

export interface MedicalPaymentData {
    patientId: number
    description: string
    amount: number
    cash: number
    card: number
    paymentPlanId: string
    id_employee?: number
    id?: number
}