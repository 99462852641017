export const SystemConstants = {
	API_LOADER: {
		TRIGGER_SUFFIX: "_API_REQUEST",
		SUCCESS_SUFFIX: "_API_SUCCESS",
		ERROR_SUFFIX: "_API_ERROR",
	},
	BACKEND_SERVER: {
		CONTROL_POV: process.env.REACT_APP_MATERGYM_SERVER,
	},
	DATE_FORMAT: 'DD-MM-YYYY',
	DATETIME_FORMAT: 'YYYY-MM-DD HH:mm',
	DATETIME_FORMAT_DISPLAY: 'DD-MM-YYYY HH:mm:ss',
	DATETIME_QUERY: (isStart: boolean) => isStart ? 'YYYY-MM-DD 00:00:01' : 'YYYY-MM-DD 23:59:59',
	DEFAULT_REDIRECT_URL: 'https://syso.evolutionfit.org/admin/main-admin.php',
	QUERY_PARAMS: {
		EMBED: 'embed',
		ACCESS_TOKEN: 'accessToken',
	},
}