import { getCommonPagesDescriptions } from "./Common"
import { getReportsPagesDescriptions } from "./Reports"
import { getSettingsPagesDescriptions } from "./Settings"
import { getOperatedBySelectDescriptions } from "./components/OperatedBySelect"
import { getBreadcrumbDescriptions } from "./components/breadcrumb"
import { getFooterDescriptions } from "./components/footer"
import { getNavbarDescriptions } from "./components/navbar"
import { getSearchableTableDescriptions } from "./components/searchableTable"
import { getSidebarDescriptions } from "./components/sidebar"
import { getDashboardDescriptions } from "./dashboard"
import { getMedicalAreaDescriptions } from "./medicalArea"
import { DefaultSettings, Descriptions } from "./types"

export const SystemDescriptions: Descriptions = {
    PAGES: {
        COMMON: getCommonPagesDescriptions(DefaultSettings.SYSTEM_LANG),
        DASHBOARD: getDashboardDescriptions(DefaultSettings.SYSTEM_LANG),
        MEDICAL_AREA: getMedicalAreaDescriptions(DefaultSettings.SYSTEM_LANG),
        REPORTS: getReportsPagesDescriptions(DefaultSettings.SYSTEM_LANG),
        SETTINGS: getSettingsPagesDescriptions(DefaultSettings.SYSTEM_LANG),
    },
    COMPONENTS: {
        SIDEBAR: getSidebarDescriptions(DefaultSettings.SYSTEM_LANG),
        NAVBAR: getNavbarDescriptions(DefaultSettings.SYSTEM_LANG),
        BREADCRUMB: getBreadcrumbDescriptions(DefaultSettings.SYSTEM_LANG),
        SEARCHABLE_TABLE: getSearchableTableDescriptions(DefaultSettings.SYSTEM_LANG),
        FOOTER: getFooterDescriptions(DefaultSettings.SYSTEM_LANG),
        OPERATED_BY_SELECT: getOperatedBySelectDescriptions(DefaultSettings.SYSTEM_LANG),
    }
}