import { Tabs, TabsProps } from "antd"
import { useState } from "react"
import { Patient } from "src/common/models/patient"
import { PageCard } from "src/common/styles/styles"
import { PatientSelect } from "src/components/PatientSelect"
import { CreatePatientForm } from "../Patient/CreatePatient/CreatePatientForm"
import { CreatePaymentPlanForm } from "./CreatePaymentPlanForm"
import { CancelConfirmationModal } from "src/components/cancelConfirmationModal"
import { SystemDescriptions } from "src/common/descriptions/descriptions"
import { checkIfSystemIsEmbed } from "src/common/util"
import { SystemConstants } from "src/common/constants"
import { Urls } from "src/common/urls"
import { useNavigate } from "react-router-dom"
import { ModalSuccess } from "src/components/ModalSuccess"
import { MedicalAreaError } from "../state/types"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/state/reducer"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { CREATE_PAYMENT_PLAN_API } from "../state/actionTypes"
import { PaymentPlanData } from "src/common/models/paymentPlan"
import { UserRequest } from "src/common/models/employee"
import { createPaymentPlanApi, getPatientByIdApi } from "../state/action"
import { Loader } from "src/components/Loader"
import { PaymentPlanList } from "../PatientPayment/PaymentPlanList"

interface ReduxProps {
    error?: MedicalAreaError
    isCreatingPaymentPlan: boolean
    createPaymentPlanSuccess: boolean
    currentPatient?: Patient
}

export const CreatePaymentPlan = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        error: state.medicalArea.error,
        isCreatingPaymentPlan: serviceLoading(state, [CREATE_PAYMENT_PLAN_API]),
        createPaymentPlanSuccess: state.medicalArea.createPaymentPlanSuccess,
        currentPatient: state.medicalArea.currentPatient,
    }))

    const [formValues, setFormValues] = useState<PaymentPlanData | undefined>()
    const [userRequest, setUserRequest] = useState<UserRequest | undefined>(undefined)
    const [selectedPatient, setSelectedPatient] = useState<Patient | undefined>()
    const [modalConfirmationOpen, setModalConfirmationOpen] = useState<boolean>(false)
    const [modalCancelOpen, setModalCancelOpen] = useState<boolean>(false)
    const [actionButtonClicked, setActionButtonClicked] = useState<boolean>(false)

    const descriptions = SystemDescriptions.PAGES.MEDICAL_AREA.CREATE_PAYMENT_PLAN

    const handlePatientChange = (patient: Patient) => {
        setSelectedPatient(patient)
        dispatch(getPatientByIdApi(patient.id_patient ?? 0, { populated: "1" }))
    }

    const renderSearchSection = () => (
        <PageCard size="small" title={descriptions.PERSONAL_DATA.TITLE}>
            <PatientSelect
                onChange={handlePatientChange}
            />
        </PageCard>
    )

    const handleFinishForm = (formFields, userRequest: UserRequest) => {
        if (!formFields || !userRequest) {
            return
        }

        setModalConfirmationOpen(true)
        setFormValues(formFields)
        setUserRequest(userRequest)
    }

    const handleCancelForm = () => {
        setModalCancelOpen(true)
    }

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: descriptions.PAYMENT_PLAN.TAB_PAYMENT_PLAN,
            children: <CreatePaymentPlanForm
                onFinish={handleFinishForm}
                onCancel={handleCancelForm}
            />,
        },
        {
            key: '2',
            label: descriptions.PAYMENT_PLAN.TAB_PATIENT_PROFILE,
            children: <CreatePatientForm
                readOnly
                patient={selectedPatient}
            />,
        },
        {
            key: '3',
            label: descriptions.PAYMENT_PLAN.TAB_PAYMENT_PLAN_RECORDS,
            children: <PaymentPlanList
                data={reduxProps.currentPatient?.paymentPlanDetail ?? []}
            />,
        },
    ];

    const renderPlanSection = () => (
        selectedPatient &&
        <PageCard size="small" title={`${selectedPatient.name} ${selectedPatient.surname}`} >
            <Tabs defaultActiveKey="1" items={items} />
        </PageCard>
    )

    const handleConfirmationAccept = () => {
        setModalConfirmationOpen(false)

        if (!userRequest || !formValues || !selectedPatient) {
            return
        }

        setActionButtonClicked(true)
        dispatch(createPaymentPlanApi({
            ...formValues,
            patientId: selectedPatient?.id_patient ?? 0,
        }, userRequest))
    }

    const handleCancelAccept = () => {
        const isEmbed = checkIfSystemIsEmbed()

        if (isEmbed) {
            if (window.self !== window.top) {
                window.parent.location.href = SystemConstants.DEFAULT_REDIRECT_URL
            } else {
                window.location.href = SystemConstants.DEFAULT_REDIRECT_URL
            }
        } else {
            navigate(Urls.FRONTEND.HOME)
        }
    }

    const renderModals = () => (
        <>
            <CancelConfirmationModal
                isVisible={modalConfirmationOpen}
                onAccept={handleConfirmationAccept}
                onCancel={() => { setModalConfirmationOpen(false) }}
                title={descriptions.CONFIRM_MODAL.TITLE}
                okText={descriptions.CONFIRM_MODAL.ACTION_BUTTON}
                cancelText={descriptions.CONFIRM_MODAL.CANCEL_BUTTON}
                description={descriptions.CANCEL_MODAL.DESCRIPTION}
            />
            <CancelConfirmationModal
                isVisible={modalCancelOpen}
                onAccept={handleCancelAccept}
                onCancel={() => { setModalCancelOpen(false) }}
                title={descriptions.CANCEL_MODAL.TITLE}
                okText={descriptions.CANCEL_MODAL.ACTION_BUTTON}
                cancelText={descriptions.CANCEL_MODAL.CANCEL_BUTTON}
                description={descriptions.CANCEL_MODAL.DESCRIPTION}
            />
            <ModalSuccess
                isVisible={reduxProps.createPaymentPlanSuccess && actionButtonClicked}
                title={descriptions.SUCCESS_MODAL.TITLE}
                description={descriptions.SUCCESS_MODAL.DESCRIPTION}
                onPrimaryAction={handleCancelAccept}
                onSecondaryActiont={handleCancelAccept}
                primaryLabel={descriptions.SUCCESS_MODAL.PRIMARY_LABEL}
                secondaryLabel={descriptions.SUCCESS_MODAL.SECONDARY_LABEL}
                hideSecondaryButton={true}
            />
        </>
    )

    const renderLoader = () => (
        reduxProps.isCreatingPaymentPlan && <Loader
            isVisible={true}
            title={descriptions.PAYMENT_PLAN.SAVING_TITLE}
            description={descriptions.PAYMENT_PLAN.SAVING_DESCRIPTION}
        />
    )

    return (
        <>
            {renderSearchSection()}
            {renderPlanSection()}
            {renderModals()}
            {renderLoader()}
        </>
    )
}