import { Patient } from "src/common/models/patient"
import { SearchableTable } from "src/components/SearchableTable"
import { tableColumns } from "./config"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/state/reducer"
import { GET_MEDICAL_CONSULTATION_RECORDS_API } from "src/features/MedicalArea/state/actionTypes"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { MedicalConsultation } from "src/common/models/medicalConsultation"
import { getMedicalConsultationRecordsApi } from "src/features/MedicalArea/state/action"
import { useEffect } from "react"

interface Props {
    patient: Patient
}

interface ReduxProps {
    isLoadingData: boolean
    consultationRecords: MedicalConsultation[]
}

export const ConsultationRecords = (props: Props) => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getMedicalConsultationRecordsApi(props.patient.id_patient!))
    }, [])


    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        isLoadingData: serviceLoading(state, [GET_MEDICAL_CONSULTATION_RECORDS_API]),
        consultationRecords: state.medicalArea.medicalConsultations ?? [],
    }))

    const renderContent = () => (
        <SearchableTable
            items={reduxProps.consultationRecords}
            tableColumns={tableColumns}
            showLoader={reduxProps.isLoadingData}
        />
    )

    return (
        renderContent()
    )
}
