import * as Actions from "./action";
import * as ActionTypes from "./actionTypes";
import { parseDataKey } from "src/common/util";
import { ServiceDebitError, ServiceDebitErrorOrigin } from "./types";
import { ServiceDebitBatch } from "src/common/models/serviceDebitBatch";

export interface ServiceDebitState {
    error?: ServiceDebitError
    serviceDebits: ServiceDebitBatch[]
}

const initialState: ServiceDebitState = {
    serviceDebits: [],
}

const serviceDebitReducer = (state: ServiceDebitState = initialState, action: any): ServiceDebitState => {
    switch (action.type) {
        case ActionTypes.GET_SERVICE_DEBITS_API: {
            return {
                ...state,
                serviceDebits: [],
                error: undefined,
            }
        }
        case ActionTypes.GET_SERVICE_DEBITS_SUCCESS: {
            const successAction = action as Actions.GetServiceDebitsSuccessAction;

            return {
                ...state,
                serviceDebits: parseDataKey(successAction.serviceDebits),
            }
        }
        case ActionTypes.GET_SERVICE_DEBITS_ERROR: {
            const errorAction = action as Actions.ErrorAction;

            return {
                ...state,
                error: {
                    type: ServiceDebitErrorOrigin.GET_RECORDS,
                    detail: errorAction.error
                }
            }
        }
    }
    return state;
};

export default serviceDebitReducer