import { Languages } from "src/common/descriptions/types";
import { ReportMedicalPaymentsDescriptions } from "./types";

const DescriptionsEnUs: ReportMedicalPaymentsDescriptions = {
    TITLE: "Medical Payments Report",
    TABLE: {
        ACTION_BUTTON: "Generate",
        SEARCH_PLACEHOLDER: "Search",
        LOADING_DATA: "Loading data...",
    },
    VIEWS: {
        RECORD: 'Record',
        ORIGIN: 'Consultation Origin',
        MEDICAL_CONSULTATION_TYPE: 'Consultation Type',
        PATIENT: 'Patient',
    },
    TABLE_COLUMNS: {
        RECORDS_VIEW: {
            ID: "Id",
            DATETIME: "Datetime",
            PATIENT: "Patient",
            DESCRIPTION: "Description",
            AMOUNT: "Amount",
            CASH: "Cash",
            CARD: "Card",
            ORIGIN: "Origin",
            CONSULTATION_TYPE: "Type",
        },
        CONSULTATION_TYPE: {
            TYPE: "Type",
            DESCRIPTION: "Description",
            AMOUNT: "Amount",
            CASH: "Cash",
            CARD: "Card",
            ORIGIN: "Origin",
            PAYMENTS_QTY: "Payments",
        },
        ORIGIN: {
            ID: "Id",
            ORIGIN: "Origin",
            AMOUNT: "Amount",
            CASH: "Cash",
            CARD: "Card",
        },
        PATIENT: {
            ID: "Id",
            PATIENT: "Patient",
            AMOUNT: "Amount",
            CASH: "Cash",
            CARD: "Card",
            PAYMENTS_QTY: "Payments Qty",
        }
    },
    PAYMENT_DETAIL: "Payment Detail: ",
    SELECT_VIEW: "Select view",
}

const DescriptionsEsUs: ReportMedicalPaymentsDescriptions = {
    TITLE: "Registro de pagos - Área Médica",
    TABLE: {
        ACTION_BUTTON: "Generar",
        SEARCH_PLACEHOLDER: "Búsqueda",
        LOADING_DATA: "Obteniendo información...",
    },
    VIEWS: {
        RECORD: 'Registro',
        ORIGIN: 'Orígen de consulta',
        MEDICAL_CONSULTATION_TYPE: 'Tipo de consulta',
        PATIENT: 'Paciente',
    },
    TABLE_COLUMNS: {
        RECORDS_VIEW: {
            ID: "Código",
            DATETIME: "Fecha",
            PATIENT: "Paciente",
            DESCRIPTION: "Descripción",
            AMOUNT: "Total",
            CASH: "Efectivo",
            CARD: "Tarjeta",
            ORIGIN: "Orígen",
            CONSULTATION_TYPE: "Tipo",
        },
        CONSULTATION_TYPE: {
            TYPE: "Tipo",
            DESCRIPTION: "Descripción",
            AMOUNT: "Total",
            CASH: "Efectivo",
            CARD: "Tarjeta",
            ORIGIN: "Orígen",
            PAYMENTS_QTY: "Pagos",
        },
        ORIGIN: {
            ID: "Código",
            ORIGIN: "Orígen",
            AMOUNT: "Total",
            CASH: "Efectivo",
            CARD: "Tarjeta",
        },
        PATIENT: {
            ID: "Codigo",
            PATIENT: "Paciente",
            AMOUNT: "Total",
            CASH: "Efectivo",
            CARD: "Tarjeta",
            PAYMENTS_QTY: "Cant Pagos",
        },
    },
    PAYMENT_DETAIL: "Detalle de Pagos:",
    SELECT_VIEW: "Seleccione vista",
}

export const medicalPaymentsReportDictionary = {}
medicalPaymentsReportDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
medicalPaymentsReportDictionary[Languages.SPANISH_US] = DescriptionsEsUs