import { User } from "src/common/models/user";
import { IPurchase, ISale } from "src/common/types";

export interface SessionData {
    accessToken?: string,
    user?: User
    currentSale?: ISale
    currentPurchase?: IPurchase
}

export enum SessionDataField {
    ACCESS_TOKEN = 'ACCESS_TOKEN',
    USER = 'USER',
    CURRENT_SALE = 'CURRENT_SALE',
    CURRENT_PURCHASE = 'CURRENT_PURCHASE',
}