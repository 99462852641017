import { Card } from 'antd'
import * as Styles from './styles'

export const Dashboard = () => {

    return (
        <Styles.ContentContainer>
            <Card size="small" title="" style={{ width: '100%' }}>
                Bienvenido a Master Gym.
            </Card>

        </Styles.ContentContainer>
    )
}
