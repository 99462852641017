import { ApiError } from "src/features/Security/networking/types";

export enum ReportOrigin {
    MEDICAL_PAYMENTS = 'MEDICAL_PAYMENTS',
    CONSULTATION_TYPE_DETAIL = 'CONSULTATION_TYPE_DETAIL',
}

export interface ReportError {
    type: ReportOrigin,
    detail: ApiError
}