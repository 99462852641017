import { FlexDiv } from "src/common/styles/div";
import styled from "styled-components";

export const HeaderContainer = styled(FlexDiv)`
    justify-contenet: center;
    align-items: center;
    gap: 8px;
`

export const SummaryContainer = styled.div`
    flex: 1 !important;
`