import { Modal, Result } from "antd"
import { QuestionOutlined } from '@ant-design/icons';
import * as Styles from './styles'

type Props = {
    isVisible: boolean
    onCancel?: () => void
    onAccept: () => void
    title: string
    okText: string
    cancelText: string
    description: string
    actionButtonDanger?: boolean
}

export const CancelConfirmationModal = (props: Props) => {

    const renderButtonsSection = () => {
        return <Styles.Container key={1}>
            <Styles.ActionButton
                onClick={props.onCancel} key="console"
            >
                {props.cancelText}
            </Styles.ActionButton>
            <Styles.ActionButton
                onClick={props.onAccept}
                type="primary"
                danger={props.actionButtonDanger}
                key="buy"
            >
                {props.okText}
            </Styles.ActionButton>
        </Styles.Container>
    }

    return (
        <Modal
            title={''}
            centered
            open={props.isVisible}
            onCancel={props.onCancel}
            width={500}
            onOk={props.onAccept}
            okText={props.okText}
            cancelText={props.cancelText}
            footer={<></>}
            closable={false}
            zIndex={1200}
            getContainer={false}
        >
            <Result
                icon={<QuestionOutlined />}
                title={props.title}
                subTitle={props.description}
                extra={[
                    renderButtonsSection(),
                ]}
            />
        </Modal>
    )
}
