export enum MedicalPaymentsViews {
    RECORD = 'RECORD',
    ORIGIN = 'ORIGIN',
    MEDICAL_CONSULTATION_TYPE = 'MEDICAL_CONSULTATION_TYPE',
    PATIENT = 'PATIENT',
}

export interface MedicalPaymentRecordsReport {
    id: string
    datetime: string
    patientId: number
    patient: string
    description: string
    credit: number
    cash: number
    card: number
    operatedBy: string
    paymentPlanId: string
    consultationTypeId: string
    consultationType: string
    consultationOriginId: number
    consultationOrigin: string
}

export interface MedicalPaymentTypeReport {
    consultationTypeId: string
    consultationType: string
    credit: number
    cash: number
    card: number
    operatedBy: string
    paymentPlanId: string
    consultationOriginId: number
    consultationOrigin: string
}

export interface MedicalPaymentOriginReport {
    consultationOriginId: number
    consultationOrigin: string
    credit: number
    cash: number
    card: number
}

export interface PatientPayments {
    id: number
    credit: number
    cash: number
    card: number
    origin: string
    consultationTypeId: string
    datetime: string
    consultationType: string
}

export interface MedicalPaymentPatientReport {
    patientId: number
    patient: string
    paymentsQty: number
    credit: number
    cash: number
    card: number
    payments: PatientPayments[]
}