import { Tabs, TabsProps } from "antd";
import { PageCard } from "src/common/styles/styles";
import { MedicalAreaTabs } from "./types";
import { ConsultationType } from "./ConsultationType";
import { SystemDescriptions } from "src/common/descriptions/descriptions";

export const MedicalAreaSettings = () => {

    const descriptions = SystemDescriptions.PAGES.SETTINGS.MEDICAL_AREA.CONSULTATION_TYPE

    const items: TabsProps['items'] = [
        {
            key: MedicalAreaTabs.CONSULTATION_TYPE,
            label: descriptions.TABS.CONSULTATION_TYPE,
            children: <ConsultationType />,
        },
    ];

    return (
        <PageCard size="small" title={``} >
            <Tabs defaultActiveKey="1" items={items} />
        </PageCard>
    )
}
