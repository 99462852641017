import { Select } from "antd"
import { UserRequest } from "./types"
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getEmployersApi } from "src/features/Employee/state/action"
import { Employee } from "src/common/models/employee"
import { RootState } from "src/state/reducer"
import { ModalCredentials } from "./ModalCredentials"
import { notification } from 'antd';
import React from "react"
import { NotificationPlacement } from "antd/es/notification/interface"
import { stringToBase64 } from "src/common/util"
import { SystemDescriptions } from "src/common/descriptions/descriptions"

interface ReduxProps {
    employers: Employee[]
}

interface Props {
    onChange: (user: UserRequest | undefined) => void
}

const Context = React.createContext({ name: 'OperatedBy' });

export const OperatedBySelect = (props: Props) => {
    const dispatch = useDispatch()

    const contextValue = useMemo(() => ({ name: 'OperatedBy' }), []);

    const [api, contextHolder] = notification.useNotification();

    const [modalCredentialsOpen, setModalCredentialsOpen] = useState<boolean>(false)
    const [userRequest, setUserRequest] = useState<UserRequest>({ username: '', password: '' })
    const [selectedValue, setSelectedValue] = useState<any>(undefined);

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        employers: state.employee.employers,
    }))

    useEffect(() => {
        dispatch(getEmployersApi({ active: "1" }))
    }, [dispatch])

    const descriptions = SystemDescriptions.COMPONENTS.OPERATED_BY_SELECT

    const openNotification = (placement: NotificationPlacement) => {
        api.info({
            message: descriptions.CREDENTIALS_RECEIVED,
            placement,
        });
    };

    const handleUserChange = (value: string) => {
        setSelectedValue(value)
        setModalCredentialsOpen(true)
        const employee = reduxProps.employers.find(candidate => candidate.username === value)

        const userReq = {
            ...userRequest,
            username: employee?.username ?? '',
        }
        setUserRequest(userReq)
    }

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const handlePasswordAccept = (password: string) => {
        const userReq = {
            ...userRequest,
            password: stringToBase64(password),
        }
        setUserRequest(userReq)
        setModalCredentialsOpen(false)
        props.onChange(userReq)
        openNotification('topRight')
    }

    return (
        <Context.Provider value={contextValue}>
            {contextHolder}
            <Select
                onChange={handleUserChange}
                options={reduxProps.employers?.map(employee => ({
                    value: `${employee.username}`,
                    label: employee.username,
                }))}
                placeholder={descriptions.PLACEHOLDER}
                showSearch
                optionFilterProp="children"
                filterOption={filterOption}
                value={selectedValue}
            />
            <ModalCredentials
                open={modalCredentialsOpen}
                onAccept={handlePasswordAccept}
                onCancel={() => {
                    setSelectedValue(undefined)
                    setModalCredentialsOpen(false)
                    props.onChange(undefined)
                }}
                username={userRequest.username}
            />
        </Context.Provider>
    )
}
