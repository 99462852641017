export const Colors = {
	// Whites
	WHITE: "#ffffff",
	WHITE_SHADE_1: "#F2F2F2",

	// Blacks
	BLACK: "#000000",

	//Blues
	BLUE_ZODIAC: "#373F53",

	//Greens
	GREEN_ENABLED: "#079AA5",
	GREEN_SUCCESS: "#41AE55",

	RED_DANGER: "#E04753",

	INNER_LOADER: "#000",

	addTransparencyToColor: (color: string, opacity: number) => {
		let opacityString = Math.round(opacity * 256).toString(16)
		if (opacityString.length === 1) {
			opacityString = "0" + opacityString
		}
		return color + opacityString
	},
}
