import * as Actions from "./action";
import * as ActionTypes from "./actionTypes";
import { parseDataKey } from "src/common/util";
import { Employee } from "src/common/models/employee";
import { EmployeeError, EmployeeErrorOrigin } from "./types";

export interface EmployeeState {
    error?: EmployeeError
    employers: Employee[]
}

const initialState: EmployeeState = {
    employers: [],
}

const employeeReducer = (state: EmployeeState = initialState, action: any): EmployeeState => {
    switch (action.type) {
        case ActionTypes.GET_EMPLOYERS_API: {
            return {
                ...state,
                employers: [],
            }
        }
        case ActionTypes.GET_EMPLOYERS_SUCCESS: {
            const successAction = action as Actions.GetEmployersSuccessAction

            return {
                ...state,
                employers: parseDataKey(successAction.employers),
                error: undefined
            }
        }
        case ActionTypes.GET_EMPLOYERS_ERROR: {
            const errorAction = action as Actions.ErrorAction;

            return {
                ...state,
                employers: [],
                error: {
                    type: EmployeeErrorOrigin.GET_RECORDS,
                    detail: errorAction.error
                }
            }
        }
    }
    return state;
};

export default employeeReducer