import { call, put, takeLatest, } from 'redux-saga/effects';
import * as ActionTypes from './actionTypes';
import * as Actions from './action';
import { Urls } from 'src/common/urls';
import { NetworkingProvider } from 'src/features/Security/networking/networkingProvider';
import { GetEmployersFilters } from 'src/common/models/employee';

const buildEmployersQuery = (filters?: GetEmployersFilters): string => {
    const params: string[] = []
    if (!filters) {
        return ''
    }

    if (filters.active) {
        params.push(`active=${filters.active}`)
    }

    return params.length > 0
        ? `?${params.join('&')}`
        : ``
}

function* getEmployersApi(action: Actions.GetEmployersAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getAppInterface().get, Urls.BACKEND.EMPLOYEE.GET(buildEmployersQuery(action.filters)))
        yield put(Actions.getEmployersSuccess(response.data))
    } catch (err) {
        yield put(Actions.getEmployersError(err));
    }
}

export default function* employeeSagas() {
    yield takeLatest(ActionTypes.GET_EMPLOYERS_API, getEmployersApi)
}