import { Modal } from "antd";
import { MedicalPaymentTypeReport } from "src/common/models/Reports/MedicalArea/payments";
import { SearchableTable } from "src/components/SearchableTable";
import { paymentRecordColumns, summaryColumns } from "../dictionary";
import { SystemDescriptions } from "src/common/descriptions/descriptions";

interface Props {
    open: boolean
    report: MedicalPaymentTypeReport[]
    onClose: () => void
    title: string
}

export const ConsultationTypePaymentsDetail = (props: Props) => {

    const descriptions = SystemDescriptions.PAGES.REPORTS.MEDICAL_AREA
    const commonDescriptions = SystemDescriptions.PAGES.COMMON

    const tableColumns: any = [
        ...paymentRecordColumns,
    ];

    const renderContent = () => (
        <SearchableTable
            items={props.report}
            tableColumns={tableColumns}
            showLoader={false}
            summaryColumns={summaryColumns}
            showSearch
            searchLabel={commonDescriptions.SEARCH}
        />
    )

    return (
        <Modal
            title={`${descriptions.PAYMENTS.PAYMENT_DETAIL} ${props.title}`}
            centered
            open={props.open}
            footer={null}
            width={1000}
            destroyOnClose
            closable
            onCancel={props.onClose}
            bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(90vh - 200px)' }}
        >
            {renderContent()}
        </Modal>
    )
}
