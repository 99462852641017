import { MedicalPaymentsViews } from "src/common/models/Reports/MedicalArea/payments";

export const getNewMedicalReportKey = (view: string): string => {
    const dictionaryOfReports = {
        [MedicalPaymentsViews.RECORD]: "paymentsRecords",
        [MedicalPaymentsViews.MEDICAL_CONSULTATION_TYPE]: "paymentsConsultationType",
        [MedicalPaymentsViews.ORIGIN]: "paymentsOrigin",
        [MedicalPaymentsViews.PATIENT]: "paymentsPatient",
    }

    return dictionaryOfReports[view]
}