import { Action } from 'redux'
import * as Actions from './actionTypes'
import { GetMedicalConsultationTypeFilters, MedicalConsultationType } from 'src/common/models/consultationType'
import { MedicalConsultationOrigin } from 'src/common/models/consultationOrigin'

export interface GetConsultationTypesAction extends Action {
    filters?: GetMedicalConsultationTypeFilters
}
export interface GetConsultationTypeSuccessAction extends Action {
    consultationTypes: MedicalConsultationType[]
}
export interface ErrorAction extends Action {
    error?
}
export const getConsultationTypesApi = (filters?: GetMedicalConsultationTypeFilters): GetConsultationTypesAction => ({
    type: Actions.GET_CONSULTATION_TYPES_API,
    filters,
})
export const getConsultationTypesSuccess = (consultationTypes: MedicalConsultationType[]): GetConsultationTypeSuccessAction => ({
    type: Actions.GET_CONSULTATION_TYPES_SUCCESS,
    consultationTypes,
})
export const getConsultationTypesError = (error?): ErrorAction => ({
    type: Actions.GET_CONSULTATION_TYPES_ERROR,
    error,
})

export interface CreateConsultationTypeAction extends Action {
    consultationType: MedicalConsultationType
}
export interface CreateConsultationTypeSuccessAction extends Action {
    consultationType: MedicalConsultationType
}
export const createConsultationTypeApi = (consultationType: MedicalConsultationType): CreateConsultationTypeAction => ({
    type: Actions.CREATE_CONSULTATION_TYPE_API,
    consultationType,
})
export const createConsultationTypeSuccess = (consultationType: MedicalConsultationType): CreateConsultationTypeSuccessAction => ({
    type: Actions.CREATE_CONSULTATION_TYPE_SUCCESS,
    consultationType,
})
export const createConsultationTypeError = (error?): ErrorAction => ({
    type: Actions.CREATE_CONSULTATION_TYPE_ERROR,
    error,
})

export interface GetConsultationTypeByIdAction extends Action {
    id: number
}
export interface GetConsultationTypeByIdSuccessAction extends Action {
    consultationType: MedicalConsultationType
}
export const getConsultationTypeByIdApi = (id: number): GetConsultationTypeByIdAction => ({
    type: Actions.GET_CONSULTATION_TYPE_BY_ID_API,
    id,
})
export const getConsultationTypeByIdSuccess = (consultationType: MedicalConsultationType): GetConsultationTypeByIdSuccessAction => ({
    type: Actions.GET_CONSULTATION_TYPE_BY_ID_SUCCESS,
    consultationType,
})
export const getConsultationTypeByIdError = (error?): ErrorAction => ({
    type: Actions.GET_CONSULTATION_TYPE_BY_ID_ERROR,
    error,
})

export const removeCurrentConsultatioType = (): Action => ({
    type: Actions.REMOVE_CURRENT_CONSULTATION_TYPE,
})

export interface UpdateConsultationTypeAction extends Action {
    consultationType: MedicalConsultationType
}
export interface UpdateConsultationTypeSuccessAction extends Action {
    consultationType: MedicalConsultationType
}
export const updateConsultationTypeApi = (consultationType: MedicalConsultationType): UpdateConsultationTypeAction => ({
    type: Actions.UPDATE_CONSULTATION_TYPE_API,
    consultationType,
})
export const updateConsultationTypeSuccess = (consultationType: MedicalConsultationType): UpdateConsultationTypeSuccessAction => ({
    type: Actions.UPDATE_CONSULTATION_TYPE_SUCCESS,
    consultationType,
})
export const updateConsultationTypeError = (error?): ErrorAction => ({
    type: Actions.UPDATE_CONSULTATION_TYPE_ERROR,
    error,
})

export interface GetConsultationOriginsSuccessAction extends Action {
    consultationOrigins: MedicalConsultationOrigin[]
}
export const getConsultationOriginsApi = (): Action => ({
    type: Actions.GET_CONSULTATION_ORIGINS_API,
})
export const getConsultationOriginsSuccess = (consultationOrigins: MedicalConsultationOrigin[]): GetConsultationOriginsSuccessAction => ({
    type: Actions.GET_CONSULTATION_ORIGINS_SUCCESS,
    consultationOrigins,
})
export const getConsultationOriginsError = (error?): ErrorAction => ({
    type: Actions.GET_CONSULTATION_ORIGINS_ERROR,
    error,
})