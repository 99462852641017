import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { MedicalConsultationType } from "src/common/models/consultationType"
import { SearchableTable } from "src/components/SearchableTable"
import { getConsultationOriginsApi, getConsultationTypesApi } from "src/features/MedicalArea/ConsultationType/state/action"
import { RootState } from "src/state/reducer"
import { AlignType } from 'rc-table/lib/interface'
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { GET_CONSULTATION_TYPES_API } from "src/features/MedicalArea/ConsultationType/state/actionTypes"
import { formatToCurrency } from "src/common/util"
import { CreateConsultationType } from "../CreateConsultationType"
import { Space, Typography } from "antd"
import { SystemDescriptions } from "src/common/descriptions/descriptions"
import { MedicalConsultationOrigin } from "src/common/models/consultationOrigin"

interface ReduxProps {
    consultationTypes?: MedicalConsultationType[]
    consultationOrigins?: MedicalConsultationOrigin[]
    isGettingConsultationTypes: boolean
}

export const ConsultationType = () => {
    const dispatch = useDispatch()

    const descriptions = SystemDescriptions.PAGES.SETTINGS.MEDICAL_AREA.CONSULTATION_TYPE

    const [createNewFlag, setCreateNewFlag] = useState<boolean>(false)
    const [title, setTitle] = useState<string>(descriptions.TITLE)
    const [consultationTypeId, setConsultationTypeId] = useState<number | undefined>()

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        consultationTypes: state.consultationType.consultationTypes,
        isGettingConsultationTypes: serviceLoading(state, [GET_CONSULTATION_TYPES_API]),
        consultationOrigins: state.consultationType.consultationOrigins,
    }))

    useEffect(() => {
        dispatch(getConsultationTypesApi({ active: "1" }))
        dispatch(getConsultationOriginsApi())
    }, [])

    const tableColumns = [
        {
            title: descriptions.TABLE_COLUMNS.NUMBER,
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id,
            align: 'center' as AlignType,
        },
        {
            title: descriptions.TABLE_COLUMNS.CODE,
            dataIndex: 'externalId',
            key: 'externalId',
            sorter: (a, b) => a.clientName - b.clientName,
            align: 'center' as AlignType,
        },
        {
            title: descriptions.TABLE_COLUMNS.DESCRIPTION,
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: descriptions.TABLE_COLUMNS.ORIGIN,
            dataIndex: 'origin',
            key: 'origin',
            align: 'center' as AlignType,
            filterSearch: true,
            onFilter: (value: string, record: MedicalConsultationType) => record.originId === parseInt(value),
            filters: reduxProps.consultationOrigins?.map(origin => ({
                text: origin.name,
                value: origin.id,
            }))
        },
        {
            title: descriptions.TABLE_COLUMNS.PRICE,
            dataIndex: 'price',
            key: 'price',
            align: 'right' as AlignType,
            render: (value) => formatToCurrency(value),
        },
        {
            title: descriptions.TABLE_COLUMNS.STATUS,
            dataIndex: 'isActive',
            key: 'isActive',
            render: (value) => {
                return value === 1
                    ? descriptions.STATUS.ACTIVE
                    : descriptions.STATUS.INACTIVE
            },
            align: 'center' as AlignType,
        },
        {
            title: descriptions.TABLE_COLUMNS.ACTIONS,
            key: 'action',
            align: 'center' as AlignType,
            render: (_, record: MedicalConsultationType) => {
                const handleEditClick = (typeId: number) => {
                    setConsultationTypeId(typeId)
                    setTitle(descriptions.TITLE3)
                    setCreateNewFlag(true)
                }

                return <Space size="middle">
                    <a onClick={() => handleEditClick(record.id!)}>
                        {descriptions.TABLE_COLUMNS.ACTION_EDIT}
                    </a>
                </Space>
            },
        },
    ];

    const handleCreateTypeClick = () => {
        setTitle(descriptions.TITLE2)
        setCreateNewFlag(true)
    }

    const renderContent = () => (
        <SearchableTable
            showSearch
            searchLabel={descriptions.TABLE.SEARCH_PLACEHOLDER}
            items={reduxProps.consultationTypes ?? []}
            tableColumns={tableColumns as any}
            showLoader={reduxProps.isGettingConsultationTypes}
            showActionButton
            actionButtonLabel={descriptions.TABLE.ACTION_BUTTON}
            actionButtonOnClick={handleCreateTypeClick}
        />
    )

    const handleFinishCreate = (isSuccess: boolean = false) => {
        setConsultationTypeId(undefined)
        setCreateNewFlag(false)
        setTitle(descriptions.TITLE)

        if (isSuccess) {
            dispatch(getConsultationTypesApi({ active: "1" }))
        }
    }

    return (
        <>
            <Typography.Title level={5} style={{ marginBottom: 12, marginTop: 0, }}>
                {title}
            </Typography.Title>
            {
                createNewFlag
                    ? <CreateConsultationType
                        consultationTypeId={consultationTypeId}
                        onCancel={() => {
                            handleFinishCreate()
                        }}
                        onAccept={() => {
                            handleFinishCreate(true)
                        }}
                    />
                    : renderContent()
            }
        </>
    )
}
