import { Languages } from "../../types";
import { ShortcutsDescriptions } from "./types";

const DescriptionsEnUs: ShortcutsDescriptions = {
    TITLE: "Shortcuts",
    POS: {
        TITLE: "POS",
        SUBTITLE: "Point of Sale",
    },
    ORDERS: {
        TITLE: "Orders",
        SUBTITLE: "Orders Records",
    },
    ITEMS: {
        TITLE: "Items",
        SUBTITLE: "Items Records",
    },
    REPORTS: {
        TITLE: "Reports",
        SUBTITLE: "Orders Report",
    },
}

const DescriptionsEsUs: ShortcutsDescriptions = {
    TITLE: "Accesos Directos",
    POS: {
        TITLE: "Punto de Venta",
        SUBTITLE: "Punto de Venta",
    },
    ORDERS: {
        TITLE: "Ordenes",
        SUBTITLE: "Registro de Ordenes",
    },
    ITEMS: {
        TITLE: "Productos",
        SUBTITLE: "Listado de Productos",
    },
    REPORTS: {
        TITLE: "Reportes",
        SUBTITLE: "Reporte de Ordenes",
    },
}

export const shortcutsDictionary = {}
shortcutsDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
shortcutsDictionary[Languages.SPANISH_US] = DescriptionsEsUs