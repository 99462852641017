import { SummaryType } from "src/components/SearchableTable/types";

export enum ColumnsKeys {
    ID = 'id',
    PATIENT_ID = "patientId",
    PATIENT = "patient",
    OUTSTANDING = 'outstanding',
    DATETIME = "datetime",
    DESCRIPTION = "description",
    IS_IGSS = "isIGSS",
    ORIGIN = 'origin',
}

export const summaryColumns: Partial<Record<ColumnsKeys, SummaryType>> = {
    [ColumnsKeys.OUTSTANDING]: SummaryType.SUM,
    [ColumnsKeys.ID]: SummaryType.COUNT,
}