import { Languages } from "../../types"
import { SearchableTableDescriptions } from "./types"

const DescriptionsEnUs: SearchableTableDescriptions = {
    RANGE_PICKER: {
        SHORTCUTS: {
            TODAY: 'Today',
            THIS_WEEK: 'This week',
            LAST_WEEK: 'Last week',
            THIS_MONTH: 'This month',
            LAST_MONTH: 'Last month',
        },
    },
    TABLE_LOADER: "Loading data....",
}

const DescriptionsEsUs: SearchableTableDescriptions = {
    RANGE_PICKER: {
        SHORTCUTS: {
            TODAY: 'Hoy',
            THIS_WEEK: 'Esta semana',
            LAST_WEEK: 'Semana pasada',
            THIS_MONTH: 'Este més',
            LAST_MONTH: 'Més anterior',
        },
    },
    TABLE_LOADER: "Obteniendo inforamción...",
}

const searchableTableDictionary = {}
searchableTableDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
searchableTableDictionary[Languages.SPANISH_US] = DescriptionsEsUs

export const getSearchableTableDescriptions = (systemLang: Languages): SearchableTableDescriptions => (
    searchableTableDictionary[systemLang]
)