export interface User {
    id?: number;
    username: string;
    password: string;
    name: string;
    surname: string;
    user_role_id?: number
    is_active?: boolean

    role?: string
    status?: string
    current_password?: string
}

export interface UserRole {
    id: number
    role: string
    is_active: boolean
}

export enum UserRoles {
    ADMIN = 'ADMIN',
    WAITER = 'WAITER',
    KITCHEN = 'KITCHEN',
    CASHBOX = 'CASHBOX',
}