import { Modal, Spin } from "antd"
import * as Styles from './styles'

type Props = {
    isVisible: boolean
    title?: string
    description?: string
}

export const
    Loader = (props: Props) => {
        return (
            <Modal
                open={props.isVisible}
                closable={false}
                footer={null}
                zIndex={1100}
                centered
                getContainer={false}
            >
                <Styles.TitleContainer>
                    <Spin size="large" />
                    <Styles.ContentContainer>
                        <Styles.Title>{props.title || "This may take a while..."}</Styles.Title>
                        <label>{props.description || "This may take a while..."}</label>
                    </Styles.ContentContainer>
                </Styles.TitleContainer>
            </Modal>
        )
    }
