import { call, put, takeLatest, } from 'redux-saga/effects';
import * as Actions from './action';
import * as ActionTypes from './actionTypes';
import { Urls } from 'src/common/urls';
import { getSessionData } from 'src/session/sessionStore';
import { Utilities } from '../types';
import { createAxiosInstance } from 'src/common/api/index';

function* getUtilitiesApi(action: Actions.UtilitiesFiltersAction) {
    try {
        const sessionData = getSessionData();
        const response = yield call(createAxiosInstance(sessionData.accessToken).get, Urls.BACKEND.LOGIN());
        const utilities: Utilities = yield JSON.parse(JSON.stringify(response.data))
        yield put(Actions.getUtilitiesSuccess(utilities));
    } catch (err) {
        yield put(Actions.getUtilitiesError(err));
    }
}

export default function* securitySagas() {
    yield takeLatest(ActionTypes.GET_UTILITIES_API, getUtilitiesApi);
}