import { Languages } from "../../types";
import { CreatePaymentPlanDescriptions } from "./types";

const DescriptionsEnUs: CreatePaymentPlanDescriptions = {
    TITLE: "Create Payment Plan",
    PERSONAL_DATA: {
        TITLE: "Search Patient",
    },
    PAYMENT_PLAN: {
        TITLE: "Payment Plan",
        TYPE: {
            LABEL: "Type",
            PLACEHOLDER: "Payment Plan Type",
        },
        DESCRIPTION: {
            LABEL: "Description",
            PLACEHOLDER: "Payment Plan Description",
        },
        CONSULTATION: {
            LABEL: "Consultation type",
            PLACEHOLDER: "Select consultation type"
        },
        AMOUNT: {
            LABEL: "Amount",
            PLACEHOLDER: "Input Amount",
        },
        UNIT_PRICE: {
            LABEL: "Unit Price",
            PLACEHOLDER: "Unit Price",
        },
        EXPIRATION_DATE: {
            LABEL: "Expiration Date",
            PLACEHOLDER: "Expiration Date",
        },
        OPERATED_BY: {
            LABEL: "Operated By",
            PLACEHOLDER: "Operated By",
        },
        NUMBER_OF_CONSULTATIONS: {
            LABEL: "Number of consultations",
            PLACEHOLDER: "Number of consultations",
        },
        ORIGIN: {
            LABEL: "Origin",
            PLACEHOLDER: "Select origin",
        },
        ERRORS: {
            REQUIRED_FIELD: (fieldName: string) => `${fieldName} is required.`,
            ERROR_SAVING_PAYMENT_PLAN: "There was an error while creating payment plan",
            INVALID_AMOUNT: "Invalid amount",
            UNKNOWN_ERROR: "An error ocurred, please contact support.",
            INVALID_CREDENTIALS: "Invalid credentials for user",
            PAYMENT_PLAN_ALERT: (plansQty: number) => `The patient has ${plansQty} payment pending plan(s).`
        },
        SAVING_TITLE: "Saving Payment Plan",
        SAVING_DESCRIPTION: "Please wait",
        TAB_PAYMENT_PLAN: "PAYMENT PLAN",
        TAB_PATIENT_PROFILE: "PATIENT PROFILE",
        TAB_PAYMENT_PLAN_RECORDS: "PAYMENT PLAN RECORDS",
        PLAN_PRIVATE: "Private",
        PLAN_PUBLIC: "IGSS",
        PLAN_DESCRIPTION: (numberOfConsultations: number, externalId: string, selectedType: string) => `${numberOfConsultations} Appt(s) ${externalId} - ${selectedType}`,
    },
    SAVE_BUTTON: "Save",
    CANCEL_BUTTON: "Cancel",
    CONFIRM_MODAL: {
        TITLE: 'Save payment plan?',
        DESCRIPTION: "Operation will not be reverted.",
        ACTION_BUTTON: "Save",
        CANCEL_BUTTON: "Go Back",
    },
    CANCEL_MODAL: {
        TITLE: "Cancel payment plan input?",
        DESCRIPTION: "All data will be lost.",
        ACTION_BUTTON: "Yes, Cancel",
        CANCEL_BUTTON: "Keep working",
    },
    SUCCESS_MODAL: {
        TITLE: "Success",
        PRIMARY_LABEL: "Payment Plan created",
        SECONDARY_LABEL: "Accept",
        DESCRIPTION: "Accept",
    },
}

const DescriptionsEsUs: CreatePaymentPlanDescriptions = {
    TITLE: "Crear Plan de Pago",
    PERSONAL_DATA: {
        TITLE: "Seleccione paciente",
    },
    PAYMENT_PLAN: {
        TITLE: "Plan de Pago",
        TYPE: {
            LABEL: "Tipo",
            PLACEHOLDER: "Tipo de Plan de Pago",
        },
        DESCRIPTION: {
            LABEL: "Descripción",
            PLACEHOLDER: "Descripción de plan de pago",
        },
        CONSULTATION: {
            LABEL: "Tipo de consulta",
            PLACEHOLDER: "Seleccione tipo"
        },
        AMOUNT: {
            LABEL: "Monto",
            PLACEHOLDER: "Ingrese monto",
        },
        UNIT_PRICE: {
            LABEL: "Precio consulta",
            PLACEHOLDER: "Precio consulta",
        },
        EXPIRATION_DATE: {
            LABEL: "Vencimiento",
            PLACEHOLDER: "Vencimiento",
        },
        OPERATED_BY: {
            LABEL: "Operado por",
            PLACEHOLDER: "Operado por",
        },
        NUMBER_OF_CONSULTATIONS: {
            LABEL: "Número de consultas",
            PLACEHOLDER: "Número de consultas",
        },
        ORIGIN: {
            LABEL: "Orígen",
            PLACEHOLDER: "Seleccione orígen",
        },
        ERRORS: {
            REQUIRED_FIELD: (fieldName: string) => `${fieldName} es requerido.`,
            ERROR_SAVING_PAYMENT_PLAN: "Ha ocurrido un error al crear plan de pago",
            INVALID_AMOUNT: "Monto invalido",
            UNKNOWN_ERROR: "Ha ocurrido un error, por favor contacta a soporte.",
            INVALID_CREDENTIALS: "Credenciales de usuario inválidas",
            PAYMENT_PLAN_ALERT: (plansQty: number) => `El paciente tiene ${plansQty} plan(es) pendiente(s) de pago.`
        },
        SAVING_TITLE: "Guardando Plan de Pago",
        SAVING_DESCRIPTION: "Espera un momento",
        TAB_PAYMENT_PLAN: "PLAN DE PAGO",
        TAB_PATIENT_PROFILE: "FICHA PACIENTE",
        TAB_PAYMENT_PLAN_RECORDS: "HISTORIAL DE PLANES",
        PLAN_PRIVATE: "Privado",
        PLAN_PUBLIC: "IGSS",
        PLAN_DESCRIPTION: (numberOfConsultations: number, externalId: string, selectedType: string) => `${numberOfConsultations} Consulta(s) ${externalId} - ${selectedType}`,
    },
    SAVE_BUTTON: "Guardar",
    CANCEL_BUTTON: "Cancelar",
    CONFIRM_MODAL: {
        TITLE: 'Guardar Plan de Pago?',
        DESCRIPTION: "La operación no podrá ser revertida.",
        ACTION_BUTTON: "Guardar",
        CANCEL_BUTTON: "Regresar",
    },
    CANCEL_MODAL: {
        TITLE: "Cancel ingreso de Plan de Pago?",
        DESCRIPTION: "Los datos ingresados se perderán.",
        ACTION_BUTTON: "Si, cancelar",
        CANCEL_BUTTON: "Seguir ingresando",
    },
    SUCCESS_MODAL: {
        TITLE: "Exito",
        PRIMARY_LABEL: "Plan de Pago creado",
        SECONDARY_LABEL: "Aceptar",
        DESCRIPTION: "Aceptar",
    },
}

export const createPaymentPlanDictionary = {}
createPaymentPlanDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
createPaymentPlanDictionary[Languages.SPANISH_US] = DescriptionsEsUs