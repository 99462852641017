import { useDispatch, useSelector } from "react-redux"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { PaymentPlanReceivable } from "src/common/models/paymentPlan"
import { ContentContainer, PageCard } from "src/common/styles/styles"
import { SearchableTable } from "src/components/SearchableTable"
import { RootState } from "src/state/reducer"
import { GET_PAYMENT_PLAN_RECEIVABLES_API } from "../state/actionTypes"
import { useEffect } from "react"
import { getPaymentPlanReceivablesApi } from "../state/action"
import { SystemDescriptions } from "src/common/descriptions/descriptions"
import { ColumnsKeys, summaryColumns } from "./config"
import { MedicalConsultationOrigin } from "src/common/models/consultationOrigin"
import { AlignType } from 'rc-table/lib/interface'
import { SystemConstants } from "src/common/constants"
import dayjs from 'dayjs'
import { formatToCurrency } from "src/common/parser";
import { getConsultationOriginsApi } from "../ConsultationType/state/action"

interface ReduxProps {
    isLoadingData: boolean
    paymentPlanReceivables: PaymentPlanReceivable[]
    consultationOrigins?: MedicalConsultationOrigin[]
}

export const PaymentPlanReceivables = () => {
    const dispatch = useDispatch()

    const descriptions = SystemDescriptions.PAGES.MEDICAL_AREA.PAYMENT_PLAN_RECEIVABLES

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        isLoadingData: serviceLoading(state, [GET_PAYMENT_PLAN_RECEIVABLES_API]),
        paymentPlanReceivables: state.medicalArea.paymentPlanReceivables,
        consultationOrigins: state.consultationType.consultationOrigins,
    }))

    useEffect(() => {
        dispatch(getPaymentPlanReceivablesApi())
        if (!reduxProps.consultationOrigins || reduxProps.consultationOrigins.length === 0) {
            dispatch(getConsultationOriginsApi())
        }
    }, [])

    const tableColumns: any = [
        {
            title: descriptions.TABLE_COLUMNS.PLAN_ID,
            dataIndex: ColumnsKeys.ID,
            key: ColumnsKeys.ID,
            align: 'center' as AlignType,
        },
        {
            title: descriptions.TABLE_COLUMNS.PATIENT_ID,
            dataIndex: ColumnsKeys.PATIENT_ID,
            key: ColumnsKeys.PATIENT_ID,
            align: 'center' as AlignType,
        },
        {
            title: descriptions.TABLE_COLUMNS.PATIENT,
            dataIndex: ColumnsKeys.PATIENT,
            key: ColumnsKeys.PATIENT,
        },
        {
            title: descriptions.TABLE_COLUMNS.DATETIME,
            dataIndex: ColumnsKeys.DATETIME,
            key: ColumnsKeys.DATETIME,
            render: (value) => {
                return dayjs(value).format(SystemConstants.DATETIME_FORMAT_DISPLAY)
            },
        },
        {
            title: descriptions.TABLE_COLUMNS.DESCRIPTION,
            dataIndex: ColumnsKeys.DESCRIPTION,
            key: ColumnsKeys.DESCRIPTION,
        },
        {
            title: descriptions.TABLE_COLUMNS.OUTSTANDING,
            dataIndex: ColumnsKeys.OUTSTANDING,
            key: ColumnsKeys.OUTSTANDING,
            render: (value) => {
                return formatToCurrency(value)
            },
            align: 'right' as AlignType,
        },
        {
            title: descriptions.TABLE_COLUMNS.IGSS,
            dataIndex: ColumnsKeys.ORIGIN,
            key: ColumnsKeys.ORIGIN,
            align: 'center' as AlignType,
            filterSearch: true,
            onFilter: (value: string, record: PaymentPlanReceivable) => {
                console.log("ajaja, ", value, record)
                return record.originId === parseInt(value)
            },
            filters: reduxProps.consultationOrigins?.map(origin => ({
                text: origin.name,
                value: origin.id,
            }))
        },
    ];

    const renderContent = () => (
        < PageCard size="small" title={descriptions.TITLE} >
            <SearchableTable
                showSearch
                searchLabel={descriptions.TABLE.SEARCH_PLACEHOLDER}
                items={reduxProps.paymentPlanReceivables}
                tableColumns={tableColumns}
                showLoader={reduxProps.isLoadingData}
                summaryColumns={summaryColumns}
            />
        </PageCard >
    )

    return (
        <ContentContainer>
            {renderContent()}
        </ContentContainer>
    )
}
