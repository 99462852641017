import { call, put, takeLatest, } from 'redux-saga/effects';
import * as ActionTypes from './actionTypes';
import * as Actions from './action';
import { Urls } from 'src/common/urls';
import { NetworkingProvider } from 'src/features/Security/networking/networkingProvider';
import { GetMedicalConsultationTypeFilters } from 'src/common/models/consultationType';

const buildConsulationTypesQuery = (filters?: GetMedicalConsultationTypeFilters): string => {
    const params: string[] = []
    if (!filters) {
        return ''
    }

    if (filters.active) {
        params.push(`active=${filters.active}`)
    }

    return params.length > 0
        ? `?${params.join('&')}`
        : ``
}

function* getConsultationTypesApi(action: Actions.GetConsultationTypesAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getAppInterface().get, Urls.BACKEND.MEDICAL_CONSULTATION.GET(buildConsulationTypesQuery(action.filters)))
        yield put(Actions.getConsultationTypesSuccess(response.data))
    } catch (err) {
        yield put(Actions.getConsultationTypesError(err));
    }
}

function* createConsultationType(action: Actions.CreateConsultationTypeAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getAppInterface().post, Urls.BACKEND.MEDICAL_CONSULTATION.CREATE, action.consultationType)
        yield put(Actions.createConsultationTypeSuccess(response.data))
    } catch (err: any) {
        yield put(Actions.createConsultationTypeError(err?.response?.data?.error ?? err));
    }
}

function* getConsultationTypeByIdApi(action: Actions.GetConsultationTypeByIdAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getAppInterface().get, Urls.BACKEND.MEDICAL_CONSULTATION.GET_BY_ID(action.id))
        yield put(Actions.getConsultationTypeByIdSuccess(response.data))
    } catch (err) {
        yield put(Actions.getConsultationTypeByIdError(err));
    }
}

function* updateConsultationType(action: Actions.UpdateConsultationTypeAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getAppInterface().put, Urls.BACKEND.MEDICAL_CONSULTATION.UPDATE, action.consultationType)
        yield put(Actions.updateConsultationTypeSuccess(response.data))
    } catch (err: any) {
        yield put(Actions.updateConsultationTypeError(err?.response?.data?.error ?? err));
    }
}

function* getConsultationOriginsApi() {
    try {
        const response = yield call(NetworkingProvider.getInstance().getAppInterface().get, Urls.BACKEND.MEDICAL_CONSULTATION.GET_ORIGINS)
        yield put(Actions.getConsultationOriginsSuccess(response.data))
    } catch (err) {
        yield put(Actions.getConsultationOriginsError(err));
    }
}

export default function* consultationTypeSagas() {
    yield takeLatest(ActionTypes.GET_CONSULTATION_TYPES_API, getConsultationTypesApi)
    yield takeLatest(ActionTypes.CREATE_CONSULTATION_TYPE_API, createConsultationType)
    yield takeLatest(ActionTypes.GET_CONSULTATION_TYPE_BY_ID_API, getConsultationTypeByIdApi)
    yield takeLatest(ActionTypes.UPDATE_CONSULTATION_TYPE_API, updateConsultationType)
    yield takeLatest(ActionTypes.GET_CONSULTATION_ORIGINS_API, getConsultationOriginsApi)
}