import { Languages } from "../types";
import { CommonPagesDescriptions } from "./types";

const DescriptionsEnUs: CommonPagesDescriptions = {
    ACTIVE_STATUS: "Active",
    INACTIVE_STATUS: "Inactive",
    YES: "Yes",
    NO: "No",
    SEARCH: "Search",
    ACTIONS: {
        EDIT: "Edit",
        VIEW: "View",
    },
}

const DescriptionsEsUs: CommonPagesDescriptions = {
    ACTIVE_STATUS: "Activo",
    INACTIVE_STATUS: "Inactivo",
    YES: "Si",
    NO: "No",
    SEARCH: "Buscar",
    ACTIONS: {
        EDIT: "Editar",
        VIEW: "Ver",
    },
}

const commonPageDictionary = {}
commonPageDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
commonPageDictionary[Languages.SPANISH_US] = DescriptionsEsUs

export const getCommonPagesDescriptions = (systemLang: Languages): CommonPagesDescriptions => (
    commonPageDictionary[systemLang]
)