import { Action } from 'redux';
import { Utilities } from '../types';
import { UtilitiesAction } from './action';
import * as Actions from './actionTypes';

export interface CommonState {
    utilities?: Utilities;
}

const defaultState: CommonState = {
    utilities: {},
};

export default (state: CommonState = defaultState, action: Action): CommonState => {
    switch (action.type) {
        case Actions.GET_UTILITIES_SUCCESS: {
            const utilitiesAction = action as UtilitiesAction;
            return {
                ...state,
                utilities: {
                    ...state.utilities,
                    ...utilitiesAction.utilities
                },
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
};