import { Avatar, Divider, List, Row, Tabs, TabsProps } from "antd";
import { PageCard } from "src/common/styles/styles"
import { PersonalData } from "./PersonalData";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getPatientByIdApi } from "../../state/action";
import { RootState } from "src/state/reducer";
import { Patient } from "src/common/models/patient";
import { PaymentPlanRecords } from "./PaymentPlanRecords";
import { SystemDescriptions } from "src/common/descriptions/descriptions";
import { AccountBalance } from "./AccountBalance";
import { Col20, Col4 } from "src/components/Columns";
import { UserOutlined } from '@ant-design/icons';
import { HeaderContainer, SummaryContainer } from "./styles";
import { formatToCurrency } from "src/common/parser";
import { ConsultationRecords } from "./ConsultationRecords";
import { useParams } from "react-router-dom";

interface ReduxProps {
    patient?: Patient
}

export const PatientProfile = () => {

    const dispatch = useDispatch()
    const { id: patientId } = useParams<{ id: string }>();

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        patient: state.medicalArea.currentPatient,
    }))

    const descriptions = SystemDescriptions.PAGES.MEDICAL_AREA.PATIENT_PROFILE

    useEffect(() => {
        if (!patientId) {
            return
        }

        dispatch(getPatientByIdApi(Number(patientId), {
            populated: "1",
            summary: "1",
        }))
    }, [patientId])

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: descriptions.PERSONAL_DATA.TITLE,
            children: <>
                {
                    reduxProps.patient &&
                    <PersonalData
                        patient={reduxProps.patient}
                    />
                }
            </>,
        },
        {
            key: '2',
            label: descriptions.PAYMENT_PLAN_RECORDS.TITLE,
            children: <>
                {
                    reduxProps.patient &&
                    <PaymentPlanRecords
                        patient={reduxProps.patient}
                    />
                }
            </>,
        },
        {
            key: '3',
            label: descriptions.ACCOUNT_STATEMENT.TITLE,
            children: <>
                {
                    reduxProps.patient &&
                    <AccountBalance
                        patient={reduxProps.patient!}
                    />
                }
            </>,
        },
        {
            key: '4',
            label: descriptions.CONSULTATION_RECORDS.TITLE,
            children: <ConsultationRecords
                patient={reduxProps.patient!}
            />
        },
    ];

    const summaryData = [
        {
            label: descriptions.SUMMARY.CONSULTATIONS,
            value: reduxProps.patient?.countConsultation ?? 0,
        },
        {
            label: descriptions.SUMMARY.PAYMENT_PLANS,
            value: reduxProps.patient?.paymentPlanDetail?.length ?? 0,
        },
        {
            label: descriptions.SUMMARY.ACTIVE_PLANS,
            value: reduxProps.patient?.paymentPlanDetail?.filter(plan => !plan.payed).length,
        },
        {
            label: descriptions.SUMMARY.PAYMENTS,
            value: reduxProps.patient?.countPay ?? 0,
        },
        {
            label: descriptions.SUMMARY.BALANCE,
            value: formatToCurrency(reduxProps.patient?.outstanding?.toString() ?? "0"),
        },
    ];

    const renderSummary = () => (
        <Col4>
            <PageCard size="small" title={""}>
                <SummaryContainer>
                    <HeaderContainer>
                        <Avatar size={64} icon={<UserOutlined />} />
                        <label
                            style={{ fontWeight: 'bold' }}
                        >
                            {`${reduxProps.patient?.name} ${reduxProps.patient?.surname}`}
                        </label>
                        <label >{reduxProps.patient?.email}</label>
                    </HeaderContainer>

                    <Divider />

                    <HeaderContainer>
                        <label style={{ fontWeight: "bold" }} >Datos totales</label>
                    </HeaderContainer>

                    <List
                        itemLayout="horizontal"
                        dataSource={summaryData}
                        renderItem={(item) => (
                            <List.Item>
                                <List.Item.Meta
                                    title={item.label}
                                />
                                <div>{item.value}</div>
                            </List.Item>
                        )}
                    />
                </SummaryContainer>
            </PageCard>
        </Col4>
    )

    return (
        <Row gutter={[8, 0]} >
            {renderSummary()}
            <Col20>
                <PageCard size="small" title={descriptions.TITLE}>
                    <Tabs defaultActiveKey="1" items={items} />
                </PageCard >
            </Col20>
        </Row>
    )
}
