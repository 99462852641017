import { Alert, DatePicker, Form, Input, Row, Select } from "antd";
import { Col20, Col24, Col4 } from "src/components/Columns";
import { FieldNumberOutlined } from '@ant-design/icons';
import { ButtonsContainer } from "src/common/styles/styles";
import { ActionButton } from "src/components/ActionButton";
import { ActionButtonType } from "src/components/ActionButton/types";
import { SystemDescriptions } from "src/common/descriptions/descriptions";
import { CurrencyInput } from "src/components/CurrencyInput";
import { OperatedBySelect } from "src/components/OperatedBySelect";
import { useEffect, useState } from "react";
import { UserRequest } from "src/common/models/employee";
import { cleanTextToCurrency, filterOption, formatToCurrency } from "src/common/util";
import { useDispatch, useSelector } from "react-redux";
import { getConsultationOriginsApi, getConsultationTypesApi } from "../../ConsultationType/state/action";
import { MedicalConsultationType } from "src/common/models/consultationType";
import { ConsultationTypeError } from "../../ConsultationType/state/types";
import { RootState } from "src/state/reducer";
import * as Styles from './styles';
import { SystemConstants } from "src/common/constants";
import { PaymentPlanData } from "src/common/models/paymentPlan";
import moment from "moment";
import InnerLoader from "src/components/innerLoader";
import { serviceLoading } from "src/common/apiLoader/state/selection";
import { GET_CONSULTATION_TYPES_API } from "../../ConsultationType/state/actionTypes";
import dayjs from 'dayjs'
import { MedicalAreaError, MedicalAreaErrorOrigin } from "../../state/types";
import { ApiError } from "src/features/Security/networking/types";
import { Patient } from "src/common/models/patient";
import { MedicalConsultationOrigin } from "src/common/models/consultationOrigin";
import { ORIGINS_EDITABLE_LIST } from "./types";

interface Props {
    onFinish: (formValues, userRequest: UserRequest) => void
    onCancel: () => void
}

interface ReduxProps {
    consultationTypes?: MedicalConsultationType[]
    consultationOrigins?: MedicalConsultationOrigin[]
    consultationTypeError?: ConsultationTypeError
    isGettingConsultationTypes: boolean
    medicalAreaError?: MedicalAreaError
    currentPatient?: Patient
}

export const CreatePaymentPlanForm = (props: Props) => {
    const dispatch = useDispatch()

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        consultationTypes: state.consultationType.consultationTypes,
        consultationOrigins: state.consultationType.consultationOrigins,
        consultationTypeError: state.consultationType.error,
        isGettingConsultationTypes: serviceLoading(state, [GET_CONSULTATION_TYPES_API]),
        medicalAreaError: state.medicalArea.error,
        currentPatient: state.medicalArea.currentPatient,
    }))

    useEffect(() => {
        dispatch(getConsultationTypesApi({ active: "1" }))
        if (!reduxProps.consultationOrigins || reduxProps.consultationOrigins.length === 0) {
            dispatch(getConsultationOriginsApi())
        }
    }, [])

    const [userRequest, setUserRequest] = useState<UserRequest | undefined>(undefined)
    const [selectecConsultationType, setSelectecConsultationType] = useState<number | undefined>()
    const [selectedOrigin, setSelectedOrigin] = useState<MedicalConsultationOrigin | undefined>()

    const descriptions = SystemDescriptions.PAGES.MEDICAL_AREA.CREATE_PAYMENT_PLAN

    const [form] = Form.useForm();

    const renderButtons = () => (
        <Form.Item>
            <ButtonsContainer>
                <ActionButton
                    label={descriptions.CANCEL_BUTTON}
                    onClick={props.onCancel}
                    actionButtonType={ActionButtonType.DESTRUCTIVE}
                />
                <ActionButton
                    label={descriptions.SAVE_BUTTON}
                    htmlType='submit'
                />
            </ButtonsContainer>
        </Form.Item>
    )

    const buildRequiredMessageErr = (fieldName: string): string => descriptions.PAYMENT_PLAN.ERRORS.REQUIRED_FIELD(fieldName)

    const handleOperatedByChange = (user: UserRequest | undefined) => {
        form.setFieldsValue({
            createdBy: user?.username ?? undefined
        })

        setUserRequest(user)
    }

    const onFinish = () => {
        const formValues = form.getFieldsValue()

        if (userRequest) {
            const data: PaymentPlanData = {
                patientId: 0,
                description: formValues.description,
                amount: formValues.amount,
                expire: moment(formValues).format("YYYY-MM-DD"),
                isIGSS: false,
                consultationTypeId: formValues?.consultationType ?? 0,
            }

            props.onFinish(data, userRequest)
        }
    }

    const handleAmountChange = (e: any): void => {
        form.setFieldsValue({ amount: cleanTextToCurrency(e.target.value) });
    }

    const handlePaymentPlanTypeChange = (value) => {
        const foundOrigin = reduxProps.consultationOrigins?.find(candidate => candidate.id === parseInt(value))
        setSelectedOrigin(foundOrigin)

        form.setFieldsValue({
            description: undefined,
            consultationType: undefined,
            numberOfConsultations: 1,
            unitPrice: undefined,
        })
        setSelectecConsultationType(undefined)
    }

    const updateDescription = () => {
        const { numberOfConsultations, consultationType } = form.getFieldsValue()

        if (numberOfConsultations && consultationType && numberOfConsultations > 0) {
            const consultationFound = reduxProps.consultationTypes?.find(candidate => candidate.id === parseInt(consultationType))

            if (!consultationFound) {
                return
            }

            const newDescription: string = descriptions.PAYMENT_PLAN.PLAN_DESCRIPTION(numberOfConsultations, consultationFound?.externalId ?? "", selectedOrigin?.name!)

            const amount: number = numberOfConsultations * consultationFound?.price

            form.setFieldsValue({
                description: newDescription,
                amount,
                unitPrice: consultationFound?.price,
            })
        } else {
            form.setFieldsValue({
                description: undefined,
                amount: undefined,
                unitPrice: 0,
            })
        }
    }

    const handleConsultationTypeChange = (value) => {
        setSelectecConsultationType(value)
        updateDescription()
    }

    const handleNumberOfConsultationsChange = () => updateDescription()

    const getCreatePaymentErrorMessage = (error: ApiError): string => {
        let message: string = descriptions.PAYMENT_PLAN.ERRORS.UNKNOWN_ERROR

        if (error?.code === 404) {
            message = descriptions.PAYMENT_PLAN.ERRORS.INVALID_CREDENTIALS
        }

        return message
    }

    const getAlertDescription = (): string => {
        let plansPending: number = 0

        reduxProps?.currentPatient?.paymentPlanDetail?.forEach(plan => {
            if (!plan.payed) {
                plansPending++
            }
        })

        return descriptions.PAYMENT_PLAN.ERRORS.PAYMENT_PLAN_ALERT(plansPending)
    }

    const handleUnitPriceChange = (e) => {
        const newPrice: number = e.target.value.trim().length === 0 ? 0 : parseFloat(e?.target?.value)

        const { numberOfConsultations, consultationType } = form.getFieldsValue()

        if (numberOfConsultations && consultationType && numberOfConsultations > 0) {

            const amount: number = numberOfConsultations * newPrice

            form.setFieldsValue({
                amount,
            })
        }
    }

    return (
        <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
        >

            <Row gutter={[16, 8]}>
                {
                    reduxProps.currentPatient?.paymentPlanDetail
                    && reduxProps?.currentPatient?.paymentPlanDetail?.length > 0
                    && reduxProps.currentPatient.paymentPlanDetail.some(candidate => !candidate.payed) &&
                    <Col24>
                        <Alert
                            message={getAlertDescription()}
                            type="info"
                            showIcon
                        />
                    </Col24>
                }
                <Col4>
                    <Form.Item
                        label={descriptions.PAYMENT_PLAN.TYPE.LABEL}
                        name={"type"}
                        rules={[{ required: true, message: buildRequiredMessageErr(descriptions.PAYMENT_PLAN.TYPE.LABEL) }]}
                        validateTrigger="onBlur"
                    >
                        <Select
                            onChange={handlePaymentPlanTypeChange}
                            options={reduxProps.consultationOrigins?.map(origin => ({
                                label: origin.name,
                                value: origin.id,
                            }))}
                            placeholder={descriptions.PAYMENT_PLAN.TYPE.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col4>
                <Col20>
                    <Form.Item
                        label={descriptions.PAYMENT_PLAN.CONSULTATION.LABEL}
                        name={"consultationType"}
                        rules={[{ required: true, message: buildRequiredMessageErr(descriptions.PAYMENT_PLAN.CONSULTATION.LABEL) }]}
                        validateTrigger="onBlur"
                    >
                        {
                            reduxProps.isGettingConsultationTypes
                                ? <InnerLoader />
                                : <Select
                                    options={reduxProps.consultationTypes?.filter(type => type.originId === selectedOrigin?.id)
                                        .map(type => ({
                                            value: type.id?.toString() ?? "0",
                                            label: `${type.externalId} - ${type.description} - ${formatToCurrency(type.price, "Q")}`,
                                        }))}
                                    placeholder={descriptions.PAYMENT_PLAN.TYPE.PLACEHOLDER}
                                    showSearch
                                    filterOption={filterOption}
                                    onChange={handleConsultationTypeChange}
                                />
                        }
                    </Form.Item>
                </Col20>
                <Col4>
                    <Form.Item
                        label={descriptions.PAYMENT_PLAN.NUMBER_OF_CONSULTATIONS.LABEL}
                        name={"numberOfConsultations"}
                        rules={[{ required: true, message: buildRequiredMessageErr(descriptions.PAYMENT_PLAN.NUMBER_OF_CONSULTATIONS.LABEL) }]}
                        validateTrigger="onBlur"
                    >
                        <Styles.NumberInput
                            type="number"
                            prefix={<FieldNumberOutlined />}
                            placeholder={descriptions.PAYMENT_PLAN.NUMBER_OF_CONSULTATIONS.PLACEHOLDER}
                            onChange={handleNumberOfConsultationsChange}
                            style={{ textAlign: 'center' }}
                            min={1}
                        />
                    </Form.Item>
                </Col4>
                <Col20>
                    <Form.Item
                        label={descriptions.PAYMENT_PLAN.DESCRIPTION.LABEL}
                        name={"description"}
                        rules={[{ required: true, message: buildRequiredMessageErr(descriptions.PAYMENT_PLAN.DESCRIPTION.LABEL) }]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={descriptions.PAYMENT_PLAN.DESCRIPTION.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col20>
                <Col4>
                    <Form.Item
                        label={descriptions.PAYMENT_PLAN.UNIT_PRICE.LABEL}
                        rules={[
                            { required: true, message: buildRequiredMessageErr(descriptions.PAYMENT_PLAN.ORIGIN.LABEL) },
                        ]}
                        name={"unitPrice"}
                        validateTrigger="onBlur"
                    >
                        <CurrencyInput
                            readOnly={selectecConsultationType === undefined || !ORIGINS_EDITABLE_LIST.includes(selectedOrigin?.id!)}
                            onChange={handleUnitPriceChange}
                        />
                    </Form.Item>
                </Col4>
                <Col4>
                    <Form.Item
                        label={descriptions.PAYMENT_PLAN.AMOUNT.LABEL}
                        rules={[
                            { required: true, message: buildRequiredMessageErr(descriptions.PAYMENT_PLAN.AMOUNT.LABEL) },
                            {
                                message: descriptions.PAYMENT_PLAN.ERRORS.INVALID_AMOUNT,
                                validator: (_, value) => {
                                    const currencyRegex = /^\d+(\.\d{1,2})?$/;

                                    if (currencyRegex.test(value)) {
                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject();
                                    }
                                }
                            }
                        ]}
                        name={"amount"}
                        validateTrigger="onBlur"
                    >
                        <CurrencyInput
                            onChange={handleAmountChange}
                        />
                    </Form.Item>
                </Col4>
                <Col4>
                    <Form.Item
                        label={descriptions.PAYMENT_PLAN.EXPIRATION_DATE.LABEL}
                        name={"expirationDate"}
                        rules={[{ required: true, message: buildRequiredMessageErr(descriptions.PAYMENT_PLAN.AMOUNT.LABEL) }]}
                        validateTrigger="onBlur"
                    >
                        <DatePicker
                            style={{ width: '100%' }}
                            format={SystemConstants.DATE_FORMAT}
                            disabledDate={(current) => {
                                let customDate = dayjs()
                                return current && current <= customDate
                            }}
                        />
                    </Form.Item>
                </Col4>
                <Col4>
                    <Form.Item
                        label={descriptions.PAYMENT_PLAN.OPERATED_BY.LABEL}
                        name="createdBy"
                        rules={[{ required: true, message: buildRequiredMessageErr(descriptions.PAYMENT_PLAN.OPERATED_BY.LABEL) }]}
                        validateTrigger="onBlur"
                    >
                        <OperatedBySelect
                            onChange={handleOperatedByChange}
                        />
                    </Form.Item>
                </Col4>
            </Row>
            {
                reduxProps.medicalAreaError && reduxProps.medicalAreaError.type === MedicalAreaErrorOrigin.CREATE_PAYMENT_PLAN &&
                <Alert type="error" message={getCreatePaymentErrorMessage(reduxProps.medicalAreaError?.detail!)} banner />
            }
            {renderButtons()}
        </Form>
    )
}
