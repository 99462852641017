import { Languages } from "../../types";
import { OperatedBySelectDescriptions } from "./types";

const DescriptionsEnUs: OperatedBySelectDescriptions = {
    PLACEHOLDER: "Select user",
    CREDENTIALS_MODAL: {
        TITLE: (username: string) => `Operated by: ${username}`,
        PASSWORD: {
            LABEL: "Password",
            PLACEHOLDER: "Input password",
        },
        ACTION_BUTTON: "Accept",
    },
    CREDENTIALS_RECEIVED: "Credentials received",
}

const DescriptionsEsUs: OperatedBySelectDescriptions = {
    PLACEHOLDER: "Seleccione usuario",
    CREDENTIALS_MODAL: {
        TITLE: (username: string) => `Operado por: ${username}`,
        PASSWORD: {
            LABEL: "Contraseña",
            PLACEHOLDER: "Ingrese contraseña",
        },
        ACTION_BUTTON: "Aceptar",
    },
    CREDENTIALS_RECEIVED: "Credenciales recibidas",
}

const operatedBySelectDictionary = {}
operatedBySelectDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
operatedBySelectDictionary[Languages.SPANISH_US] = DescriptionsEsUs

export const getOperatedBySelectDescriptions = (systemLang: Languages): OperatedBySelectDescriptions => (
    operatedBySelectDictionary[systemLang]
)