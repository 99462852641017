import { SearchableTable } from "src/components/SearchableTable"
import { summaryColumns, tableColumns } from "./config"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { getPatientAccountStmtApi } from "src/features/MedicalArea/state/action"
import { RootState } from "src/state/reducer"
import { PatientAccountStatement } from "src/common/models/patientAccountStatement"
import { SystemDescriptions } from "src/common/descriptions/descriptions"
import { Select } from "antd"
import { filterOption } from "src/common/util"
import { Patient } from "src/common/models/patient"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { GET_PATIENT_ACCOUNT_STATEMENT_API } from "src/features/MedicalArea/state/actionTypes"

interface Props {
    patient: Patient
}

interface ReduxProps {
    patientAccStmt?: PatientAccountStatement[]
    isLoadingData: boolean
}

interface PaymentPlanCollection {
    id: string
    description: string
}

export const AccountBalance = (props: Props) => {

    const dispatch = useDispatch()

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        patientAccStmt: state.medicalArea.patientAccountStmt,
        isLoadingData: serviceLoading(state, [GET_PATIENT_ACCOUNT_STATEMENT_API]),
    }))

    const descriptions = SystemDescriptions.PAGES.MEDICAL_AREA.PATIENT_PROFILE.ACCOUNT_STATEMENT

    const [paymentPlans, setPaymentPlans] = useState<PaymentPlanCollection[]>([])
    const [selectedPlan, setSelectedPlan] = useState<PaymentPlanCollection | undefined>()

    useEffect(() => {
        dispatch(getPatientAccountStmtApi(props.patient.id_patient!))

        const plansList: PaymentPlanCollection[] = props.patient.paymentPlanDetail!.map(paymentPlan => ({
            id: `${paymentPlan.id}`,
            description: `${paymentPlan.id} - ${paymentPlan.description}`,
        }))

        setPaymentPlans(plansList)
    }, [])

    const commonDescriptions = SystemDescriptions.PAGES.COMMON

    const handlePaymentPlanChange = (value) => {
        const foundPlan = paymentPlans.find(candidate => candidate.id === value)
        setSelectedPlan(foundPlan)
    }

    const renderPaymentPlans = () => (
        <Select
            style={{ width: '100%' }}
            showSearch
            placeholder={descriptions.SELECT}
            optionFilterProp="children"
            onChange={handlePaymentPlanChange}
            filterOption={filterOption}
            options={[
                {
                    label: descriptions.ALL,
                    value: "0",
                },
                ...paymentPlans.map(paymentPlan => ({
                    label: paymentPlan.description,
                    value: paymentPlan.id,
                }))
            ]}
        />
    )

    const handleGetAccStmt = () => {
        dispatch(getPatientAccountStmtApi(props.patient.id_patient!, {
            paymentPlanId: selectedPlan ? selectedPlan?.id : undefined,
        }))
    }

    const renderContent = () => (
        <SearchableTable
            items={reduxProps.patientAccStmt ?? []}
            tableColumns={tableColumns}
            summaryColumns={summaryColumns}
            showSearch
            searchLabel={commonDescriptions.SEARCH}
            showActionButton
            actionButtonLabel={descriptions.GENERATE}
            actionButtonOnClick={handleGetAccStmt}
            showLoader={reduxProps.isLoadingData}
        >
            {renderPaymentPlans()}
        </SearchableTable>
    )

    return (
        renderContent()
    )
}
