import { PaymentPlan } from "src/common/models/paymentPlan";
import { Languages } from "../../types";
import { MedicalPaymentDescriptions } from "./types";
import dayjs from 'dayjs'
import { SystemConstants } from "src/common/constants";
import { formatToCurrency } from "src/common/parser";

const DescriptionsEnUs: MedicalPaymentDescriptions = {
    TITLE: "Create Medical Payment",
    PERSONAL_DATA: {
        TITLE: "Personal Data",
        CUSTOMER: {
            LABEL: "Patient",
            PLACEHOLDER: "Select patient",
        },
        EMAIL: {
            LABEL: "Email",
            PLACEHOLDER: "Email",
        },
        PHONE: {
            LABEL: "Phone",
            PLACEHOLDER: "Phone",
        },
        BALANCE: {
            LABEL: "Balance",
            PLACEHOLDER: "Balance",
        },
        GETTING_PATIENT_DATA: "Getting patient data.",
        ERROR_GETTING_PATIENTS: "An error ocurred while getting patients.",
        PLANS_VISIBILITY: (numberOfPlans: number, hide?: boolean) => {
            if (numberOfPlans === 0) {
                return `No Plans`
            }
            return hide ? `Show ${numberOfPlans} Plan(s)` : `Hide ${numberOfPlans} Plan(s)`
        },
        PLAN_DATA_COMPOSSER: (paymentPlan: PaymentPlan) => {
            const datetime: string = dayjs(paymentPlan.datetime).format(SystemConstants.DATETIME_FORMAT_DISPLAY)
            const amount: string = formatToCurrency(paymentPlan.amount.toString())
            const amountPayed: string = formatToCurrency(paymentPlan.amountPayed.toString())
            const outstanding: string = formatToCurrency(
                (parseFloat(paymentPlan.amount.toString()) - parseFloat(paymentPlan.amountPayed.toString())).toString()
            )

            return `Created at: ${datetime}. Total: ${amount}. Payed: ${amountPayed}. Outstanding: ${outstanding}`
        },
    },
    PAYMENT: {
        TITLE: "Payment description",
        SUBTITLE: "Payment",
        PAYMENT_PLAN: {
            LABEL: "Select Payment Plan",
            PLACEHOLDER: "Select Payment Plan",
        },
        DESCRIPTION: {
            LABEL: "Description",
            PLACEHOLDER: "Description",
        },
        CURRENT_BALANCE: {
            LABEL: "Current Balance",
            PLACEHOLDER: "Current Balance",
        },
        NEW_BALANCE: {
            LABEL: "New Balance",
            PLACEHOLDER: "New Balance",
        },
        CASH: {
            LABEL: "Cash Amount",
            PLACEHOLDER: "Cash Amount",
        },
        CARD: {
            LABEL: "Card Amount",
            PLACEHOLDER: "Card Amount",
        },
        OPERATED_BY: {
            LABEL: "Operated by",
            PLACEHOLDER: "Select user",
        },
        ERRORS: {
            REQUIRED_FIELD: (fieldName: string) => `${fieldName} is required.`,
            INVALID_CREDENTIALS: "Invalid credentials for user",
            UNKNOWN_ERROR: "An error ocurred, please contact support.",
            WRONG_AMOUNT_TO_PAY: "Amount to Pay must be lower or equal than outstanding and greater than zero",
        },
        DESCRIPTION_COMPOSSER: (id: string) => `Payment Payment Plan ${id}`,
        TABS: {
            CASH: "Cash",
            CARD: "Card",
        },
        SAVING_TITLE: "Saving Payment",
        SAVING_DESCRIPTION: "Please wait",
    },
    SAVE_BUTTON: "Save",
    CANCEL_BUTTON: "Cancel",
    CONFIRM_MODAL: {
        TITLE: 'Save payment?',
        DESCRIPTION: "Operation will not be reverted.",
        ACTION_BUTTON: "Save",
        CANCEL_BUTTON: "Go Back",
    },
    CANCEL_MODAL: {
        TITLE: "Cancel payment input?",
        DESCRIPTION: "All data will be lost.",
        ACTION_BUTTON: "Yes, Cancel",
        CANCEL_BUTTON: "Keep working",
    },
    SUCCESS_MODAL: {
        TITLE: "Success",
        PRIMARY_LABEL: "Payment created",
        SECONDARY_LABEL: "Accept",
        DESCRIPTION: "Accept",
    },
}

const DescriptionsEsUs: MedicalPaymentDescriptions = {
    TITLE: "Crear Pago Médico",
    PERSONAL_DATA: {
        TITLE: "Datos personales",
        CUSTOMER: {
            LABEL: "Paciente",
            PLACEHOLDER: "Seleccione paciente",
        },
        EMAIL: {
            LABEL: "Correo",
            PLACEHOLDER: "Correo",
        },
        PHONE: {
            LABEL: "Teléfono",
            PLACEHOLDER: "Teléfono",
        },
        BALANCE: {
            LABEL: "Saldo",
            PLACEHOLDER: "Saldo",
        },
        GETTING_PATIENT_DATA: "Obeniendo información del paciente.",
        ERROR_GETTING_PATIENTS: "Ha ocurrido un error al obtener los pacientes.",
        PLANS_VISIBILITY: (numberOfPlans: number, hide?: boolean) => {
            if (numberOfPlans === 0) {
                return `Sin planes`
            }
            return hide ? `Mostrar ${numberOfPlans} Plan(es)` : `Ocultar ${numberOfPlans} Plan(es)`
        },
        PLAN_DATA_COMPOSSER: (paymentPlan: PaymentPlan) => {
            const datetime: string = dayjs(paymentPlan.datetime).format(SystemConstants.DATETIME_FORMAT_DISPLAY)
            const amount: string = formatToCurrency(paymentPlan.amount.toString())
            const amountPayed: string = formatToCurrency(paymentPlan.amountPayed.toString())
            const outstanding: string = formatToCurrency(
                (parseFloat(paymentPlan.amount.toString()) - parseFloat(paymentPlan.amountPayed.toString())).toString()
            )

            return `Creado: ${datetime}. Total: ${amount}. Pagado: ${amountPayed}. Pendiente: ${outstanding}`
        },
    },
    PAYMENT: {
        TITLE: "Datos de pago",
        SUBTITLE: "Cobro",
        PAYMENT_PLAN: {
            LABEL: "Seleccione plan de pago",
            PLACEHOLDER: "Seleccione plan de pago",
        },
        DESCRIPTION: {
            LABEL: "Descripción",
            PLACEHOLDER: "Descripción",
        },
        CURRENT_BALANCE: {
            LABEL: "Saldo actual",
            PLACEHOLDER: "Saldo actual",
        },
        NEW_BALANCE: {
            LABEL: "Nuevo saldo",
            PLACEHOLDER: "Nuevo saldo",
        },
        CASH: {
            LABEL: "Monto Efectivo",
            PLACEHOLDER: "Monto Efectivo",
        },
        CARD: {
            LABEL: "Monto Tarjeta",
            PLACEHOLDER: "Monto Tarjeta",
        },
        OPERATED_BY: {
            LABEL: "Operado por",
            PLACEHOLDER: "Seleciones usuario",
        },
        ERRORS: {
            REQUIRED_FIELD: (fieldName: string) => `${fieldName} es requerido.`,
            INVALID_CREDENTIALS: "Credenciales de usuario inválidas",
            UNKNOWN_ERROR: "Ha ocurrido un error, por favor contacta a soporte.",
            WRONG_AMOUNT_TO_PAY: "El total a pagar debe ser menor o igual al saldo y mayor a cero",
        },
        DESCRIPTION_COMPOSSER: (id: string) => `Abono plan de pago ${id}`,
        TABS: {
            CASH: "Efectivo",
            CARD: "Tarjeta",
        },
        SAVING_TITLE: "Guardando Pago",
        SAVING_DESCRIPTION: "Espere por favor",
    },
    SAVE_BUTTON: "Guardar",
    CANCEL_BUTTON: "Cancelar",
    CONFIRM_MODAL: {
        TITLE: 'Guardar pago con los datos ingresado?',
        DESCRIPTION: "La operación no podrá ser revertida.",
        ACTION_BUTTON: "Guardar",
        CANCEL_BUTTON: "Regresar",
    },
    CANCEL_MODAL: {
        TITLE: "Cancelar ingreso de pago?",
        DESCRIPTION: "La información ingresada sera borrada.",
        ACTION_BUTTON: "Si, Cancelar",
        CANCEL_BUTTON: "Seguir ingresando",
    },
    SUCCESS_MODAL: {
        TITLE: "Exito",
        PRIMARY_LABEL: "Pago creado",
        SECONDARY_LABEL: "Aceptar",
        DESCRIPTION: "Aceptar",
    },
}

export const medicalPaymentCreateDictionary = {}
medicalPaymentCreateDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
medicalPaymentCreateDictionary[Languages.SPANISH_US] = DescriptionsEsUs