import { SystemConstants } from "src/common/constants";
import dayjs from 'dayjs'
import { SummaryType } from "src/components/SearchableTable/types";
import { searchableTableUtils } from "src/components/SearchableTable/utils";
import { SystemDescriptions } from "src/common/descriptions/descriptions";

const descriptions = SystemDescriptions.PAGES.MEDICAL_AREA.PATIENTS

export enum ColumnsKeys {
    ID = 'id_patient',
    NAME = 'name',
    SURNAME = 'surname',
    BIRTHDATE = 'birthdate',
    AGE = 'age',
    GENDER = 'gender',
    PHONE = 'phone',
    IS_CLIENT = 'isClient',
    USERNAME = 'username',
    EMAIL = 'email',
    ACTIONS = 'actions',
}

export const summaryColumns: Partial<Record<ColumnsKeys, SummaryType>> = {
}

export const tableColumns: any = [
    {
        title: descriptions.TABLE_COLUMNS.ID,
        dataIndex: ColumnsKeys.ID,
        key: ColumnsKeys.ID,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.NAME,
        dataIndex: ColumnsKeys.NAME,
        key: ColumnsKeys.NAME,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.SURNAME,
        dataIndex: ColumnsKeys.SURNAME,
        key: ColumnsKeys.SURNAME,
    },
    {
        title: descriptions.TABLE_COLUMNS.BIRTHDATE,
        dataIndex: ColumnsKeys.BIRTHDATE,
        key: ColumnsKeys.BIRTHDATE,
        render: (value) => {
            return dayjs(value).format(SystemConstants.DATE_FORMAT)
        },
    },
    {
        title: descriptions.TABLE_COLUMNS.AGE,
        dataIndex: ColumnsKeys.BIRTHDATE,
        key: ColumnsKeys.BIRTHDATE,
        render: (value) => {

            function calculateAge(birthdate) {
                const today = dayjs();
                const birthdateObj = dayjs(birthdate);
                const age = today.diff(birthdateObj, 'year');
                return age;
            }

            return calculateAge(value)
        },
    },
    {
        title: descriptions.TABLE_COLUMNS.GENDER,
        dataIndex: ColumnsKeys.GENDER,
        key: ColumnsKeys.GENDER,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.PHONE,
        dataIndex: ColumnsKeys.PHONE,
        key: ColumnsKeys.PHONE,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.EMAIL,
        dataIndex: ColumnsKeys.EMAIL,
        key: ColumnsKeys.EMAIL,
        align: searchableTableUtils.alignment.centerAlign,
    },
];