import { SystemDescriptions } from "src/common/descriptions/descriptions";
import { formatToDisplayDatetime } from "src/common/util";
import { searchableTableUtils } from "src/components/SearchableTable/utils";

const descriptions = SystemDescriptions.PAGES.MEDICAL_AREA.PATIENT_PROFILE.CONSULTATION_RECORDS

export enum ColumnsKeys {
    ID = 'id',
    DATETIME = "datetime",
    MEDICAL_AREA = "medicalAreaId",
}

export const tableColumns: any = [
    {
        title: descriptions.TABLE_COLUMNS.ID,
        dataIndex: ColumnsKeys.ID,
        key: ColumnsKeys.ID,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.DATETIME,
        dataIndex: ColumnsKeys.DATETIME,
        key: ColumnsKeys.DATETIME,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToDisplayDatetime(value)
    },
    {
        title: descriptions.TABLE_COLUMNS.MEDICAL_AREA,
        dataIndex: ColumnsKeys.MEDICAL_AREA,
        key: ColumnsKeys.MEDICAL_AREA,
        align: searchableTableUtils.alignment.centerAlign,
    },
];