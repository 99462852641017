import { call, put, takeLatest, } from 'redux-saga/effects';
import * as ActionTypes from './actionTypes';
import * as Actions from './action';
import { Urls } from 'src/common/urls';
import { NetworkingProvider } from 'src/features/Security/networking/networkingProvider';
import { GetServiceDebitFilters } from 'src/common/models/serviceDebitBatch';

const buildServiceDebitsQuery = (filters?: GetServiceDebitFilters): string => {
    const params: string[] = []
    if (!filters) {
        return ''
    }

    if (filters.dateFrom && filters.dateTo) {
        params.push(`dateFrom=${filters.dateFrom}`)
        params.push(`dateTo=${filters.dateTo}`)
    }
    if (filters.clientId) {
        params.push(`clientId=${filters.clientId}`)
    }

    return params.length > 0
        ? `?${params.join('&')}`
        : ``
}

function* getServiceDebitsApi(action: Actions.GetServiceDebitsAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getAppInterface().get, Urls.BACKEND.SERVICE_DEBIT.GET(buildServiceDebitsQuery(action.filters)))
        yield put(Actions.getServiceDebitsSuccess(response.data))
    } catch (err) {
        yield put(Actions.getServiceDebitsError(err));
    }
}

export default function* serviceDebitSagas() {
    yield takeLatest(ActionTypes.GET_SERVICE_DEBITS_API, getServiceDebitsApi)
}