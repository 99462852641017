import Axios, { AxiosInstance } from 'axios'
import { SystemConstants } from 'src/common/constants'
import { getSessionData } from 'src/session/sessionStore'

export class NetworkingProvider {
    private static instance: NetworkingProvider

    private controlInterface
    private securityInterface

    private constructor() {
        this.controlInterface = Axios.create({
            baseURL: `${SystemConstants.BACKEND_SERVER.CONTROL_POV}/api/v1`
        })
        this.controlInterface.interceptors.response.use(this.responseInterceptorResponse, this.responseInterceptorError)

        this.securityInterface = Axios.create({
            baseURL: `${SystemConstants.BACKEND_SERVER.CONTROL_POV}/api/v1`
        })
    }

    public static getInstance = () => {
        if (!NetworkingProvider.instance) {
            NetworkingProvider.instance = new NetworkingProvider()
        }
        return NetworkingProvider.instance
    }

    public getControlInterface = (): AxiosInstance => {
        const sessionData = getSessionData()
        if (sessionData.accessToken) {
            this.controlInterface.defaults.headers.common['Authorization'] = sessionData.accessToken
            return this.controlInterface
        } else {
            // redirectToLogout()
            throw new Error("No access token")
        }
    }

    public getAppInterface = (): AxiosInstance => {
        // return this.controlInterface
        const queryParameters: any = new URLSearchParams(window.location.search)
        const accessToken: string = queryParameters.get(SystemConstants.QUERY_PARAMS.ACCESS_TOKEN)

        if (accessToken) {
            this.controlInterface.defaults.headers.common['Authorization'] = accessToken
            return this.controlInterface
        } else {
            // redirectToLogout()
            throw new Error("No access token")
        }

        const sessionData = getSessionData()
        if (sessionData.accessToken) {
            console.log("ui-at", sessionData.accessToken)
            this.controlInterface.defaults.headers.common['Authorization'] = sessionData.accessToken
            return this.controlInterface
        } else {
            // redirectToLogout()
            throw new Error("No access token")
        }
    }

    public getLoginInterface = (): AxiosInstance => {
        return this.securityInterface
    }

    public getSecurityInterface = (): AxiosInstance => {
        delete this.securityInterface.defaults.headers.common['Authorization']
        return this.securityInterface
    }

    private responseInterceptorResponse = (response) => {
        return response
    }

    private responseInterceptorError = async (error) => {
        if (error.response.status !== 401) {
            throw error
        }
        try {
            // TODO: REFRESH TOKEN
            // redirectToLogout()
        } catch (err) {
            // redirectToLogout()
            throw err
        }
    }
}