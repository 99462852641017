import { combineReducers } from 'redux'
import apiLoaderReducer, { ApiLoaderState } from 'src/common/apiLoader/state/reducer'
import commonReducer, { CommonState } from 'src/common/state/reducer'
import menuReducer, { MenuState } from 'src/components/Sidebar/state/reducer'
import securityReducer, { SecurityState } from 'src/features/Security/state/reducer'
import medicalAreaReducer, { MedicalAreaState } from 'src/features/MedicalArea/state/reducer'
import employeeReducer, { EmployeeState } from 'src/features/Employee/state/reducer'
import consultationTypeReducer, { ConsultationTypeState } from 'src/features/MedicalArea/ConsultationType/state/reducer'
import serviceDebitReducer, { ServiceDebitState } from 'src/features/ServiceDebit/state/reducer'
import reportReducer, { ReportState } from 'src/features/Reports/state/reducer'

export interface RootState {
    apiLoader: ApiLoaderState
    common: CommonState
    consultationType: ConsultationTypeState
    employee: EmployeeState
    medicalArea: MedicalAreaState
    menu: MenuState
    report: ReportState
    security: SecurityState
    serviceDebit: ServiceDebitState
}

export default combineReducers<RootState>({
    apiLoader: apiLoaderReducer,
    common: commonReducer,
    consultationType: consultationTypeReducer,
    employee: employeeReducer,
    menu: menuReducer,
    medicalArea: medicalAreaReducer,
    report: reportReducer,
    security: securityReducer,
    serviceDebit: serviceDebitReducer,
})