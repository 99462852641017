import { SystemDescriptions } from "src/common/descriptions/descriptions";
import { formatToCurrency } from "src/common/parser";
import { formatToDisplayDatetime } from "src/common/util";
import { SummaryType } from "src/components/SearchableTable/types";
import { searchableTableUtils } from "src/components/SearchableTable/utils";

const descriptions = SystemDescriptions.PAGES.MEDICAL_AREA.PATIENT_PROFILE.ACCOUNT_STATEMENT

export enum ColumnsKeys {
    ID = 'id',
    DATETIME = "datetime",
    DESCRIPTION = "description",
    PREVIOUS = 'previous',
    CREDIT = "credit",
    DEBIT = "debit",
    BALANCE = "outstanding",
}

export const summaryColumns: Partial<Record<ColumnsKeys, SummaryType>> = {
    [ColumnsKeys.CREDIT]: SummaryType.SUM,
    [ColumnsKeys.DEBIT]: SummaryType.SUM,
}

export const tableColumns: any = [
    {
        title: descriptions.TABLE_COLUMNS.ID,
        dataIndex: ColumnsKeys.ID,
        key: ColumnsKeys.ID,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.DATETIME,
        dataIndex: ColumnsKeys.DATETIME,
        key: ColumnsKeys.DATETIME,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToDisplayDatetime(value)
    },
    {
        title: descriptions.TABLE_COLUMNS.DESCRIPTION,
        dataIndex: ColumnsKeys.DESCRIPTION,
        key: ColumnsKeys.DESCRIPTION,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.PREVIOUS,
        dataIndex: ColumnsKeys.PREVIOUS,
        key: ColumnsKeys.PREVIOUS,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value)
    },
    {
        title: descriptions.TABLE_COLUMNS.CREDIT,
        dataIndex: ColumnsKeys.CREDIT,
        key: ColumnsKeys.CREDIT,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value)
    },
    {
        title: descriptions.TABLE_COLUMNS.DEBIT,
        dataIndex: ColumnsKeys.DEBIT,
        key: ColumnsKeys.DEBIT,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value)
    },
    {
        title: descriptions.TABLE_COLUMNS.BALANCE,
        dataIndex: ColumnsKeys.BALANCE,
        key: ColumnsKeys.BALANCE,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value)
    },
];