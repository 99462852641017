import { FlexDiv, HorizontalFlexDiv } from "src/common/styles/div";
import styled from "styled-components";

export const TitleContainer = styled(HorizontalFlexDiv)`
    gap: 12px;
    align-items: center;
`
export const ContentContainer = styled(FlexDiv)`
    width: 100%;
`
export const Title = styled.label`
    font-weight: bold;
    font-size: 14px;
`