import { Form, Input, Row, Select, Divider, Button, Tabs, Alert } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Patient } from 'src/common/models/patient';
import { MedicalAreaError, MedicalAreaErrorOrigin } from '../state/types';
import { RootState } from 'src/state/reducer';
import { serviceLoading } from 'src/common/apiLoader/state/selection';
import { CREATE_MEDICAL_PAYMENT_API, GET_PATIENTS_API, GET_PATIENT_BY_ID_API } from '../state/actionTypes';
import { createMedicalPaymentApi, getPatientByIdApi, getPatientsApi } from '../state/action';
import dayjs from 'dayjs';
import { SystemConstants } from 'src/common/constants';
import InnerLoader from 'src/components/innerLoader';
import { ButtonsContainer, PageCard } from 'src/common/styles/styles';
import { Col24, Col4, Col8, Col18, Col6, Col12 } from 'src/components/Columns';
import { ActionButton } from 'src/components/ActionButton';
import { OperatedBySelect } from 'src/components/OperatedBySelect';
import { UserRequest } from 'src/common/models/employee';
import { PaymentPlan } from 'src/common/models/paymentPlan';
import { MedicalPaymentData, PaymentType } from './types';
import { ApiError } from 'src/features/Security/networking/types';
import { Loader } from 'src/components/Loader';
import { CancelConfirmationModal } from 'src/components/cancelConfirmationModal';
import { ModalSuccess } from 'src/components/ModalSuccess';
import { useNavigate } from 'react-router-dom';
import { Urls } from 'src/common/urls';
import { UserOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { checkIfSystemIsEmbed } from 'src/common/util';
import { formatToCurrency } from 'src/common/parser';
import { ActionButtonType } from 'src/components/ActionButton/types';
import { SystemDescriptions } from 'src/common/descriptions/descriptions';
import { CurrencyInput } from 'src/components/CurrencyInput';
import { PaymentPlanList } from './PaymentPlanList';

interface ReduxProps {
    patients: Patient[]
    error?: MedicalAreaError
    isGettingPatients: boolean
    isGettingPatientById: boolean
    currentPatient?: Patient
    isCreatingPayment: boolean
    createPaymentSuccess: boolean
}

export const PatientPayment = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [form] = Form.useForm();

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        patients: state.medicalArea.patients,
        error: state.medicalArea.error,
        isGettingPatientById: serviceLoading(state, [GET_PATIENT_BY_ID_API]),
        isGettingPatients: serviceLoading(state, [GET_PATIENTS_API]),
        currentPatient: state.medicalArea.currentPatient,
        isCreatingPayment: serviceLoading(state, [CREATE_MEDICAL_PAYMENT_API]),
        createPaymentSuccess: state.medicalArea.createMedicalPaymentSuccess,
    }))

    const [plansVisible, setPlansVisible] = useState<boolean>(false)
    const [selectedPlan, setSelectedPlan] = useState<PaymentPlan | undefined>(undefined)
    const [newBalance, setnewBalance] = useState<number>(0)
    const [paymentType, setPaymentType] = useState<PaymentType>(PaymentType.CASH)
    const [userRequest, setUserRequest] = useState<UserRequest | undefined>(undefined)
    const [modalConfirmationOpen, setModalConfirmationOpen] = useState<boolean>(false)
    const [modalCancelOpen, setModalCancelOpen] = useState<boolean>(false)
    const [actionButtonClicked, setActionButtonClicked] = useState<boolean>(false)

    const descriptions = SystemDescriptions.PAGES.MEDICAL_AREA.MEDICAL_PAYMENT

    useEffect(() => {
        dispatch(getPatientsApi({ paymentPlan: "1" }))
    }, [dispatch])

    const buildPaymentPlanDescription = (id: string): string => descriptions.PAYMENT.DESCRIPTION_COMPOSSER(id)

    useEffect(() => {
        if (selectedPlan) {
            setnewBalance(selectedPlan.amount - selectedPlan.amountPayed)
            form.setFieldsValue({
                description: buildPaymentPlanDescription(selectedPlan.id?.toString() ?? '')
            })
        } else {
            setnewBalance(0)
        }
    }, [selectedPlan, form])

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const handlePatientChange = (value: string) => {
        // TODO: CLEAR PAYMENT VALUES 
        form.setFieldsValue({
            paymentPlanId: undefined,
            description: undefined,
            cash: 0,
            card: 0,
        })
        setSelectedPlan(undefined)
        dispatch(getPatientByIdApi(parseInt(value), { populated: "1" }))
    }

    const renderSearchSection = () => (
        <>
            <PageCard size="small" title={descriptions.PERSONAL_DATA.TITLE}>
                {
                    reduxProps.isGettingPatients
                        ? <InnerLoader />
                        : reduxProps.error && reduxProps.error.type === MedicalAreaErrorOrigin.GET_PATIENTS
                            ? <Alert type="error" message={descriptions.PERSONAL_DATA.ERROR_GETTING_PATIENTS} banner />
                            :
                            <Select
                                style={{ width: '100%' }}
                                showSearch
                                placeholder={descriptions.PERSONAL_DATA.CUSTOMER.PLACEHOLDER}
                                optionFilterProp="children"
                                onChange={handlePatientChange}
                                filterOption={filterOption}
                                options={reduxProps.patients.map(patient => ({
                                    value: `${patient.id_patient ?? 0}`,
                                    label: `${patient.name} ${patient.surname} - ${dayjs(patient.birthdate).format(SystemConstants.DATE_FORMAT)} - ${patient.doc_id}`,
                                }))}
                            />
                }
            </PageCard>
        </>
    )

    const renderViewPlans = () => {
        const plans: number = reduxProps.currentPatient?.paymentPlanDetail?.length ?? 0
        return descriptions.PERSONAL_DATA.PLANS_VISIBILITY(plans, !plansVisible)
    }

    const renderExtraButton = () => {
        const handleExtraClick = () => {
            setPlansVisible(!plansVisible)
        }

        return <Button type="link" onClick={handleExtraClick}>{renderViewPlans()}</Button>
    }

    const renderDataSection = () => (
        <>
            {reduxProps.isGettingPatientById && <InnerLoader label={descriptions.PERSONAL_DATA.GETTING_PATIENT_DATA} />}
            {reduxProps.currentPatient &&
                <PageCard size="small" title={descriptions.PERSONAL_DATA.TITLE}
                    extra={renderExtraButton()}
                >
                    {reduxProps.currentPatient &&
                        <Form
                            layout="vertical"
                            requiredMark={false}
                        >
                            <Row gutter={16}>
                                <Col8>
                                    <Form.Item
                                        label={descriptions.PERSONAL_DATA.CUSTOMER.LABEL}
                                    >
                                        <Input defaultValue={`${reduxProps.currentPatient?.name} ${reduxProps.currentPatient?.surname} `} readOnly prefix={<UserOutlined />} />
                                    </Form.Item>
                                </Col8>
                                <Col8>
                                    <Form.Item
                                        label={descriptions.PERSONAL_DATA.EMAIL.LABEL}
                                    >
                                        <Input defaultValue={reduxProps.currentPatient?.email} readOnly prefix={<MailOutlined />} />
                                    </Form.Item>
                                </Col8>
                                <Col4>
                                    <Form.Item
                                        label={descriptions.PERSONAL_DATA.PHONE.LABEL}
                                    >
                                        <Input defaultValue={reduxProps.currentPatient?.phone} readOnly
                                            prefix={<PhoneOutlined />}
                                        />
                                    </Form.Item>
                                </Col4>
                                <Col4>
                                    <Form.Item
                                        label={descriptions.PERSONAL_DATA.BALANCE.LABEL}
                                    >
                                        <CurrencyInput
                                            defaultValue={formatToCurrency(reduxProps.currentPatient?.outstanding?.toString() ?? "0")}
                                            readOnly
                                            prefix={'Q'}
                                        />
                                    </Form.Item>
                                </Col4>
                                {
                                    plansVisible &&
                                    <Col24>
                                        <>
                                            <Divider />
                                            <PaymentPlanList
                                                data={reduxProps.currentPatient?.paymentPlanDetail}
                                            />
                                        </>
                                    </Col24>
                                }
                            </Row>
                        </Form>
                    }
                </PageCard>
            }
        </>
    )

    const handlePaymentPlanChange = (value: string) => {
        const planFound = reduxProps.currentPatient?.paymentPlanDetail?.find(candidate => candidate.id === value)
        setSelectedPlan(planFound)
    }

    const handleOperatedByChange = (user: UserRequest | undefined) => {
        form.setFieldsValue({
            createdBy: user?.username ?? undefined
        })

        setUserRequest(user)
    }

    const onFinish = () => {
        setModalConfirmationOpen(true)
    }

    const getCreatePaymentErrorMessage = (error: ApiError): string => {
        let message: string = descriptions.PAYMENT.ERRORS.UNKNOWN_ERROR

        if (error?.code === 404) {
            message = descriptions.PAYMENT.ERRORS.INVALID_CREDENTIALS
        }

        return message
    }

    const buildRequiredMessageErr = (fieldName: string): string => descriptions.PAYMENT.ERRORS.REQUIRED_FIELD(fieldName)

    const isValidPayedAmount = (value) => {
        const currencyRegex = /^\d+(\.\d{1,2})?$/;

        if (!currencyRegex.test(value)) {
            return Promise.reject();
        }

        const currentOutstanding: number = (selectedPlan?.amount ?? 0) - (selectedPlan?.amountPayed ?? 0)
        const amount: number = parseFloat(value)
        return amount <= currentOutstanding && amount > 0
    }

    const renderPaymentSection = () => (
        reduxProps.currentPatient &&
        <Form
            layout="vertical"
            requiredMark={false}
            form={form}
            onFinish={onFinish}
        >
            <Row gutter={8}>
                <Col18>
                    <PageCard size="small" title={descriptions.PAYMENT.TITLE}>

                        <Row gutter={16}>
                            <Col12>
                                <Form.Item
                                    label={descriptions.PAYMENT.PAYMENT_PLAN.LABEL}
                                    name="paymentPlanId"
                                    rules={[{ required: true, message: buildRequiredMessageErr(descriptions.PAYMENT.PAYMENT_PLAN.LABEL) }]}
                                    validateTrigger="onBlur"
                                >
                                    <Select
                                        onChange={handlePaymentPlanChange}
                                        options={reduxProps.currentPatient.paymentPlanDetail?.filter(plan => !plan.payed)
                                            .map(plan => ({
                                                value: plan.id,
                                                label: `${plan.id} - ${plan.description}`,
                                            }))}
                                        placeholder={descriptions.PAYMENT.PAYMENT_PLAN.PLACEHOLDER}
                                    />
                                </Form.Item>
                            </Col12>
                            <Col12>
                                <Form.Item
                                    label={descriptions.PAYMENT.DESCRIPTION.LABEL}
                                    name="description"
                                    rules={[{ required: true, message: buildRequiredMessageErr(descriptions.PAYMENT.DESCRIPTION.LABEL) }]}
                                    validateTrigger="onBlur"
                                >
                                    <Input
                                        placeholder={descriptions.PAYMENT.DESCRIPTION.PLACEHOLDER}
                                        readOnly />
                                </Form.Item>
                            </Col12>
                            <Col8>
                                <Form.Item
                                    label={descriptions.PAYMENT.CURRENT_BALANCE.LABEL}
                                >
                                    <CurrencyInput
                                        value={
                                            formatToCurrency(((selectedPlan?.amount ?? 0) - (selectedPlan?.amountPayed ?? 0)).toString())
                                        }
                                        readOnly
                                        prefix={'Q'}
                                    />
                                </Form.Item>
                            </Col8>
                            <Col8>
                                <Form.Item
                                    label={descriptions.PAYMENT.NEW_BALANCE.LABEL}
                                >
                                    <CurrencyInput
                                        value={formatToCurrency(newBalance.toString())} readOnly
                                        prefix={'Q'}
                                    />
                                </Form.Item>
                            </Col8>
                            <Col8>
                                <Form.Item
                                    label={descriptions.PAYMENT.OPERATED_BY.LABEL}
                                    name="createdBy"
                                    rules={[{ required: true, message: buildRequiredMessageErr(descriptions.PAYMENT.OPERATED_BY.LABEL) }]}
                                    validateTrigger="onBlur"
                                >
                                    <OperatedBySelect
                                        onChange={handleOperatedByChange}
                                    />
                                </Form.Item>
                            </Col8>
                        </Row>

                    </PageCard >
                </Col18>
                <Col6>
                    <PageCard size="small" title={descriptions.PAYMENT.SUBTITLE} style={{ height: '100%' }}>
                        <Tabs
                            defaultActiveKey={PaymentType.CASH}
                            items={[
                                {
                                    label: descriptions.PAYMENT.TABS.CASH,
                                    key: PaymentType.CASH,
                                    children: <Col24>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '24px' }}>
                                            <Form.Item
                                                label={descriptions.PAYMENT.CASH.LABEL}
                                                name="cash"
                                                rules={
                                                    paymentType === PaymentType.CASH
                                                        ? [
                                                            {
                                                                required: true,
                                                                message: buildRequiredMessageErr(descriptions.PAYMENT.CASH.LABEL),
                                                            },
                                                            {
                                                                message: descriptions.PAYMENT.ERRORS.WRONG_AMOUNT_TO_PAY,
                                                                validator: (_, value) => {
                                                                    return isValidPayedAmount(value)
                                                                        ? Promise.resolve()
                                                                        : Promise.reject()
                                                                }
                                                            }
                                                        ]
                                                        : undefined
                                                }
                                                validateTrigger="onChange"
                                            >

                                                <CurrencyInput
                                                    defaultValue={0}
                                                    prefix={'Q'}
                                                />

                                            </Form.Item>
                                        </div>
                                    </Col24>,
                                },
                                {
                                    label: descriptions.PAYMENT.TABS.CARD,
                                    key: PaymentType.CARD,
                                    children: <Col24>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '24px' }}>
                                            <Form.Item
                                                label={descriptions.PAYMENT.CARD.LABEL}
                                                name="card"
                                                rules={
                                                    paymentType === PaymentType.CARD
                                                        ? [
                                                            {
                                                                required: true,
                                                                message: buildRequiredMessageErr(descriptions.PAYMENT.CARD.LABEL),
                                                            },
                                                            {
                                                                message: descriptions.PAYMENT.ERRORS.WRONG_AMOUNT_TO_PAY,
                                                                validator: (_, value) => {
                                                                    return isValidPayedAmount(value)
                                                                        ? Promise.resolve()
                                                                        : Promise.reject()
                                                                }
                                                            }
                                                        ]
                                                        : undefined
                                                }
                                                validateTrigger="onChange"
                                            >
                                                <CurrencyInput
                                                    defaultValue={0}
                                                    prefix={'Q'}
                                                />
                                            </Form.Item>
                                        </div>
                                    </Col24>,
                                },
                            ]}
                            onChange={(activeKey => {
                                setPaymentType(activeKey as PaymentType)
                            })}
                        />
                    </PageCard >
                </Col6>
            </Row>
            {
                reduxProps.error && reduxProps.error.type === MedicalAreaErrorOrigin.CREATE_MEDICAL_PAYMENT &&
                <Alert
                    style={{ marginTop: 12 }}
                    type="error" message={getCreatePaymentErrorMessage(reduxProps.error?.detail!)} banner />
            }
            {renderButtons()}
        </Form >
    )

    const handleCancelClick = () => {
        setModalCancelOpen(true)
    }

    const renderButtons = () => (
        reduxProps.currentPatient &&
        <Form.Item>
            <ButtonsContainer>
                <ActionButton
                    label={descriptions.CANCEL_BUTTON}
                    onClick={handleCancelClick}
                    actionButtonType={ActionButtonType.DESTRUCTIVE}
                />
                <ActionButton
                    label={descriptions.SAVE_BUTTON}
                    htmlType='submit'
                />
            </ButtonsContainer>
        </Form.Item>
    )

    const renderLoader = () => (
        reduxProps.isCreatingPayment && <Loader
            isVisible={true}
            title={descriptions.PAYMENT.SAVING_TITLE}
            description={descriptions.PAYMENT.SAVING_DESCRIPTION}
        />
    )

    const handleConfirmationAccept = () => {
        setModalConfirmationOpen(false)
        const formValues = form.getFieldsValue()

        if (!reduxProps.currentPatient?.id_patient || !userRequest) {
            return
        }

        const cash: number = parseFloat(formValues?.cash ?? "0")
        const card: number = parseFloat(formValues?.card ?? "0")

        const paymentData: MedicalPaymentData = {
            ...formValues,
            patientId: reduxProps.currentPatient?.id_patient,
            cash,
            card,
        }
        setActionButtonClicked(true)
        dispatch(createMedicalPaymentApi(paymentData, userRequest))
    }

    const handleCancelAccept = () => {
        const isEmbed = checkIfSystemIsEmbed()

        if (isEmbed) {
            if (window.self !== window.top) {
                window.parent.location.href = SystemConstants.DEFAULT_REDIRECT_URL
            } else {
                window.location.href = SystemConstants.DEFAULT_REDIRECT_URL
            }
        } else {
            navigate(Urls.FRONTEND.HOME)
        }
    }

    const renderModals = () => (
        <>
            <CancelConfirmationModal
                isVisible={modalConfirmationOpen}
                onAccept={handleConfirmationAccept}
                onCancel={() => { setModalConfirmationOpen(false) }}
                title={descriptions.CONFIRM_MODAL.TITLE}
                okText={descriptions.CONFIRM_MODAL.ACTION_BUTTON}
                cancelText={descriptions.CONFIRM_MODAL.CANCEL_BUTTON}
                description={descriptions.CANCEL_MODAL.DESCRIPTION}
            />
            <CancelConfirmationModal
                isVisible={modalCancelOpen}
                onAccept={handleCancelAccept}
                onCancel={() => { setModalCancelOpen(false) }}
                title={descriptions.CANCEL_MODAL.TITLE}
                okText={descriptions.CANCEL_MODAL.ACTION_BUTTON}
                cancelText={descriptions.CANCEL_MODAL.CANCEL_BUTTON}
                description={descriptions.CANCEL_MODAL.DESCRIPTION}
            />
            <ModalSuccess
                isVisible={reduxProps.createPaymentSuccess && actionButtonClicked}
                title={descriptions.SUCCESS_MODAL.TITLE}
                description={descriptions.SUCCESS_MODAL.DESCRIPTION}
                onPrimaryAction={handleCancelAccept}
                onSecondaryActiont={handleCancelAccept}
                primaryLabel={descriptions.SUCCESS_MODAL.PRIMARY_LABEL}
                secondaryLabel={descriptions.SUCCESS_MODAL.SECONDARY_LABEL}
                hideSecondaryButton={true}
            />
        </>
    )

    return (
        <>
            {renderSearchSection()}
            {renderDataSection()}
            {renderPaymentSection()}
            {renderLoader()}
            {renderModals()}
        </>
    )
}
