import { Card, Statistic } from 'antd'
import { WarningOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { StatisticType } from './types';

interface Props {
  statisticType?: StatisticType
  title: string
  value: number
}

export const StatisticCard = (props: Props) => {

  const getIcon = () => {
    const iconDictionary = {
      [StatisticType.SUCCESS]: <CheckOutlined />,
      [StatisticType.WARNING]: <WarningOutlined />,
      [StatisticType.ERROR]: <CloseOutlined />,
      [StatisticType.NONE]: null,
    }

    return iconDictionary[props.statisticType ?? StatisticType.NONE]
  }

  const getValueColor = (): string => {
    const colorDictionary = {
      [StatisticType.SUCCESS]: "#3f8600",
      [StatisticType.WARNING]: "#FA8C14",
      [StatisticType.ERROR]: "#cf1322",
      [StatisticType.NONE]: "#000",
    }

    return colorDictionary[props.statisticType ?? StatisticType.NONE]
  }

  return (
    <Card bordered={false}>
      <Statistic
        title={props.title}
        value={props.value}
        precision={0}
        valueStyle={{ color: getValueColor() }}
        prefix={getIcon()}
        suffix=""
      />
    </Card>
  )
}
