import * as Actions from "./action";
import * as ActionTypes from "./actionTypes";
import { ReportError, ReportOrigin } from "./types";
import { MedicalPaymentOriginReport, MedicalPaymentPatientReport, MedicalPaymentRecordsReport, MedicalPaymentTypeReport } from "src/common/models/Reports/MedicalArea/payments";
import { getNewMedicalReportKey } from "./reducerUtils";
import { parseDataKey } from "src/common/util";

export interface ReportState {
    error?: ReportError

    medicalArea?: {
        paymentsRecords?: MedicalPaymentRecordsReport[]
        paymentsConsultationType?: MedicalPaymentTypeReport[]
        paymentsOrigin?: MedicalPaymentOriginReport[]
        paymentsPatient?: MedicalPaymentPatientReport[]
        paymentConsultationTypeDetail?: MedicalPaymentTypeReport[]
    }
}

const initialState: ReportState = {
}

const reportReducer = (state: ReportState = initialState, action: any): ReportState => {
    switch (action.type) {
        case ActionTypes.GET_MEDICAL_AREA_REPORT_API: {
            return {
                ...state,
                error: undefined,
            }
        }
        case ActionTypes.GET_MEDICAL_AREA_REPORT_SUCCESS: {
            const successAction = action as Actions.GetMedicalReportSuccessAction;
            const medicalKey = getNewMedicalReportKey(successAction.view)

            return {
                ...state,
                medicalArea: {
                    ...state.medicalArea,
                    [medicalKey]: parseDataKey(successAction.report),
                },
            }
        }
        case ActionTypes.GET_MEDICAL_AREA_REPORT_ERROR: {
            const errorAction = action as Actions.ErrorAction;

            return {
                ...state,
                error: {
                    type: ReportOrigin.MEDICAL_PAYMENTS,
                    detail: errorAction.error
                }
            }
        }
        case ActionTypes.GET_PAYMENT_CONSULTATION_TYPE_DETAIL_API: {
            return {
                ...state,
                error: undefined,
            }
        }
        case ActionTypes.GET_PAYMENT_CONSULTATION_TYPE_DETAIL_SUCCESS: {
            const successAction = action as Actions.GetPaymentConsultationTypeDetailSuccessAction;

            return {
                ...state,
                medicalArea: {
                    ...state.medicalArea,
                    paymentConsultationTypeDetail: successAction.report,
                },
            }
        }
        case ActionTypes.GET_PAYMENT_CONSULTATION_TYPE_DETAIL_ERROR: {
            const errorAction = action as Actions.ErrorAction;

            return {
                ...state,
                error: {
                    type: ReportOrigin.CONSULTATION_TYPE_DETAIL,
                    detail: errorAction.error
                }
            }
        }
    }
    return state;
};

export default reportReducer