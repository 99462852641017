import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { RootState } from "src/state/reducer"
import { MenuState } from "../Sidebar/state/reducer"
import { BreadcrumbDescriptions } from "src/common/descriptions/components/breadcrumb/types"
import { SystemDescriptions } from "src/common/descriptions/descriptions"
import { Urls } from "src/common/urls"
import * as Styles from './styles'
import { Breadcrumb } from "antd"
import { HomeOutlined } from '@ant-design/icons';
import useMenuSelection from "src/hooks/useMenuSelection"

type ReduxProps = {
    menu: MenuState
}

export const BreadCrumb = () => {

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        menu: state.menu,
    }))

    const { } = useMenuSelection()

    const descriptions: BreadcrumbDescriptions = SystemDescriptions.COMPONENTS.BREADCRUMB

    return (
        <Styles.Container>
            <Styles.Title>{reduxProps.menu.selectedMenuOption?.displayName}</Styles.Title>
            <Breadcrumb
                items={[
                    {
                        title: <Link to={Urls.FRONTEND.HOME}>
                            <HomeOutlined />
                            <span>&nbsp;{descriptions.ROOT}</span>
                        </Link>,
                    },
                    {
                        title: (
                            <>
                                <span>{reduxProps.menu.selectedMenuOption?.displayName}</span>
                            </>
                        ),
                    },
                ]}
            />
        </Styles.Container>
    )
}
