import { Button, Card, Row, Space } from "antd"
import { useNavigate } from "react-router-dom"
import { Urls } from "src/common/urls"
import { Col8 } from "src/components/Columns"

export const Reports = () => {
    const navigate = useNavigate()

    const dictionaryOfReports = {
        MEDICAL: {
            PAYMENTS: {
                label: "Payments 2",
                path: Urls.FRONTEND.REPORTS.MEDICAL_AREA.PAYMENTS,
            }
        },
    }

    const renderPaymentItem = (item: { label: string, path: string }) => {
        return <Button
            type="link"
            onClick={() => {
                navigate(item.path)
            }}
        >
            {item.label}
        </Button>
    }

    return (
        <Row gutter={[8, 8]}>
            <Col8>
                <Card title="Medical Área">
                    <Space direction="vertical" >
                        {
                            Object.values(dictionaryOfReports.MEDICAL).map(item => renderPaymentItem(item))
                        }
                    </Space>
                </Card>
            </Col8>
        </Row >
    )
}