import { Languages } from "src/common/descriptions/types";
import { PaymentPlanReceivablesDescriptions } from "./types";

const DescriptionsEnUs: PaymentPlanReceivablesDescriptions = {
    TITLE: "Payment Plan Receivables",
    TABLE: {
        ACTION_BUTTON: "",
        SEARCH_PLACEHOLDER: "Search",
        LOADING_DATA: "Loading data...",
    },
    TABLE_COLUMNS: {
        PLAN_ID: "Plan ID",
        PATIENT_ID: "Patient ID",
        PATIENT: "Patient",
        DESCRIPTION: "Description",
        OUTSTANDING: "Outstanding",
        IGSS: "Orígin",
        DATETIME: "Created at",
    },
}

const DescriptionsEsUs: PaymentPlanReceivablesDescriptions = {
    TITLE: "Cuentas por cobrar - Área Médica",
    TABLE: {
        ACTION_BUTTON: "",
        SEARCH_PLACEHOLDER: "Búsqueda",
        LOADING_DATA: "Obteniendo información...",
    },
    TABLE_COLUMNS: {
        PLAN_ID: "Código Plan",
        PATIENT_ID: "Código Paciente",
        PATIENT: "Paciente",
        DESCRIPTION: "Descripción",
        OUTSTANDING: "Saldo",
        IGSS: "Orígen",
        DATETIME: "Creado",
    },
}

export const paymentPlanReceivablesDictionary = {}
paymentPlanReceivablesDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
paymentPlanReceivablesDictionary[Languages.SPANISH_US] = DescriptionsEsUs