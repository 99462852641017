import { Button, Form, Input, Modal, Row } from "antd"
import React, { useEffect } from "react"
import { SystemDescriptions } from "src/common/descriptions/descriptions"
import { Col24 } from "src/components/Columns"

interface Props {
    username: string
    open: boolean
    onAccept: (password: string) => void
    onCancel: () => void
}

export const ModalCredentials = (props: Props) => {
    const [form] = Form.useForm();

    const descriptions = SystemDescriptions.COMPONENTS.OPERATED_BY_SELECT.CREDENTIALS_MODAL

    useEffect(() => {
        form.setFieldsValue({
            password: undefined,
        })
    }, [props.open])


    const passwordInput = React.useCallback(
        inputElement => {
            if (inputElement && props.open) {
                setTimeout(() => inputElement.focus(), 0);
            }
        },
        [props.open]
    );

    const onFinish = (formValues) => {
        const password = formValues.password
        if (password && password.trim().length > 0)
            props.onAccept(formValues.password)
    }

    const handleCancel = () => {
        form.resetFields()
        props.onCancel()
    }

    const renderForm = () => {
        return <Form
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
            form={form}
        >
            <Row gutter={16}>
                <Col24>
                    <Form.Item
                        label={descriptions.PASSWORD.LABEL}
                        name="password"
                    >
                        <Input.Password
                            defaultValue={''}
                            ref={passwordInput}
                            placeholder={descriptions.PASSWORD.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col24>
                <Col24>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{ width: '100%' }}
                    >
                        {descriptions.ACTION_BUTTON}
                    </Button>
                </Col24>
            </Row>
        </Form>
    }

    return (
        <Modal
            title={descriptions.TITLE(props.username)}
            centered
            open={props.open}
            footer={null}
            onCancel={handleCancel}
        >
            {renderForm()}
        </Modal>
    )
}
