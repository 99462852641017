export interface ServiceDebitBatch {
    id?: number
    clientId: number
    date: string
    cutOffDate: number
    status: ServiceDebitBatchStatus
    outstanding: number
    lastPaymentDate: string
    actionDone: ServiceDebitBatchAction
    ref: number
    isParent: boolean
}

enum ServiceDebitBatchStatus {
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
    CREATED = "CREATED",
    FINISHED = "FINISHED",
    FINISHED_ERR = "FINISHED_ERR",
}

export enum ServiceDebitBatchAction {
    RECHARGED_FEE = "RECHARGED_FEE",
    INACTIVATED = "INACTIVATED",
    INACTIVATED_RECHARGE = "INACTIVATED_RECHARGE",
    NONE = "NONE",
}

export interface GetServiceDebitFilters {
    dateFrom?: string
    dateTo?: string
    clientId?: number
}