import Axios, { AxiosInstance } from 'axios';
import { SystemConstants } from 'src/common/constants';
import { Urls } from '../urls';

const axiosInstance = Axios.create({
    baseURL: `${SystemConstants.BACKEND_SERVER.CONTROL_POV}/api/v1`
})

export const createAxiosInstance = (token): AxiosInstance => {
    axiosInstance.defaults.headers.common['Authorization'] = token;
    axiosInstance.interceptors.response.use(responseInterceptorResponse, responseInterceptorError);
    return axiosInstance;
};

const responseInterceptorResponse = (response) => {
    return response;
}

const responseInterceptorError = (error) => {
    if (error.response.status === 401) {
        window.location.href = Urls.FRONTEND.LOGOUT
        // TODO: Refresh Token
    } else {
        return error.response;
    }
}