import { call, put, takeEvery, } from 'redux-saga/effects';
import * as ActionTypes from './actionTypes';
import * as Actions from './action';
import { Urls } from 'src/common/urls';
import { NetworkingProvider } from 'src/features/Security/networking/networkingProvider';

function* getMedicalReportApi(action: Actions.GetMedicalReportAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getAppInterface().get, Urls.BACKEND.REPORTS.MEDICAL_AREA.PAYMENTS(action.params))

        yield put(Actions.getMedicalReportSuccess(
            action.reportType,
            action.params.view,
            response.data,
        ))
    } catch (err) {
        yield put(Actions.getMedicalReportError(err));
    }
}

function* getConsultationTypeDetailApi(action: Actions.GetPaymentConsultationTypeDetailAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getAppInterface().get, Urls.BACKEND.REPORTS.MEDICAL_AREA.PAYMENTS(action.params))

        yield put(Actions.getPaymentConsultationTypeDetailSuccess(response.data))
    } catch (err) {
        yield put(Actions.getPaymentConsultationTypeDetailError(err));
    }
}

export default function* reportSagas() {
    yield takeEvery(ActionTypes.GET_MEDICAL_AREA_REPORT_API, getMedicalReportApi)
    yield takeEvery(ActionTypes.GET_PAYMENT_CONSULTATION_TYPE_DETAIL_API, getConsultationTypeDetailApi)
}