import { Languages } from "src/common/descriptions/types";
import { CreateConsultationTypeDescriptions } from "./types";

const DescriptionsEnUs: CreateConsultationTypeDescriptions = {
    TITLE: "Create Medical Consultation Type",
    SAVE_BUTTON: "Save",
    CANCEL_BUTTON: "Cancel",
    CONFIRM_MODAL: {
        TITLE: 'Save consultation type?',
        DESCRIPTION: "Operation will not be reverted.",
        ACTION_BUTTON: "Save",
        CANCEL_BUTTON: "Go Back",
    },
    CANCEL_MODAL: {
        TITLE: "Cancel consultation type input?",
        DESCRIPTION: "All data will be lost.",
        ACTION_BUTTON: "Yes, Cancel",
        CANCEL_BUTTON: "Keep working",
    },
    SUCCESS_MODAL: {
        TITLE: "Success",
        PRIMARY_LABEL: "Accept",
        SECONDARY_LABEL: "Accept",
        DESCRIPTION: "Consultation Type Created",
    },
    SUCCESS_SECONDARY_MODAL: {
        TITLE: "Success",
        PRIMARY_LABEL: "Accept",
        SECONDARY_LABEL: "Accept",
        DESCRIPTION: "Consultation Type Updated",
    },
    ERRORS: {
        REQUIRED_FIELD: (fieldName: string) => `${fieldName} is required.`,
        SAVE_ACTION: "",
        UNKNOWN: "An error ocurred while processing request, please contact support."
    },
    FORM: {
        EXTERNAL_ID: {
            LABEL: "External Id",
            PLACEHOLDER: "External Id",
        },
        DESCRIPTION: {
            LABEL: "Description",
            PLACEHOLDER: "Input description",
        },
        PRICE: {
            LABEL: "Price",
            PLACEHOLDER: "Input price",
        },
        STATUS: {
            LABEL: "Status",
            PLACEHOLDER: "Select status",
        },
        ORIGIN: {
            LABEL: "Origin",
            PLACEHOLDER: "Select origin",
        },
        ACTIVE: "Active",
        INACTIVE: "Inactive",
        ERRORS: {
            INVALID_PRICE: "Precio inválido",
            NOT_UNIQUE_FIELD: (field: string) => `A record with ${field} already exists.`,
        },
    },
    MAIN_ACTION_TITLE: "Creating Consultation Type",
    MAIN_ACTION_DESCRIPTION: "Please wait...",
    GETTING_DATA: "Getting data...",
}

const DescriptionsEsUs: CreateConsultationTypeDescriptions = {
    TITLE: "Crear Tipo de Consulta",
    SAVE_BUTTON: "Guardar",
    CANCEL_BUTTON: "Cancelar",
    CONFIRM_MODAL: {
        TITLE: 'Guardar tipo de consulta con los datos ingresados?',
        DESCRIPTION: "La operación no podrá ser revertida.",
        ACTION_BUTTON: "Guardar",
        CANCEL_BUTTON: "Regresar",
    },
    CANCEL_MODAL: {
        TITLE: "Cancelar ingreso de tipo de consulta?",
        DESCRIPTION: "La información ingresada sera borrada.",
        ACTION_BUTTON: "Si, Cancelar",
        CANCEL_BUTTON: "Seguir ingresando",
    },
    SUCCESS_MODAL: {
        TITLE: "Exito",
        PRIMARY_LABEL: "Aceptar",
        SECONDARY_LABEL: "Aceptar",
        DESCRIPTION: "Tipo de consulta creado",
    },
    SUCCESS_SECONDARY_MODAL: {
        TITLE: "Exito",
        PRIMARY_LABEL: "Aceptar",
        SECONDARY_LABEL: "Aceptar",
        DESCRIPTION: "Tipo de consulta Actualizado",
    },
    ERRORS: {
        REQUIRED_FIELD: (fieldName: string) => `${fieldName} es requerido.`,
        SAVE_ACTION: "",
        UNKNOWN: "Ha ocurrido un error al procesar la operación, por favor contacta a soporte."
    },
    FORM: {
        EXTERNAL_ID: {
            LABEL: "Código externo",
            PLACEHOLDER: "Código externo",
        },
        DESCRIPTION: {
            LABEL: "Descripción",
            PLACEHOLDER: "Ingresa descripción",
        },
        PRICE: {
            LABEL: "Precio",
            PLACEHOLDER: "Ingresa precio",
        },
        STATUS: {
            LABEL: "Estado",
            PLACEHOLDER: "Selecciona estado",
        },
        ORIGIN: {
            LABEL: "Origen",
            PLACEHOLDER: "Seleccione origen",
        },
        ACTIVE: "Activo",
        INACTIVE: "Inactivo",
        ERRORS: {
            INVALID_PRICE: "Precio no válido",
            NOT_UNIQUE_FIELD: (field: string) => `El valor ingresado para el campo ${field} ya existe en sistema.`,
        },
    },
    MAIN_ACTION_TITLE: "Creando Tipo de Pago",
    MAIN_ACTION_DESCRIPTION: "Por favor espere...",
    GETTING_DATA: "Obteniendo información...",
}

export const createConsultationTypeDictionary = {}
createConsultationTypeDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
createConsultationTypeDictionary[Languages.SPANISH_US] = DescriptionsEsUs