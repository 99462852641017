import { Action } from 'redux'
import * as Actions from './actionTypes'
import { Employee, GetEmployersFilters } from 'src/common/models/employee'

export interface GetEmployersAction extends Action {
    filters?: GetEmployersFilters
}

export interface GetEmployersSuccessAction extends Action {
    employers: Employee[]
}

export interface ErrorAction extends Action {
    error?
}

export const getEmployersApi = (filters?: GetEmployersFilters): GetEmployersAction => ({
    type: Actions.GET_EMPLOYERS_API,
    filters,
})

export const getEmployersSuccess = (employers: Employee[]): GetEmployersSuccessAction => ({
    type: Actions.GET_EMPLOYERS_SUCCESS,
    employers,
})

export const getEmployersError = (error?): ErrorAction => ({
    type: Actions.GET_EMPLOYERS_ERROR,
    error,
})