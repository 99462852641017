import { Form, Input, Row } from "antd"
import { useEffect } from "react"
import { SystemDescriptions } from "src/common/descriptions/descriptions"
import { Patient } from "src/common/models/patient"
import { formatToDisplayDate } from "src/common/util"
import { Col10, Col18, Col24, Col4, Col6, Col8 } from "src/components/Columns"

interface Props {
    patient: Patient
}

export const PersonalData = (props: Props) => {

    const [form] = Form.useForm();

    useEffect(() => {
        const patient = props.patient

        form.setFieldsValue({
            names: patient.name,
            surnames: patient.surname,
            birthdate: formatToDisplayDate(patient.birthdate),
            gender: patient.gender,
            phone: patient.phone,
            address: patient.address,
            zipcode: patient.zipcode,
            email: patient.email,
            docId: patient.doc_id,
            inCharge: patient.in_charge,
            history: patient.history,
            createdBy: patient.createdBy,
            other: patient.other,
        })
    }, [])

    const descriptions = SystemDescriptions.PAGES.MEDICAL_AREA.PATIENT_PROFILE.PERSONAL_DATA

    const renderForm = () => (
        <Form
            layout="vertical"
            requiredMark={false}
            form={form}
        >
            <Row gutter={16}>
                <Col6>
                    <Form.Item
                        label={descriptions.NAMES}
                        name={"names"}
                    >
                        <Input readOnly />
                    </Form.Item>
                </Col6>
                <Col6>
                    <Form.Item
                        label={descriptions.SURNAMES}
                        name={"surnames"}
                    >
                        <Input readOnly />
                    </Form.Item>
                </Col6>
                <Col4>
                    <Form.Item
                        label={descriptions.BIRTHDATE}
                        name={"birthdate"}
                    >
                        <Input readOnly />
                    </Form.Item>
                </Col4>
                <Col4>
                    <Form.Item
                        label={descriptions.GENDER}
                        name={"gender"}
                    >
                        <Input readOnly />
                    </Form.Item>
                </Col4>
                <Col4>
                    <Form.Item
                        label={descriptions.PHONE}
                        name={"phone"}
                    >
                        <Input readOnly />
                    </Form.Item>
                </Col4>
                <Col6>
                    <Form.Item
                        label={descriptions.ADDRESS}
                        name={"address"}
                    >
                        <Input readOnly />
                    </Form.Item>
                </Col6>
                <Col10>
                    <Form.Item
                        label={descriptions.ZIPCODE}
                        name={"zipcode"}
                    >
                        <Input placeholder="" readOnly />
                    </Form.Item>
                </Col10>
                <Col8>
                    <Form.Item
                        label={descriptions.EMAIL}
                        name={"email"}
                    >
                        <Input placeholder="" readOnly />
                    </Form.Item>
                </Col8>
                <Col6>
                    <Form.Item
                        label={descriptions.DOC_ID}
                        name={"docId"}
                    >
                        <Input readOnly />
                    </Form.Item>
                </Col6>
                <Col18>
                    <Form.Item
                        label={descriptions.IN_CHARGE}
                        name={"inCharge"}
                    >
                        <Input placeholder="" readOnly />
                    </Form.Item>
                </Col18>
                <Col24>
                    <Form.Item
                        label={descriptions.HISTORY}
                        name={"history"}
                    >
                        <Input placeholder="" readOnly />
                    </Form.Item>
                </Col24>
                <Col18>
                    <Form.Item
                        label={descriptions.OTHER}
                        name={"other"}
                    >
                        <Input placeholder="" readOnly />
                    </Form.Item>
                </Col18>
                <Col6>
                    <Form.Item
                        label={descriptions.CREATED_BY}
                        name={"createdBy"}
                    >
                        <Input placeholder="" readOnly />
                    </Form.Item>
                </Col6>
            </Row>
        </Form>
    )

    return (
        renderForm()
    )
}
