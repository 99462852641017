import { useDispatch, useSelector } from "react-redux"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { CREATE_CONSULTATION_TYPE_API, GET_CONSULTATION_TYPE_BY_ID_API } from "src/features/MedicalArea/ConsultationType/state/actionTypes"
import { RootState } from "src/state/reducer"
import { CreateConsultationTypeForm } from "./CreateTypeForm"
import { CancelConfirmationModal } from "src/components/cancelConfirmationModal"
import { ModalSuccess } from "src/components/ModalSuccess"
import { MedicalConsultationType } from "src/common/models/consultationType"
import { useEffect, useState } from "react"
import { SystemDescriptions } from "src/common/descriptions/descriptions"
import { createConsultationTypeApi, getConsultationTypeByIdApi, removeCurrentConsultatioType, updateConsultationTypeApi } from "src/features/MedicalArea/ConsultationType/state/action"
import { Loader } from "src/components/Loader"
import InnerLoader from "src/components/innerLoader"

interface ReduxProps {
    isCreatingConsultationType: boolean
    createConsultationTypeSuccess: boolean
    isGettingConsultationType: boolean
    currentConsultationType?: MedicalConsultationType
}

interface Props {
    onAccept: () => void
    onCancel: () => void
    consultationTypeId?: number
}

export const CreateConsultationType = (props: Props) => {
    const dispatch = useDispatch()

    const [formValues, setFormValues] = useState<Partial<MedicalConsultationType> | undefined>()
    const [modalConfirmationOpen, setModalConfirmationOpen] = useState<boolean>(false)
    const [modalCancelOpen, setModalCancelOpen] = useState<boolean>(false)
    const [actionButtonClicked, setActionButtonClicked] = useState<boolean>(false)

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        createConsultationTypeSuccess: state.consultationType.createConsultationTypeSuccess,
        isCreatingConsultationType: serviceLoading(state, [CREATE_CONSULTATION_TYPE_API]),
        isGettingConsultationType: serviceLoading(state, [GET_CONSULTATION_TYPE_BY_ID_API]),
        currentConsultationType: state.consultationType.currentConsultationType,
    }))

    const descriptions = SystemDescriptions.PAGES.SETTINGS.MEDICAL_AREA.CREATE_CONSULTATION_TYPE

    useEffect(() => {
        if (!props.consultationTypeId) {
            return
        }

        dispatch(getConsultationTypeByIdApi(props.consultationTypeId))
    }, [props.consultationTypeId])


    const handleConfirmationAccept = () => {
        setModalConfirmationOpen(false)

        if (!formValues) {
            return
        }

        setActionButtonClicked(true)

        if (props.consultationTypeId) {
            dispatch(updateConsultationTypeApi(formValues as MedicalConsultationType))
        } else {
            dispatch(createConsultationTypeApi(formValues as MedicalConsultationType))
        }
    }

    const handleCancelAccept = () => {
        dispatch(removeCurrentConsultatioType())
        props.onAccept()
    }

    const renderModals = () => (
        <>
            <CancelConfirmationModal
                isVisible={modalConfirmationOpen}
                onAccept={handleConfirmationAccept}
                onCancel={() => { setModalConfirmationOpen(false) }}
                title={descriptions.CONFIRM_MODAL.TITLE}
                okText={descriptions.CONFIRM_MODAL.ACTION_BUTTON}
                cancelText={descriptions.CONFIRM_MODAL.CANCEL_BUTTON}
                description={descriptions.CONFIRM_MODAL.DESCRIPTION}
            />
            <CancelConfirmationModal
                isVisible={modalCancelOpen}
                onAccept={handleCancelAccept}
                onCancel={() => { setModalCancelOpen(false) }}
                title={descriptions.CANCEL_MODAL.TITLE}
                okText={descriptions.CANCEL_MODAL.ACTION_BUTTON}
                cancelText={descriptions.CANCEL_MODAL.CANCEL_BUTTON}
                description={descriptions.CANCEL_MODAL.DESCRIPTION}
            />
            <ModalSuccess
                isVisible={reduxProps.createConsultationTypeSuccess && actionButtonClicked}
                title={props.consultationTypeId
                    ? descriptions.SUCCESS_SECONDARY_MODAL?.TITLE ?? ''
                    : descriptions.SUCCESS_MODAL.TITLE}
                description={props.consultationTypeId
                    ? descriptions.SUCCESS_SECONDARY_MODAL?.DESCRIPTION ?? ''
                    : descriptions.SUCCESS_MODAL.DESCRIPTION}
                onPrimaryAction={handleCancelAccept}
                onSecondaryActiont={handleCancelAccept}
                primaryLabel={descriptions.SUCCESS_MODAL.PRIMARY_LABEL}
                secondaryLabel={descriptions.SUCCESS_MODAL.SECONDARY_LABEL}
                hideSecondaryButton={true}
            />
        </>
    )

    const handleFinishForm = (consultationType: Partial<MedicalConsultationType>) => {
        setModalConfirmationOpen(true)
        setFormValues(consultationType)
    }

    const handleCancel = () => {
        setModalCancelOpen(true)
    }

    const renderLoader = () => (
        reduxProps.isCreatingConsultationType && <Loader
            isVisible={true}
            title={descriptions.MAIN_ACTION_TITLE}
            description={descriptions.MAIN_ACTION_DESCRIPTION}
        />
    )

    const renderContent = () => {
        return <CreateConsultationTypeForm
            onFinish={handleFinishForm}
            onCancel={handleCancel}
            initialValues={reduxProps.currentConsultationType}
        />
    }

    return (
        <>
            {
                reduxProps.isCreatingConsultationType
                    ? <InnerLoader
                        darkMode
                        label="Getting data..."
                    />
                    : renderContent()
            }
            {renderModals()}
            {renderLoader()}
        </>
    )
}