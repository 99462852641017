import { useDispatch, useSelector } from "react-redux"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { ContentContainer, PageCard } from "src/common/styles/styles"
import { SearchableTable } from "src/components/SearchableTable"
import { RootState } from "src/state/reducer"
import { GET_SERVICE_DEBITS_API } from "../state/actionTypes"
import { ServiceDebitBatch, ServiceDebitBatchAction } from "src/common/models/serviceDebitBatch"
import { useEffect } from "react"
import { getServiceDebitsApi } from "../state/action"
import { formatToCurrency } from "src/common/util"
import { SystemConstants } from "src/common/constants"
import dayjs from 'dayjs'
import { AlignType } from 'rc-table/lib/interface'
import { DateRanges } from "src/components/SearchableTable/types"
import { StatisticCard } from "./StatisticCard"
import { StatisticType } from "./StatisticCard/types"
import { Row } from "antd"
import { Col6 } from "src/components/Columns"

interface ReduxProps {
    isLoadingData: boolean
    serviceDebits: ServiceDebitBatch[]
}

export const ServiceDebitRecords = () => {
    const dispatch = useDispatch()

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        isLoadingData: serviceLoading(state, [GET_SERVICE_DEBITS_API]),
        serviceDebits: state.serviceDebit.serviceDebits,
    }))

    useEffect(() => {
        dispatch(getServiceDebitsApi({
            dateFrom: dayjs().format('YYYY-MM-DD'),
            dateTo: dayjs().format('YYYY-MM-DD'),
        }))
    }, [])

    const tableColumns = [
        {
            title: 'No',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id,
            align: 'center' as AlignType,
        },
        {
            title: 'Client',
            dataIndex: 'clientName',
            key: 'clientName',
            sorter: (a, b) => a.clientName - b.clientName,
        },
        {
            title: 'Fecha',
            dataIndex: 'date',
            key: 'date',
            render: (value) => {
                return dayjs(value).format(SystemConstants.DATETIME_FORMAT_DISPLAY)
            },
            align: 'center' as AlignType,
        },
        {
            title: 'CutOffDate',
            dataIndex: 'cutOffDate',
            key: 'cutOffDate',
            align: 'center' as AlignType,
        },
        {
            title: 'Saldo',
            dataIndex: 'outstanding',
            key: 'outstanding',
            render: (value) => {
                return formatToCurrency(value)
            },
            align: 'right' as AlignType,
        },
        {
            title: 'Ultimio Pago',
            dataIndex: 'lastPaymentDate',
            key: 'lastPaymentDate',
            render: (value) => {
                return value ? dayjs(value).format(SystemConstants.DATE_FORMAT) : "No payments"
            },
            align: 'center' as AlignType,
        },
        {
            title: 'Acción realizada',
            dataIndex: 'actionDone',
            key: 'actionDone',
            render: (value) => {
                const dictionaryOfActions = {
                    RECHARGED_FEE: "Recharged fee",
                    NONE: "None",

                }
                return dictionaryOfActions[value] ?? ""
            },
            align: 'center' as AlignType,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (value) => {
                const dictionaryOfStatuses = {
                    SUCCESS: "Success",
                    ERROR: "Error",
                }
                return dictionaryOfStatuses[value] ?? ""
            },
            align: 'center' as AlignType,
        },
    ];

    const handleDateChange = (dateRanges: DateRanges) => {
        dispatch(getServiceDebitsApi({
            dateFrom: dayjs(dateRanges.startDate).format('YYYY-MM-DD'),
            dateTo: dayjs(dateRanges.endDate).format('YYYY-MM-DD'),
        }))
    }

    const renderStatistics = () => {
        const processedItems = reduxProps.serviceDebits.filter(item => !item.isParent)

        const operated = processedItems.length
        let rechargedFee = 0
        let inactivated = 0
        let errors = 0

        processedItems.forEach(item => {
            rechargedFee += item.actionDone === ServiceDebitBatchAction.RECHARGED_FEE ? 1 : 0
            inactivated += item.actionDone === ServiceDebitBatchAction.INACTIVATED ? 1 : 0
            errors += item.actionDone === ServiceDebitBatchAction.NONE ? 1 : 0
        })

        return <Row gutter={[12, 12]}>
            <Col6>
                <StatisticCard
                    statisticType={StatisticType.NONE}
                    title={"Operados"}
                    value={operated}
                />
            </Col6>
            <Col6>
                <StatisticCard
                    statisticType={StatisticType.SUCCESS}
                    title={"Recargos"}
                    value={rechargedFee}
                />
            </Col6>
            <Col6>
                <StatisticCard
                    statisticType={StatisticType.ERROR}
                    title={"Inactivados"}
                    value={inactivated}
                />
            </Col6>
            <Col6>
                <StatisticCard
                    statisticType={StatisticType.WARNING}
                    title={"Errores"}
                    value={errors}
                />
            </Col6>
        </Row>
    }

    const renderContent = () => (
        <>
            {renderStatistics()}
            < PageCard size="small" title={"Registro de débitos por servicio"} >

                <SearchableTable
                    showSearch
                    searchLabel={"Search"}
                    showRangePicker
                    rangePickerDefaultValues={[dayjs(), dayjs()]}
                    rangePickerOnChange={handleDateChange}
                    items={reduxProps.serviceDebits.filter(item => !item.isParent)}
                    tableColumns={tableColumns}

                    showLoader={reduxProps.isLoadingData}
                />
            </PageCard >
        </>
    )

    return (
        <ContentContainer>
            {renderContent()}
        </ContentContainer>
    )
}
