import { Badge, List } from 'antd'
import { SystemDescriptions } from 'src/common/descriptions/descriptions'
import { PaymentPlan } from 'src/common/models/paymentPlan'

interface Props {
    data?: PaymentPlan[]
}

export const PaymentPlanList = (props: Props) => {

    const descriptions = SystemDescriptions.PAGES.MEDICAL_AREA.MEDICAL_PAYMENT

    const buildPlanDescription = (item: PaymentPlan): string => {
        return descriptions.PERSONAL_DATA.PLAN_DATA_COMPOSSER(item)
    }

    const getBadgeColor = (isPayed: boolean): string => {
        const dictionaryOfColors = {
            payed: "green",
            pending: "red",
        }

        const status: string = isPayed ? "payed" : "pending"
        return dictionaryOfColors[status]
    }

    return (
        <List
            itemLayout="horizontal"
            dataSource={props?.data ?? []}
            renderItem={(item, index) => (
                <Badge.Ribbon
                    text={item.payed ? "Pagado" : "Pendiente Pago"}
                    color={getBadgeColor(item.payed)}>
                    <List.Item>
                        <List.Item.Meta
                            title={<label>{item.id} - {item.description}</label>}
                            description={buildPlanDescription(item)}
                        />
                    </List.Item>
                </Badge.Ribbon>
            )}
        />
    )
}
