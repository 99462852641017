import { Languages } from "src/common/descriptions/types";
import { PatientProfileDescriptions } from "./types";

const DescriptionsEnUs: PatientProfileDescriptions = {
    TITLE: "Patient Profile",
    SUMMARY: {
        TITLE: "Total Data",
        CONSULTATIONS: "Consultations",
        PAYMENT_PLANS: "Payment Plans",
        ACTIVE_PLANS: "Active Plans",
        PAYMENTS: "Payments",
        BALANCE: "Balance"
    },
    PERSONAL_DATA: {
        TITLE: "Personal Data",
        NAMES: "Names",
        SURNAMES: "Surnames",
        BIRTHDATE: "Birthdate",
        GENDER: "Gender",
        PHONE: "Phone",
        ADDRESS: "Address",
        ZIPCODE: "Zipcode",
        EMAIL: "Email",
        DOC_ID: "Doc ID",
        IN_CHARGE: "In Charge",
        HISTORY: "History",
        OTHER: "Other",
        CREATED_BY: "Created By",
    },
    PAYMENT_PLAN_RECORDS: {
        TITLE: "Payment Plan Records",
    },
    ACCOUNT_STATEMENT: {
        TITLE: "Account Balance",
        SELECT: "Select",
        GENERATE: "Generate",
        ALL: "All",
        TABLE_COLUMNS: {
            ID: "ID",
            DATETIME: "Datetime",
            DESCRIPTION: "Description",
            PREVIOUS: "Previous",
            CREDIT: "Credit",
            DEBIT: "Debit",
            BALANCE: "Balance",
        },
    },
    CONSULTATION_RECORDS: {
        TITLE: "Consultation Records",
        TABLE_COLUMNS: {
            ID: "ID",
            DATETIME: "Datetime",
            MEDICAL_AREA: "Medical Area",
        },
    },
}

const DescriptionsEsUs: PatientProfileDescriptions = {
    TITLE: "Perfil de Paciente",
    SUMMARY: {
        TITLE: "Datos Totales",
        CONSULTATIONS: "Consultas",
        PAYMENT_PLANS: "Planes de Pago",
        ACTIVE_PLANS: "Planes Activos",
        PAYMENTS: "Pagos",
        BALANCE: "Saldo Actual"
    },
    PERSONAL_DATA: {
        TITLE: "Datos Personales",
        NAMES: "Nombres",
        SURNAMES: "Apellidos",
        BIRTHDATE: "Fecha Nac",
        GENDER: "Género",
        PHONE: "Teléfono",
        ADDRESS: "Dirección",
        ZIPCODE: "Ciudad",
        EMAIL: "Correo",
        DOC_ID: "Doc ID",
        IN_CHARGE: "Encargado",
        HISTORY: "Historia",
        OTHER: "Otro",
        CREATED_BY: "Creado Por",
    },
    PAYMENT_PLAN_RECORDS: {
        TITLE: "Historial Planes de Pago",
    },
    ACCOUNT_STATEMENT: {
        TITLE: "Estado de Cuenta",
        SELECT: "Seleccione",
        GENERATE: "Generar",
        ALL: "Todos",
        TABLE_COLUMNS: {
            ID: "Código",
            DATETIME: "Fecha",
            DESCRIPTION: "Descripción",
            PREVIOUS: "Previo",
            CREDIT: "Crédito",
            DEBIT: "Débito",
            BALANCE: "Saldo",
        },
    },
    CONSULTATION_RECORDS: {
        TITLE: "Historial Consultas",
        TABLE_COLUMNS: {
            ID: "Código",
            DATETIME: "Fecha",
            MEDICAL_AREA: "Área Médica",
        },
    },
}

export const patientProfileDictionary = {}
patientProfileDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
patientProfileDictionary[Languages.SPANISH_US] = DescriptionsEsUs