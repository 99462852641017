import { Action } from 'redux'
import * as Actions from './actionTypes'
import { GetServiceDebitFilters, ServiceDebitBatch } from 'src/common/models/serviceDebitBatch'

export interface GetServiceDebitsAction extends Action {
    filters?: GetServiceDebitFilters
}
export interface GetServiceDebitsSuccessAction extends Action {
    serviceDebits: ServiceDebitBatch[]
}
export interface ErrorAction extends Action {
    error?
}
export const getServiceDebitsApi = (filters?: GetServiceDebitFilters): GetServiceDebitsAction => ({
    type: Actions.GET_SERVICE_DEBITS_API,
    filters,
})
export const getServiceDebitsSuccess = (serviceDebits: ServiceDebitBatch[]): GetServiceDebitsSuccessAction => ({
    type: Actions.GET_SERVICE_DEBITS_SUCCESS,
    serviceDebits,
})
export const getServiceDebitsError = (error?): ErrorAction => ({
    type: Actions.GET_SERVICE_DEBITS_ERROR,
    error,
})