import { DatePicker, Form, Input, Row, Select } from "antd";
import { Col10, Col18, Col24, Col4, Col6, Col8 } from "src/components/Columns";
import { UserOutlined, ArrowDownOutlined, PhoneOutlined, BorderHorizontalOutlined, MailOutlined, IdcardOutlined, UsergroupAddOutlined, BarsOutlined, MoreOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { Patient } from "src/common/models/patient";
import { useEffect } from "react";
import dayjs from 'dayjs';
import { SystemConstants } from "src/common/constants";
import { GenderIds } from "./types";

interface Props {
  patient?: Patient
  readOnly?: boolean
}

export const CreatePatientForm = (props: Props) => {

  const [form] = Form.useForm();

  useEffect(() => {
    if (props.patient) {
      const patient = props.patient

      form.setFieldsValue({
        name: patient.name,
        surname: patient.surname,
        birthdate: dayjs(patient.birthdate),
        gender: patient.id_gender,
        phone: patient.phone,
        address: patient.address,
        zipcode: patient.id_zipcode,
        email: patient.email,
        docId: patient.doc_id,
        tutor: patient.in_charge,
        history: patient.history,
        other: patient.other,
        createdBy: patient.id_employee,
      })
    }
  }, [props.patient])


  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
    >
      <Row gutter={16}>
        <Col6>
          <Form.Item
            label={"Name"}
            name={"name"}
          >
            <Input readOnly={props.readOnly} prefix={<UserOutlined />} />
          </Form.Item>
        </Col6>
        <Col6>
          <Form.Item
            label={"Surname"}
            name={"surname"}
          >
            <Input readOnly={props.readOnly} prefix={<UserOutlined />} />
          </Form.Item>
        </Col6>
        <Col4>
          <Form.Item
            label={"Birthdate"}
            name={"birthdate"}
          >
            <DatePicker
              style={{ width: '100%' }}
              format={SystemConstants.DATE_FORMAT}
              disabled={props.readOnly}
            />
          </Form.Item>
        </Col4>
        <Col4>
          <Form.Item
            label={"Gender"}
            name={"gender"}
          >
            {/* <Input readOnly={props.readOnly} prefix={<ArrowDownOutlined />} /> */}
            <Select
              options={[
                {
                  value: GenderIds.MALE,
                  label: "Masculino",
                },
                {
                  value: GenderIds.FEMALE,
                  label: "Femenino",
                },
              ]}
              placeholder={"Select payment plan type"}
              disabled={props.readOnly}
            />
          </Form.Item>
        </Col4>
        <Col4>
          <Form.Item
            label={"Phone"}
            name={"phone"}
          >
            <Input readOnly={props.readOnly} prefix={<PhoneOutlined />} />
          </Form.Item>
        </Col4>
        <Col6>
          <Form.Item
            label={"Address"}
            name={"address"}
          >
            <Input readOnly={props.readOnly} prefix={<BorderHorizontalOutlined />} />
          </Form.Item>
        </Col6>
        <Col10>
          <Form.Item
            label={"Zipcode"}
            name={"zipcode"}
          >
            <Input readOnly={props.readOnly} prefix={<ArrowDownOutlined />} />
          </Form.Item>
        </Col10>
        <Col8>
          <Form.Item
            label={"Email"}
            name={"email"}
          >
            <Input readOnly={props.readOnly} prefix={<MailOutlined />} />
          </Form.Item>
        </Col8>
        <Col6>
          <Form.Item
            label={"Doc ID"}
            name={"docId"}
          >
            <Input readOnly={props.readOnly} prefix={<IdcardOutlined />} />
          </Form.Item>
        </Col6>
        <Col18>
          <Form.Item
            label={"Tutor"}
            name={"tutor"}
          >
            <Input readOnly={props.readOnly} prefix={<UsergroupAddOutlined />} />
          </Form.Item>
        </Col18>
        <Col24>
          <Form.Item
            label={"History"}
            name={"history"}
          >
            <Input readOnly={props.readOnly} prefix={<BarsOutlined />} />
          </Form.Item>
        </Col24>
        <Col18>
          <Form.Item
            label={"Other"}
            name={"other"}
          >
            <Input readOnly={props.readOnly} prefix={<MoreOutlined />} />
          </Form.Item>
        </Col18>
        <Col6>
          <Form.Item
            label={"Created by"}
            name={"createdBy"}
          >
            <Input readOnly={props.readOnly} prefix={<UserSwitchOutlined />} />
          </Form.Item>
        </Col6>
      </Row>
    </Form>
  )
}
